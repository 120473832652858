+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.SaisieComplete = window.App.Pages.SaisieComplete || {};
    window.App.Pages.SaisieComplete.views = window.App.Pages.SaisieComplete.views || {};

    window.App.Pages.SaisieComplete.views.edit = viewFactory;

    function viewFactory(App) {
        var state = {
                bindings: [],
                fields: {},
                parcel: null
            },
            Api = window.App.Api,
            FormValidation = window.FormValidation;

        return {
            el: '#editView',
            $$loaded: false,
            $$intialized: false,
            // Called by App lifecycle
            onInit: onInit,
            onEnter: onEnter,
            onExit: onExit,
            // Local methods
            bindEvents: bindEvents,
            loadProducts: loadProducts,
            loadPointsOfSale: loadPointsOfSale,
            loadCountries: loadCountries,
            loadParcel: loadParcel,
            loadCustomer: loadCustomer,
            submit: submit,
            reset: reset,
            onSave: onSave,
            // Utils methods
            compile: compile,
            merge: merge
        };

        // --------------------------------------------------------------------
        // VIEW FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Initialize lists from API and load bindings
         */
        function onInit() {
            var view = this;

            window.App.Bind.bindFields(view.$$el, 'parcel', state.fields);

            $.when(
                view.loadPointsOfSale(),
                view.loadCountries()
            )
                .done(function () {
                    setTimeout(function () {
                        view.$$loaded = true;
                        view.$$el.trigger('loaded');
                    }, 10);
                });
        }

        /**
         * Initialize fields states, start autofocus on number
         */
        function onEnter(data) {
            var view = this;

            view.$$initialized = false;

            state.carrier = 'object' === typeof data.carrier ? data.carrier.id : data.carrier;

            if (view.$$loaded) {
                load();
            } else {
                view.$$el.on('loaded', function () {
                    load();
                });
            }

            return void 0;

            /*
             * Internal functions
             */

            function load() {
                // Init UI
                view.loadParcel(data.parcel);

                // Init event
                view.bindEvents();
            }
        }

        /**
         * Binds all events
         */
        function bindEvents() {
            var view = this,
                form = view.el('form');

            /*
             * Customers callbacks
             */
            state.bindings.push((function () {
                view.component('customerPickup')
                    .on('customer.loaded', function (event, customer) {
                        view.loadCustomer(customer);
                    })
                    .on('customer.reset', function (event, customer) {
                        view.component('parcelExpediteur').trigger('component.hide');
                    });

                return function () {
                    view.component('customerPickup').off('customer.loaded customer.reset');
                };
            })());

            /*
             * Actions callbacks
             */
            state.bindings.push((function () {
                view.component('actions')
                    .on('label.printed', function () {
                        // Reload the data
                        App.getParcel(state.parcel.numero)
                            .done(function (parcel) {
                                view.loadParcel(parcel);
                            });
                    })
                    .on('edit.full', function (event, parcel) {
                        App.redirectFullInput(parcel.numero);
                    })
                    .on('edit.fast', function (event, parcel) {
                        App.goto('new', {
                            parcel: parcel,
                            carrier: parcel.transporteur
                        });
                    });

                return function () {
                    view.component('actions').off('label.printed edit.full edit.fast');
                };
            })());

            /*
             * Submit the form with validation
             */
            state.bindings.push((function () {
                view.el('form')
                    .on('submit', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        view.submit();
                    });

                return function () {
                    view.el('form').off('submit reset');
                };
            })());

            /*
             * Go back to home
             */
            state.bindings.push((function () {
                view.el('reset').on('click', function () {
                    App.goto('home');
                });

                return function () {
                    view.el('reset').off('click');
                };
            })());

            /*
             * Handle history
             */
            if ('function' === typeof window.history.replaceState) {
                window.history.replaceState(
                    {
                        page: 'edit-full',
                        number: state.parcel.numero
                    }, null, view.$$el.closest('[v-page]').data('url-edit').replace(/--numerock--/, state.parcel.numero)
                );
            }
        }

        /**
         * Clear bindings, stop autofocus
         */
        function onExit() {
            var view = this,
                i;

            view.component('customerPickup').trigger('reset.customer');

            state.focusInterval && clearInterval(state.focusInterval);

            for (i = 0; i < state.bindings.length; ++i) {
                state.bindings[i]();
            }

            state.bindings = [];
        }

        /**
         * Clear the number field, keep the carrier
         */
        function reset() {
            var view = this,
                form = view.el('form'),
                number = view.el('numerock'),
                alert = view.el('alert');

            number.val('');
            alert.text('').addClass('hidden');
        }

        /**
         * Copy customer address to address component
         */
        function loadCustomer(customer) {
            var view = this;

            if (customer) {
                if (!state.parcel || state.parcel.numclient != customer.customer_number || !view.$$initialized) {
                    customer = {
                        numclient: customer.customer_number,
                        expediteur: {
                            _type: 'ColikadoAddress',
                            civilite: customer.civility,
                            nom: customer.lastname,
                            prenom: customer.firstname,
                            adresse: {
                                lignes: customer.address.lines || [],
                                code_postal: customer.address.zipcode,
                                ville: customer.address.city,
                                pays: {
                                    nom: customer.address.country.name,
                                    code: customer.address.country.code
                                },
                                digicode: customer.address.country.entry_code
                            },
                            telephone: {
                                fixe: customer.phone.landline,
                                mobile: customer.phone.mobile
                            },
                            email: customer.email
                        }
                    }
                } else {
                    customer = {};
                }
            } else {
                customer = {
                    numclient: '',
                    expediteur: {
                        _type: 'ColikadoAddress',
                        $$initialized: true,
                        civilite: '',
                        nom: '',
                        prenom: '',
                        adresse: {
                            lignes: ['', ''],
                            code_postal: '',
                            ville: '',
                            pays: {
                                nom: '',
                                code: ''
                            },
                            digicode: ''
                        },
                        telephone: {
                            fixe: '',
                            mobile: ''
                        },
                        email: ''
                    }
                }
            }

            view.$$initialized = true;

            var data = $.extend(true, state.parcel, view.compile(), customer);

            if (data.status) {
                delete data.status;
            }

            view.merge(data);

            view.component('parcelExpediteur').trigger('component.show');
        }

        /**
         * Get products from API v2, remove products and restore previous value
         */
        function loadProducts() {
            return Api.Colikado.loadProductOptions(state.fields.produit.id, ['dimensions'], null, state.parcel.numero);
        }

        /**
         * Get points of sale from API v2, remove points of sale and restore previous value
         */
        function loadPointsOfSale() {
            var defer = $.Deferred();

            $.when(
                Api.Colikado.loadPointsOfSaleOptions(state.fields.vente.achat.id, {
                    actif: true
                }),
                Api.Colikado.loadPointsOfSaleOptions(state.fields.vente.depot.id, {
                    actif: true
                })
            )
                .done(function () {
                    defer.resolve();
                })
                .fail(function () {
                    defer.reject();
                });

            return defer;
        }

        /**
         * Hardcoded in fast input mode
         */
        function loadCountries() {
            $('<option></option>')
                .attr('value', 'RE')
                .text('REUNION')
                .appendTo(state.fields.expediteur.adresse.pays.code);

            $('<option></option>')
                .attr('value', 'FR')
                .text('FRANCE')
                .appendTo(state.fields.expediteur.adresse.pays.code);

            return Api.Colikado.loadCountriesOptions(state.fields.destinataire.adresse.pays.code, {
                livre: true
            });
        }

        /**
         * Load the parcel data into the view
         *
         * @param {*} parcel
         */
        function loadParcel(parcel) {
            var view = this;

            state.parcel = parcel;

            $.when(
                view.loadProducts()
            )
                .done(function () {

                    // ***

                    App.selectFirst(state.fields.produit.id);

                    // ***

                    App.selectFirst(state.fields.vente.achat.id);
                    App.selectFirst(state.fields.vente.depot.id);

                    // ***

                    App.selectFirst(state.fields.douane.nature);

                    // ***

                    App.selectFirst(state.fields.expediteur.civilite);
                    App.selectFirst(state.fields.destinataire.civilite);

                    // ***

                    App.selectValue(state.fields.expediteur.adresse.pays.code, 'RE');
                    App.selectValue(state.fields.destinataire.adresse.pays.code, 'FR');

                    // ***

                    // Load all fields
                    view.merge(parcel);

                    view.component('productInput').trigger('parcel.loaded', parcel);
                    view.component('freePackage').trigger('parcel.loaded', parcel);
                    view.component('customerPickup').trigger('parcel.loaded', parcel);
                    view.component('salesInput').trigger('parcel.loaded', parcel);
                    view.component('actions').trigger('parcel.loaded', parcel);
                });
        }

        /**
         * Save
         */
        function submit() {
            var view = this,
                buttons = $('button', view.$$el);

            FormValidation.validate(view.el('form').get(0))
                .success(function (validation) {
                    var data = view.compile(),
                        failure = function (error) {
                            buttons.prop('disabled', false);

                            App.showError('Échec de l\'enregistrement', error && error.errors ? [error.message, error.errors] : error)
                        };

                    validation.reset();
                    buttons.prop('disabled', true);

                    App.showWaiting();

                    Api.Colikado.validateParcel(data)
                        .done(function () {
                            Api.Colikado.saveParcelComplete(data)
                                .done(function (parcel) {
                                    buttons.prop('disabled', false);

                                    onSuccess(parcel);
                                })
                                .fail(failure)
                        })
                        .fail(failure);

                })
                .fail(function () {
                    App.showError('Saisie incomplète ou erronée', '<ul><li>Tous champs obligatoires doivent être renseignés.</li><li>Vérifiez le format des données saisies.</li></ul>');
                });

            /*
             * ---- Functions
             */

            function onSuccess(parcel) {
                view.onSave(parcel);
            }
        }

        /**
         * Handle parcel saving
         *
         * @param parcel
         */
        function onSave(parcel) {
            var view = this,
                buttons = $('button', view.$$el),
                requirePrinting = !parcel.impression.etiquette.imprime,
                requireNewPrinting = requirePrinting && state.parcel.impression.etiquette.imprime;

            // Update UI
            view.loadParcel(parcel);

            // Ask for next move
            swal({
                type: 'success',
                title: 'Enregistrement réussi',
                text: requireNewPrinting ? 'Attention, il faut ré-imprimer l\'étiquette de ce colis suite à sa modification' : null,
                html: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                showCancelButton: true,
                closeOnCancel: true,
                cancelButtonText: 'Retour au formulaire',
                showConfirmButton: true,
                closeOnConfirm: false,
                showLoaderOnConfirm: true,
                confirmButtonText: requirePrinting ? 'Imprimer l\'étiquette' : 'Saisir un autre colis',
                confirmButtonColor: '#0459B7'
            }, function (confirm) {
                buttons.prop('disabled', false);

                if (confirm) {
                    if (requirePrinting) {
                        swal.close();

                        setTimeout(function () {
                            Api.Colikado.printLabel(parcel.numero)
                                .done(function () {
                                    // Reload the data
                                    App.getParcel(parcel.numero)
                                        .done(function (parcel) {
                                            view.onSave(parcel);
                                            swal.enableButtons();
                                        })
                                        .fail(function () {
                                            swal.enableButtons();
                                        });
                                })
                                .fail(function (error) {
                                    swal.enableButtons();

                                    App.showError({
                                        title: 'Échec de l\'impression',
                                        closeOnConfirm: false
                                    }, error, function () {
                                        onSuccess(parcel);
                                    });
                                });
                        }, 50);
                    } else {
                        App.goto('home');

                        swal.close();
                    }
                }
            });
        }

        // --------------------------------------------------------------------
        // UTILITY FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Merge the given object into the form fields
         */
        function merge(source) {
            return App.deepMerge(state.fields, source);
        }

        /**
         * Merge the given object into the form fields
         */
        function compile() {
            var data = App.deepCompile(state.fields, {});

            data.numero = state.parcel.numero;

            return data;
        }

    }
}(window, jQuery);
