+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.AttributionPointDeVente = window.App.Pages.AttributionPointDeVente || {};
    window.App.Pages.AttributionPointDeVente.views = window.App.Pages.AttributionPointDeVente.views || {};

    window.App.Pages.AttributionPointDeVente.views.home = viewFactory;

    function viewFactory(App) {
        var state = {
            bindings: []
        };

        return {
            el: '#homeView',
            $$loaded: false,
            // Called by App lifecycle
            onEnter: onEnter,
            onExit: onExit,
            // Local methods
            bindEvents: bindEvents,
            checkAddForm: checkAddForm,
            decreaseStock: decreaseStock,
            createNumberList: createNumberList,
            submit: submit
        };

        // --------------------------------------------------------------------
        // VIEW FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Initialize fields states, start autofocus on number
         */
        function onEnter() {
            var view = this;

            view.el('numero').focus();
            view.el('cklist-container').hide();
            view.el('stocks').hide();

            view.bindEvents();

            view.checkAddForm();
        }

        function checkAddForm() {
            var view = this;

            if (!view.el('transporteur').val() || !view.el('station').val()) {
                view.el('submit-number').prop('disabled', true);
            }
        }

        /**
         * Binds all events
         */
        function bindEvents() {
            var view = this;

            state.bindings.push((function () {
                $(view.el('station').add(view.el('transporteur')))
                    .on('change.module', function () {
                        view.checkAddForm();
                        $(this).closest('form').submit();
                    });

                return function () {
                    $(view.el('station').add(view.el('transporteur'))).off('change.module');
                };
            })());

            state.bindings.push((function () {
                view.el('submit-number')
                    .on('click.module', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        return view.submit();
                    });

                return function () {
                    view.el('submit-number').off('click.module');
                };
            })());

            state.bindings.push((function () {
                view.el('cklist')
                    .on('click.module', '[data-trigger=delete]', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        return view.decreaseStock($(this));
                    });

                return function () {
                    view.el('ckilist').off('click.module', '[data-trigger=delete]');
                };
            })());

            state.bindings.push((function () {
                view.el('submit-validation')
                    .on('click.module', function () {
                        return view.createNumberList();
                    });

                return function () {
                    view.el('submit-validation').off('click.module');
                };
            })());
        }

        /**
         * Clear bindings, stop autofocus
         */
        function onExit() {
            var i;

            for (i = 0; i < state.bindings.length; ++i) {
                state.bindings[i]();
            }

            state.bindings = [];
        }

        function decreaseStock($el) {
            var view = this,
                numero = $el.data('numero'),
                stock_type = null;

            if (/^CK06/.test(numero)) {
                stock_type = 'anc6';
            } else if (/^CK12/.test(numero)) {
                stock_type = 'anc12';
            } else if (/^CA/.test(numero)) {
                stock_type = 'affr';
            } else if (/^(?:TT|CP)/.test(numero)) {
                stock_type = 'prep';
            }

            var $newStock = $('#new_stock_' + stock_type);
            var $total = $('#tt_' + stock_type);

            $newStock.html((parseInt($newStock.html()) - 1));
            $total.html((parseInt($total.html()) - 1));

            $el.closest('tr').remove();

            if ($('tr', view.el('cklist')).length <= 0) {
                view.el('cklist-container').hide();
                view.el('stocks').hide();
            }
        }

        function submit() {
            var view = this,
                $numero = view.el('numero'),
                numero = $numero.val().toUpperCase();

            if (numero.match(/^(?:TT|C[A-P])\d{9}$/i)) {

                var cklist_item = '<tr><td class="ck">';
                cklist_item += numero;
                cklist_item += '</td><td>';
                cklist_item += '<a data-trigger="delete" data-numero="' + numero + '" class="btn btn-danger btn-squared btn-xs">Retirer</a>';
                cklist_item += '</td></tr>';

                $.get('/bontransport/checkexistingck/ck/' + numero, function (exists) {
                    if (exists == '0') {
                        var $ckList = view.el('cklist');

                        if (!$ckList.find('[data-numero=' + numero + ']').length) {
                            // 1) append the entered ck to the cklist
                            $('tbody', $ckList).append(cklist_item);
                            // 2) clear the ck field
                            $numero.val('');
                            // 3) update the corresponding new_stock value
                            var stock_type = '';
                            if (/^CK06/.test(numero)) {
                                stock_type = 'anc6';
                            } else if (/^CK12/.test(numero)) {
                                stock_type = 'anc12';
                            } else if (/^CA/.test(numero.toString())) {
                                stock_type = 'affr';
                            } else if (/^CP/.test(numero)) {
                                stock_type = 'prep';
                            }

                            var $newStocks = $('#new_stock_' + stock_type);
                            var totalStocks = $('#tt_' + stock_type);

                            $newStocks.html((parseInt($newStocks.html()) + 1));
                            totalStocks.html((parseInt(totalStocks.html()) + 1));

                            view.el('cklist-container').show();
                            view.el('stocks').show();
                        } else {
                            $numero.val(null);
                            App.showError({
                                title: 'Ajout impossible',
                                html: 'Ce numéro est déjà présent dans la liste en cours de traitement.'
                            });
                        }
                    } else {
                        $numero.val(null);
                        App.showError({
                            title: 'Ajout impossible',
                            html: 'Ce numero est déja attribué à un point de vente.'
                        });
                    }
                });

            } else {
                App.showError({
                    title: 'Erreur de saisie',
                    html: 'Le format de ce numero est invalide.'
                });
            }

            $numero.focus();
        }

        function createNumberList() {
            var view = this,
                ok = false,
                output = '';

            view.el('cklist').find('[data-numero]').each(function () {
                output += $(this).data('numero') + ';';
            });

            if (output != '') {
                output = output.substr(0, output.length - 1);
                view.el('validation-list').val(output);
                ok = true;
            } else {
                alert('Veuillez entrez des numeros CK');
            }

            return ok;
        }
    }
}(window, jQuery);