+function (window, document, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Api = window.App.Api || {};
    window.App.Api.Colipays = {
        silentPrint: window.navigator && window.navigator.userAgent && window.navigator.userAgent.toLowerCase().match(/firefox/),
        // Public functions,
        $$api: base('$$api'),
        // Data functions
        getCustomer: getCustomer,
        searchCustomers: searchCustomers,
        createCustomer: createCustomer,
        getCreditNote: getCreditNote,
        validateSupervisorCode: validateSupervisorCode,
        getComponents: getComponents,
        migrateLoyalty: migrateLoyalty,
        // Transformers
        transformIn: base('transformIn'),
        transformOut: base('transformOut'),
        // Utility functions
        _buildParams: base('_buildParams')
    };

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    // ***

    // --------------------------------------------------------------------
    // DATA LOAD FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Load a customer by number
     *
     * @param {string} number customer number (numclient)
     * @param {[]|string} include relations to include
     *
     * @returns {*} a promise that is resolve in `done()` with customer data or rejected `fail()` with error
     */
    function getCustomer(number, include) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/customers/' + number.toUpperCase(), api._buildParams(null, include))
            .done(function (customer) {
                defer.resolve(api.transformIn(customer));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Find customers by criteria
     *
     * @param {*} criterias search criterias
     * @param {[]|string} include relations to include
     *
     * @returns {*} a promise that is resolve in `done()` with customer data or rejected `fail()` with error
     */
    function searchCustomers(criterias, include) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/customers', api._buildParams(criterias, include))
            .done(function (result) {
                result.data = result.data.map(function (item) {
                    return api.transformIn(item);
                });

                defer.resolve(result);
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Create a new customer
     *
     * @param {*} data customer data
     * @param {[]|string} include relations to include
     *
     * @returns {*} a promise that is resolve in `done()` with customer data or rejected `fail()` with error
     */
    function createCustomer(data, include) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$post('/customers', api._buildParams(data, include))
            .done(function (customer) {
                defer.resolve(api.transformIn(customer));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Load a customer by number
     *
     * @param {string} number credit note number
     * @param {[]|string} include relations to include
     *
     * @returns {*} a promise that is resolve in `done()` with customer data or rejected `fail()` with error
     */
    function getCreditNote(number, include) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/credit-notes/' + number.toUpperCase(), api._buildParams(null, include))
            .done(function (customer) {
                defer.resolve(api.transformIn(customer));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Validate a supervisor PIN code
     *
     * @param {string} number supervisor pin code
     * @param {[]|string} include relations to include
     *
     * @returns {*} a promise that is resolve in `done()` or rejected `fail()` with error
     */
    function validateSupervisorCode(number, include) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$post('/users/supervisors/validate-pin', api._buildParams({'pin': number}, include))
            .done(function (supervisor) {
                defer.resolve(supervisor);
            })
            .fail(function (error) {
                if (error.status_code === 404) {
                    defer.reject('Le code PIN a été refusé.');
                } else {
                    defer.reject('Le code PIN n’a pas pu être vérifié : ' + error && error.message ? error.message : 'erreur interne');

                    typeof console.error === 'function' && console.error('Failed PIN validation request', error);
                }
            });

        return defer;
    }

    /**
     * Get all parcel's components
     *
     * @param {[]|string=} include relations to include
     *
     * @returns {*} a promise that is resolve in `done()` or rejected `fail()` with error
     */
    function getComponents(include) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/stocks/components', api._buildParams(null, include))
            .done(function (result) {
                result.data = result.data.map(function (item) {
                    return api.transformIn(item);
                });

                defer.resolve(result);
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Migrate the customer to the new program
     *
     * @param {string} customerNumber
     *
     * @returns {*} a promise that is resolve in `done()` or rejected `fail()` with error
     */
    function migrateLoyalty(customerNumber) {
        var api = this,
            defer = $.Deferred(),
            data = {from: 'v1', to: 'v2'};

        api.$$api().$post('/customers/' + customerNumber + '/loyalty/migrate', api._buildParams(data))
            .done(function (result) {
                defer.resolve(api.transformIn(data));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    // --------------------------------------------------------------------
    // DATA MANIPULATION FUNCTIONS
    // --------------------------------------------------------------------

    // ***

    // --------------------------------------------------------------------
    // ACTION HELPERS FUNCTIONS
    // --------------------------------------------------------------------

    // ***

    // --------------------------------------------------------------------
    // HTML HELPERS FUNCTIONS
    // --------------------------------------------------------------------

    // ***

    // --------------------------------------------------------------------
    // UTILITY FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Proxy an API Base function
     *
     * @param member
     * @returns {*}
     */
    function base(member) {
        return window.App.Api.Base[member];
    }
}(window, document, jQuery);