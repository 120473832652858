+function (window, document, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.SaisieArrivee = window.App.Pages.SaisieArrivee || {};

    var module = window.App.Pages.SaisieArrivee;

    module.views = module.views || {};
    module.name = 'SaisieArrivee';

    $(document).ready(function () {
        window.App.OnePage.load(module.name, initPage);
    });

    return void 0;

    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------
    // ---------- App Definition
    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------

    function initPage(pageEl) {
        var App = {
                views: {
                    'home': module.views.home,
                    'existing': module.views.existing,
                    'new': module.views.new
                },
                init: init,
                goto: goto,
                showError: showError,
                showWaiting: showWaiting,
                // Internals,
                getParcel: getParcel,
                redirectFullInput: redirectFullInput,
                // Utilities
                round: round,
                deepMerge: deepMerge,
                deepCompile: deepCompile,
                selectFirst: selectFirst
            },
            Api = window.App.Api;

        App.init();

        // ------------------------------------------------------------------------
        // ------------------------------------------------------------------------
        // ---------- App functions
        // ------------------------------------------------------------------------
        // ------------------------------------------------------------------------

        /**
         * Bootstrap the page views
         */
        function init() {
            window.App.OnePage.initViews(App, pageEl);

            App.goto('home');
        }


        /**
         * Display a SWAL alert box
         * @param {*} message The title as string or `{ title: string, text: string }`
         * @param {*} error Optional error data
         * @param {*} cb callback function on close
         */
        function showError(message, error, cb) {
            return window.App.Alert.showError(message, error, cb);
        }

        /**
         * Display a waiting SWAL alert box
         *
         * @returns {{close: Function}} Call close to close the waiting bo
         */
        function showWaiting(title) {
            return window.App.Alert.showWaiting(title);
        }

        /**
         * Change the view
         * @param {string} view The view key
         * @param {*} [data] Optional data to pass to the view onEnter event
         */
        function goto(view, data) {
            return window.App.OnePage.gotoView(this, view, data);
        }

        // --------------------------------------------------------------------
        // INTERNAL FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Returns the api query used to retrieve a parcel with data
         *
         * @param {string} number
         * @param {[]} includes
         * @returns {*} API deferred object with `done` and `fail` method
         */
        function getParcel(number, includes) {
            var defaults = ['produit', 'transporteur', 'expediteur', 'destinataire', 'pdvAchat', 'pdvDepot', 'impression.etiquette'];

            if ('undefined' !== typeof includes && $.isArray(includes) && includes.length) {
                defaults = defaults.concat(includes);
            }

            return Api.Colikado.getParcel(number, defaults);
        }

        /**
         * HTTP redirect to full input page for given parcel number
         *
         * @param {string} number
         */
        function redirectFullInput(number) {
            window.location.href = pageEl.data('url-full-input').replace('--numerock--', number);
        }

        // --------------------------------------------------------------------
        // UTILITY FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Rounds a value to the given precision (accepts strings ; passed to parseFloat)
         * @param {*} value
         * @param {int} precision
         *
         * @returns {number}
         */
        function round(value, precision) {
            return window.App.Math.round(value, precision);
        }

        /**
         * Deep merge an object into form fields through `field.val()` function
         *
         * @internal not exposed
         */
        function deepMerge(target, source) {
            return window.App.Bind.deepMerge(target, source);
        }


        /**
         * Deep merge an object into form fields through `field.val()` function
         *
         * @internal not exposed
         */
        function deepCompile(source, target, prefix) {
            return window.App.Bind.deepCompile(source, target, prefix);
        }

        /**
         * Select the first element of a select
         *
         * @internal not exposed
         */
        function selectFirst(element) {
            return window.App.Utils.selectFirst(element);
        }
    }
}(window, document, jQuery);