+function (window, document, $) {

    window.Colipays = window.Colipays || {};
    window.Colipays.Api = window.Colipays.Api || {};

    var module = {
            /* internals */
            _dataApiKey: 'cv2.codepostal',
            _endpoint: '/geo/countries/:country/cities?code_postal=:zipcode&limit=50', // Central API,
            _cache: {},
            /* required module methods */
            init: init,
            autocomplete: autocomplete
        },
        api = window.Colipays.Api;

    api.CodePostal = module;

    $(document).on('colipays:api:loaded', function () {
        api = window.Colipays.Api;
        module.init();
    });

    return void 0;

    // ---------- FUNCTIONS ---------------------------------------------------

    function init() {
        var self = this;

        $('[data-trigger="' + self._dataApiKey + '"]')
            .each(function () {
                self.autocomplete($(this));
            });

        // Inject jQuery wrapper
        $.fn.codePostal = function () {
            return this.each(function () {
                self.autocomplete($(this));
            });
        };
    }

    function autocomplete(item) {
        var self = this,
            target = item.data('target');

        item.suggest({
            accent: false,
            minLength: 3,
            dropdownWidth: 'auto',
            appendMethod: 'replace',
            limit: 50,
            visibleLimit: 20,
            valid: function () {
                return true;
            },
            source: [
                function (q, add) {
                    var term = q;

                    if (term) {
                        if (typeof self._cache[term] != 'undefined') {
                            add(self._cache[term]);
                            return;
                        }

                        api.$get(self._endpoint.replace(/:country/, 'fr').replace(/:zipcode/, term))
                            .done(function (cities) {
                                if (cities.meta.pagination.total > 0) {
                                    self._cache[term] = cities.data;
                                    add(cities.data);
                                }
                            });
                    }
                }
            ],
            getTitle: function (item) {
                return item.code_postal + ' - ' + item.nom;
            },
            getValue: function (item) {
                return item.code_postal
            }
        }).on('selected.suggest', function (e, item) {
            if (item) {
                $(target).val(item.nom);
            }
        });
    }

}(window, document, jQuery);