+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.CaisseCommande = window.App.Pages.CaisseCommande || {};
    window.App.Pages.CaisseCommande.views = window.App.Pages.CaisseCommande.views || {};

    window.App.Pages.CaisseCommande.views.edit = viewFactory;

    function viewFactory(App) {
        var state = {
                bindings: [],
                fields: {
                    client: {}
                },
                client: null
            },
            Alert = window.App.Alert,
            Api = window.App.Api,
            FormValidation = window.FormValidation;

        return {
            el: '#editView',
            $$loaded: false,
            // Called by App lifecycle
            onInit: onInit,
            onEnter: onEnter,
            onExit: onExit,
            // Local methods
            bindEvents: bindEvents,
            bindExternalControls: bindExternalControls,
            loadCountries: loadCountries,
            loadCustomer: loadCustomer,
            updateCustomer: updateCustomer,
            revealForm: revealForm,
            revealNewForm: revealNewForm,
            createCustomer: createCustomer,
            checkLoyaltyStatus: checkLoyaltyStatus,
            // Utils methods
            compile: compile,
            merge: merge
        };

        // --------------------------------------------------------------------
        // VIEW FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Initialize lists from API and load bindings
         */
        function onInit() {
            var view = this;

            window.App.Bind.bindFields(view.$$el, 'client', state.fields.client);

            // Bind to external UI elements
            view.bindExternalControls();

            // Load external data
            $.when(
                view.loadCountries(),
                view.loadCustomer()
            )
                .done(function () {
                    setTimeout(function () {
                        view.$$loaded = true;
                        view.$$el.trigger('loaded');
                    }, 10);
                });
        }

        /**
         * Initialize fields states, start autofocus on number
         */
        function onEnter(data) {
            var view = this;

            if (view.$$loaded) {
                load();
            } else {
                view.$$el.on('loaded', function () {
                    load();
                });
            }

            return void 0;

            /*
             * Internal functions
             */

            function load() {
                // Init events
                view.bindEvents();

                view.el('form').removeClass('hidden');
            }
        }

        /**
         * Binds all events
         */
        function bindEvents() {
            var view = this,
                form = view.el('form');

            /*
             * Customers callbacks
             */
            state.bindings.push((function () {
                view.component('customerPickup')
                    .on('customer.loaded', function (event, customer) {
                        view.updateCustomer(customer);

                        if (customer) {
                            view.revealNewForm(false);
                            view.checkLoyaltyStatus(customer);
                        }
                    })
                    .on('customer.reset', function (event, customer) {
                        view.updateCustomer(null);
                        view.revealNewForm(false);
                    })
                    .on('customer.new', function (event, customer) {
                        view.updateCustomer(null);
                        view.revealNewForm();
                    });

                return function () {
                    view.component('customerPickup').off('customer.loaded customer.reset');
                };
            })());

            /*
             * Actions
             */
            state.bindings.push((function () {
                view.el('client_create').on('click.page', function () {
                    if (!view.el('newForm').hasClass('hidde')) {
                        view.createCustomer();
                    }
                });

                return function () {
                    view.el('client_create').off('click.page');
                }
            })());

            state.bindings.push((function () {
                view.el('newForm').on('keypress.page', function (event) {
                    if (event.keyCode === 13) {
                        event.preventDefault();
                        event.stopPropagation();

                        view.createCustomer();
                    }
                });

                return function () {
                    view.el('newForm').off('keypress.page');
                }
            })());
        }

        function bindExternalControls() {
            var view = this,
                form = view.el('form');

            state.customerControl = view.el('commande_colipays_numero_client');
            state.formControl = view.el('commande_form_externe');
            state.newFormControl = view.el('newForm');
        }

        /**
         * Clear bindings, stop autofocus
         */
        function onExit() {
            var view = this,
                i;

            view.component('customerPickup').trigger('reset.customer');

            for (i = 0; i < state.bindings.length; ++i) {
                state.bindings[i]();
            }

            state.bindings = [];
        }

        /**
         * Copy customer data to external control
         */
        function updateCustomer(customer, disallowRefreshOnChange) {
            var view = this;

            if (customer) {
                state.customerControl.val(customer.customer_number);

                if (!disallowRefreshOnChange && view.el('form').data('refreshOnChange')) {
                    view.el('commande-update-form').submit();
                } else {
                    view.revealForm();
                }
            } else {
                state.customerControl.val('');
                view.revealForm(false);
            }
        }

        /**
         * Reveal or hide the main form control
         * @param visible `false` to hide, visible otherwise
         */
        function revealForm(visible) {
            if (visible === false) {
                state.formControl.addClass('hidden');
            } else {
                state.formControl.removeClass('hidden');
            }
        }

        /**
         * Reveal or hide the new customer form control
         * @param visible `false` to hide, visible otherwise
         */
        function revealNewForm(visible) {
            if (visible === false) {
                state.newFormControl.addClass('hidden');
            } else {
                state.newFormControl.removeClass('hidden');
            }
        }

        /**
         * Create a new customer with input data
         */
        function createCustomer() {
            var view = this,
                client = view.compile().client,
                buttons = $('button', state.newFormControl);

            FormValidation.validate(state.newFormControl.get(0))
                .success(function (validation) {
                    var wait = Alert.showWaiting('Création en cours...');

                    view.revealNewForm(false);

                    Api.Colipays.createCustomer({
                        civility: client.civilite,
                        lastname: client.nom,
                        firstname: client.prenom,
                        company: client.societe,
                        email: client.email,
                        phone: {
                            landline: client.telephone.fixe,
                            mobile: client.telephone.mobile
                        },
                        address: {
                            lines: [
                                client.adresse.lignes[0],
                                client.adresse.lignes[1]
                            ],
                            entry_code: client.adresse.digicode,
                            zipcode: client.adresse.code_postal,
                            city: client.adresse.ville,
                            country: client.adresse.pays
                        },
                        birthday: {
                            date: client.naissance.date ? moment(client.naissance.date, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
                            country: client.naissance.pays
                        },
                        optin: {
                            colipays: client.optin.colipays === 'on',
                            partner: client.optin.partner === 'on'
                        }
                    }).then(
                        function onSuccess(customer) {
                            view.updateCustomer(customer);
                        },
                        function onError(error) {
                            wait.close();

                            setTimeout(function () {
                                view.revealNewForm(true);

                                Alert.showError('La création du client a échoué', error);
                            }, 250);
                        }
                    ).done();

                    validation.reset();
                    buttons.prop('disabled', true);
                })
                .fail(function () {
                    App.showError('Saisie incomplète ou erronée', '<ul><li>Tous champs obligatoires doivent être renseignés.</li><li>Vérifiez le format des données saisies.</li></ul>');
                });
        }

        /**
         * Loads the initial customer data
         */
        function loadCustomer() {
            var view = this,
                customerComponent = view.component('customerPickup'),
                customerNumber = state.customerControl.val(),
                defer = $.Deferred();

            if (customerNumber) {
                customerComponent.one('customer.loaded', function (e, customer) {
                    view.updateCustomer(customer, true);
                    view.checkLoyaltyStatus(customer);

                    defer.resolve();
                });

                customerComponent.trigger('load.customer', customerNumber);
            } else {
                defer.resolve();

                if (!view.el('form').data('refreshOnChange')) {
                    view.revealForm();
                }
            }

            return defer;
        }

        /**
         * Hardcoded in fast input mode
         */
        function loadCountries() {
            var fields = $([
                state.fields.client.adresse.pays.code,
                state.fields.client.naissance.pays
            ]);

            $('<option></option>')
                .attr('value', 'RE')
                .text('REUNION')
                .appendTo(fields);

            return Api.Colikado.loadCountriesOptions(fields, {livre: true});
        }

        /**
         * Update the UI if the loyalty must be activated
         *
         * @param {*} customer
         */
        function checkLoyaltyStatus(customer) {
            var view = this,
                customerComponent = view.component('customerPickup');

            if (customer.loyalty.program === 'v1') {
                customerComponent.trigger('loyalty.show', [customer]);
            } else {
                customerComponent.trigger('loyalty.hide', [customer]);
            }
        }

        // --------------------------------------------------------------------
        // UTILITY FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Merge the given object into the form fields
         */
        function merge(source) {
            return App.deepMerge(state.fields, source);
        }

        /**
         * Merge the given object into the form fields
         */
        function compile() {
            return App.deepCompile(state.fields, {});
        }

    }
}(window, jQuery);