+function (window, document, $) {
    "use strict";

    var module = {
            initComponent: initComponent
        },
        Mustache = window.Mustache;

    window.App = window.App || {};
    window.App.Components = $.extend(true, window.App.Components || {}, module);

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    function initComponent(module, el, builder) {
        if (!el.length) {
            el = $(el);
        }

        // Ensure builder exists
        builder = builder || {};

        // Prepare
        var view = $.extend(true, {}, module.defaults, el.data());

        if ('undefined' === typeof view.modelPrefix || !view.modelPrefix) {
            throw 'A component requires a model-prefix attribute or a modelPrefix default property';
        }

        if (!view.modelPrefix.match(/\.$/)) {
            view.modelPrefix += '.';
        }

        view.modelPrefixSlug = $.camelCase(view.modelPrefix.replace(/\./g, '-'));
        view.templateId = el.attr('id') || ( view.modelPrefixSlug + '' + new Date().getTime() + '' + Math.round(Math.random() * 1000));
        view.templateSelector = '[v-component-instance=\'' + view.templateId + '\']';

        var template = 'function' === typeof builder.prepare && builder.prepare(view);

        if ('undefined' === typeof template || null === template) {
            throw 'A component builder prepare method must return an HTML template';
        }

        // Build
        var component = $(Mustache.render(template, view));

        component.attr('v-component-instance', view.templateId);

        if (1 !== component.length) {
            throw 'A component requires a single root element: ' + el.attr('v-component');
        }

        builder.el = (function (prefix, component) {
            return function findElement(key) {
                if(!key) {
                    return component;
                }

                return $('[v-el="' + prefix + key + '"]', component);
            }
        })(view.modelPrefix, component);

        if ('string' === typeof view.required) {
            var i,
                required = (view.required || '').split(',');

            for (i = 0; i < required.length; ++i) {
                builder.el(required[i].trim())
                    .prop('required', true)
                    .closest('.form-group')
                    .addClass('field-required');
            }
        }

        'function' === typeof builder.build && builder.build(component, view);

        component.insertAfter(el);
        el.remove();

        // Bind
        'function' === typeof builder.bind && builder.bind(component, view);

        return component;
    }
}(window, document, jQuery);