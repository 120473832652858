+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.StocksPlanner = window.App.Pages.StocksPlanner || {};
    window.App.Pages.StocksPlanner.views = window.App.Pages.StocksPlanner.views || {};

    window.App.Pages.StocksPlanner.views.home = viewFactory;

    function viewFactory(App) {
        var state = {
                bindings: [],
                fields: {session: {}},
                session: {
                    list: {
                        id: null,
                        name: null
                    },
                    dateFrom: {
                        val: null,
                        format: null
                    },
                    dateUntil: {
                        val: null,
                        format: null
                    }
                },
                template: null,
                emptyTemplate: null,
                lists: null,
                plannerItemTemplate: null,
                componentItemTemplate: null,
                $firstItem: null
            },
            Api = window.App.Api,
            Utils = window.App.Utils,
            FormValidation = window.FormValidation;

        return {
            el: '#homeView',
            $initialized: false,
            // Called by App lifecycle
            onInit: onInit,
            onEnter: onEnter,
            onExit: onExit,
            // Local methods
            bindEvents: bindEvents,
            loadLists: loadLists,
            resetForm: resetForm,
            reloadTable: reloadTable,
            createTable: createTable,
            fetchRemoteData: fetchRemoteData,
            getPlannerDay: getPlannerDay,
            setPlannerDay: setPlannerDay,
            clearStorage: clearStorage,
            progress: progress,
            renderDayTable: renderDayTable,
            bindDayTable: bindDayTable,
            mergeItem: mergeItem,
            mergeComponent: mergeComponent,
            updateStocks: updateStocks
        };

        // --------------------------------------------------------------------
        // VIEW FUNCTIONS
        // --------------------------------------------------------------------

        function onInit() {
            var view = this,
                dateFrom = view.el('date-from'),
                dateUntil = view.el('date-until'),
                wait = App.showWaiting();


            window.App.Bind.bindFields(view.$$el, 'session', state.fields.session);

            Utils.datepicker(dateFrom, dateUntil, {
                startDate: moment('2016-10-01', 'YYYY-MM-DD').toDate(),
                endDate: moment().add(45, 'days').toDate()
            });

            state.emptyTemplate = view.el('lists').html();
            state.plannerItemTemplate = view.el('planer-item-template').html();
            state.componentItemTemplate = view.el('planer-item-component-template').html();

            $.when(
                view.loadLists(),
                view.reloadTable()
            ).done(function () {
                view.$initialized = true;

                view.$$el.trigger('loaded');
                wait.close();
            })
        }

        /**
         * Initialize fields states, start autofocus on number
         */
        function onEnter() {
            var view = this,
                load = function () {
                    view.bindEvents();
                };

            view.el('date-from').datepicker('update', moment().format('DD/MM/YYYY'));
            view.el('date-until').datepicker('update', moment().add(45, 'days').format('DD/MM/YYYY'));

            if (view.$initialized) {
                load();
            } else {
                view.$$el.on('loaded', load)
            }
        }

        /**
         * Binds all events
         */
        function bindEvents() {
            var view = this;

            /*
            * Create a new table
            */
            state.bindings.push((function () {
                view.el('create')
                    .on('click', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        FormValidation.validate(view.el('search-form').get(0))
                            .success(function (validation) {
                                var dateFrom = moment(view.el('date-from').val(), 'DD/MM/YYYY'),
                                    dateUntil = moment(view.el('date-until').val(), 'DD/MM/YYYY');
                                validation.reset();

                                if (dateUntil.isBefore(dateFrom)) {
                                    var tmp = dateUntil;
                                    dateUntil = dateFrom;
                                    dateFrom = tmp;
                                }

                                if (dateUntil.diff(dateFrom, 'days') > 45) {
                                    dateUntil = moment(dateFrom).add(45, 'day');
                                }

                                state.session.list = state.lists[view.el('lists').val()];
                                state.session.dateFrom.val = dateFrom.format('YYYY-MM-DD');
                                state.session.dateFrom.format = dateFrom.format('DD/MM/YYYY');
                                state.session.dateUntil.val = dateUntil.format('YYYY-MM-DD');
                                state.session.dateUntil.format = dateUntil.format('DD/MM/YYYY');

                                App.deepMerge(state.fields.session, state.session);

                                view.resetForm();
                                view.createTable();
                            })
                            .fail(function () {
                                App.showError('Les données saisies ne sont pas valides');
                            })
                    });

                return function () {
                    view.el('create').off('click');
                };
            })());

            /*
            * Show the create form
            */
            state.bindings.push((function () {
                view.el('reset')
                    .on('click', function () {
                        App.showConfirm('Confirmation', 'Etes-vous sur de vouloir créer un autre planificateur ?', function (dialog) {
                            view.el('form-panel').toggleClass('hidden');
                            view.el('view-panel').toggleClass('hidden');

                            view.el('planner-items').empty();

                            view.clearStorage();

                            dialog.close();
                        }, function () {
                            //
                        })
                    });

                return function () {
                    view.el('reset').off('click');
                };
            })());
        }

        /**
         * Clear bindings, stop autofocus
         */
        function onExit() {
            var i;

            for (i = 0; i < state.bindings.length; ++i) {
                state.bindings[i]();
            }

            state.bindings = [];
        }

        /**
         * Reset the creation form
         */
        function resetForm() {
            var view = this;

            App.selectFirst(view.el('lists'), true);

            view.el('date-from').val('').datepicker('update');
            view.el('date-until').val('').datepicker('update');
        }

        /**
         * Load list select options
         */
        function loadLists() {
            var view = this;

            return Api.Colipays.Planner.getComponentsLists().then(function (items) {
                var $lists = view.el('lists').empty().append($(state.emptyTemplate));

                state.lists = {};

                items.data.forEach(function (list) {
                    $lists.append($('<option value="' + list.id + '">' + list.name + '</option>'));

                    state.lists[list.id] = list;
                });
            });
        }

        /**
         * Reload a saved table in session
         */
        function reloadTable() {
            var view = this,
                deferred = $.Deferred(),
                session = window.localStorage.getItem('stocks.planner.session');

            if (session) {
                state.session = JSON.parse(session);

                App.deepMerge(state.fields.session, state.session);

                view.el('form-panel').toggleClass('hidden');
                view.el('view-panel').toggleClass('hidden');

                view.fetchRemoteData().then(function () {
                    deferred.resolve();
                });
            } else {
                deferred.resolve();
            }

            return deferred.promise();
        }

        /**
         * Create a new table
         */
        function createTable() {
            var view = this,
                wait = App.showWaiting();

            view.el('form-panel').toggleClass('hidden');
            view.el('view-panel').toggleClass('hidden');

            window.localStorage.setItem('stocks.planner.session', JSON.stringify(state.session));

            view.fetchRemoteData().then(function () {
                wait.close();
            })
        }

        /**
         *
         * @param {string} key
         * @returns {*}
         */
        function getPlannerDay(key) {
            var data;

            if (data = window.localStorage.getItem('stocks.planner.' + key)) {
                data = JSON.parse(data);
            }

            return data;
        }

        /**
         *
         * @param {string} key
         * @param {*} data
         */
        function setPlannerDay(key, data) {
            window.localStorage.setItem('stocks.planner.' + key, JSON.stringify(data));
        }

        /**
         * Clear all stored planner data
         */
        function clearStorage() {
            var key, queue = ['stocks.planner.session'];

            for (var i = 0; i < localStorage.length; i++) {
                key = window.localStorage.key(i);

                if (key.match(/^stocks\.planner\..+/)) {
                    queue.push(key);
                }
            }

            queue.forEach(function (key) {
                window.localStorage.removeItem(key);
            });

            state.session = {
                list: {
                    id: null,
                    name: null
                },
                dateFrom: {
                    val: null,
                    format: null
                },
                dateUntil: {
                    val: null,
                    format: null
                }
            };
            state.$firstItem = null;
        }

        /**
         *  Initialize the progress bar
         *
         * @param {int} max
         * @returns {{advance: advance, terminate: terminate}}
         */
        function progress(max) {
            var view = this,
                $bar = view.el('progress-bar'),
                $label = view.el('progress-label'),
                tick = 0;

            $bar.closest('.progress-container').removeClass('hidden');

            return {
                current: function () {
                    return Math.round(tick * 100 / max)
                },
                advance: function () {
                    var progress = Math.round(++tick * 100 / max);

                    $label.text(progress + ' %');
                    $bar.css('width', progress + '%');
                },
                terminate: function () {
                    $label.text('');
                    $bar.css('width', 0).closest('.progress-container').addClass('hidden');
                }
            };
        }

        /**
         * Load in local storage remote data
         */
        function fetchRemoteData() {
            var view = this,
                deferred = $.Deferred(),
                date = moment(state.session.dateFrom.val, 'YYYY-MM-DD'),
                end = moment(state.session.dateUntil.val, 'YYYY-MM-DD'),
                queue = [],
                progress = view.progress(end.diff(date, 'days') + 1),
                i = 0;

            while (date.isSameOrBefore(end)) {
                queue.push(Api.Colipays.Planner.getInventoriesByDate(date.format('YYYY-MM-DD'), state.session.list.id)
                    .done(function (result) {
                        var key = state.session.list.id + '.' + moment(result.meta.departure_at, 'YYYY-MM-DD').format('YYYYMMDD'),
                            itemKey = 'planner-item-' + key.replace(/[^a-z0-9]+/i, '-'),
                            old = view.getPlannerDay(key),
                            render = view.el(itemKey).length === 0;

                        if (old) {
                            if (old.meta.hash !== result.meta.hash) {
                                view.setPlannerDay(key, result);
                                render = true;
                            }
                        } else {
                            view.setPlannerDay(key, result);
                        }

                        if (render) {
                            view.renderDayTable(key, itemKey);
                        }

                        progress.advance();
                    }));

                date.add(1, 'day').startOf('day');
            }

            $.when.apply($, queue).done(function () {
                var $lastItem = null;

                state.$firstItem = $($('[data-sort]', view.el('planner-items'))
                    .sort(function (a, b) {
                        var orderA = parseInt($(a).attr('v-el').split('-').pop());
                        var orderB = parseInt($(b).attr('v-el').split('-').pop());

                        return (orderA < orderB) ? -1 : (orderA > orderB) ? 1 : 0;
                    })
                    .each(function (_, item) {
                        var $item = $(item);

                        $item.parent().append(item);

                        if ($lastItem) {
                            $lastItem.data('next', $item);
                            $item.data('prev', $lastItem);
                        }

                        $lastItem = $item;
                    })
                    .get(0));

                progress.terminate();

                state.$firstItem.data('prev', $('<dummy></dummy>').data('key', 'default'));
                date = moment(view.getPlannerDay(state.$firstItem.data('key')).meta.departure_at, 'YYYY-MM-DD').add(-1, 'day');

                Api.Colipays.Planner.getInventoriesByDate(date.format('YYYY-MM-DD'), state.session.list.id)
                    .done(function (result) {
                        view.setPlannerDay('default', result);

                        view.updateStocks();

                        deferred.resolve();
                    });
            });

            return deferred.promise();
        }

        function renderDayTable(key, itemKey) {
            var view = this,
                data = view.getPlannerDay(key),
                container = view.el('planner-items'),
                $item = $(state.plannerItemTemplate).attr('v-el', itemKey).data('key', key),
                fields = {main: {}, components: {}};

            // Load main data
            //
            window.App.Bind.bindFields($item, 'item', fields.main);

            view.mergeItem(fields.main, data);

            // Load components rows
            //
            data.data.forEach(function (data) {
                var $component = $(state.componentItemTemplate);

                window.App.Bind.bindFields($component, 'item', fields.components[data.code] = {});

                view.mergeComponent(fields.components[data.code], data);

                $('[v-el="components"]', $item).append($component);
            });

            // Add to items list
            //
            container.append($item);

            // Bind Events
            //
            view.bindDayTable(key, fields, $item)
        }

        function bindDayTable(key, fields, $item) {
            var view = this,
                updateVolume = function (value) {
                    value = parseFloat(value);

                    var data = view.getPlannerDay(key),
                        old = data.meta.volumes.planned,
                        updateView = function () {
                            view.setPlannerDay(key, data);
                            view.mergeItem(fields.main, data);
                            view.updateStocks();
                        };

                    if (old !== value) {
                        data.meta.volumes.planned = value;
                        data.meta.volumes.coefficient = Math.round(100 * data.meta.volumes.planned / data.meta.volumes.sold) / 100;

                        updateView();

                        Api.Colipays.Planner.setVolumesByDate(data.meta.departure_at, value).fail(function () {
                            App.showError('La modification du volume disponible a échoué pour le ' + moment(data.meta.departure_at, 'YYYY-MM-DD').format('dddd DD MMMM YYYY'));

                            data.meta.volumes.planned = old;
                            data.meta.volumes.coefficient = Math.round(100 * data.meta.volumes.planned / data.meta.volumes.sold) / 100;

                            updateView();
                        });
                    }
                },
                updateInventory = function (code, entrance_quantity, planned_stock_variation) {
                    entrance_quantity = parseFloat(entrance_quantity);
                    planned_stock_variation = parseFloat(planned_stock_variation);

                    var data = view.getPlannerDay(key), old,
                        changed = false,
                        updateView = function () {
                            view.setPlannerDay(key, data);
                            view.mergeItem(fields.main, data);
                            view.updateStocks();
                        };

                    data.data = data.data.map(function (data) {
                        if (data.code === code) {
                            old = {
                                entrance_quantity: data.entrance_quantity,
                                planned_stock_variation: data.planned_stock_variation
                            };

                            if (old.entrance_quantity !== entrance_quantity || old.planned_stock_variation !== planned_stock_variation) {
                                data.entrance_quantity = entrance_quantity;
                                data.planned_stock_variation = planned_stock_variation;
                                changed = true;
                            }
                        }

                        return data;
                    });

                    if (changed) {
                        updateView();

                        Api.Colipays.Planner.setInventoryMovement(data.meta.departure_at, code, entrance_quantity, planned_stock_variation).fail(function () {
                            App.showError('La modification du stock du composant ' + code + ' a échoué pour le ' + moment(data.meta.departure_at, 'YYYY-MM-DD').format('dddd DD MMMM YYYY'));

                            data.data = data.data.map(function (data) {
                                if (data.code === code) {
                                    data.entrance_quantity = old.entrance_quantity;
                                    data.planned_stock_variation = old.planned_stock_variation;
                                }

                                return data;
                            });

                            updateView();
                        });
                    }
                },
                data = view.getPlannerDay(key);
            // Allow edit
            //
            fields.main.volumes.planned
                .on('blur', function (e) {
                    updateVolume(fields.main.volumes.planned.val());
                })
                .on('keypress', function (e) {
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        e.stopPropagation();

                        updateVolume(fields.main.volumes.planned.val());
                    }
                });
            data.data.forEach(function (data) {
                $.each([fields.components[data.code].entrance_quantity, fields.components[data.code].planned_stock_variation], function () {
                    this
                        .on('blur', function (e) {
                            updateInventory(data.code, fields.components[data.code].entrance_quantity.val(), fields.components[data.code].planned_stock_variation.val());
                        })
                        .on('keypress', function (e) {
                            if (e.keyCode === 13) {
                                e.preventDefault();
                                e.stopPropagation();

                                updateInventory(data.code, fields.components[data.code].entrance_quantity.val(), fields.components[data.code].planned_stock_variation.val());
                            }
                        });
                });
            });
            // Refresh displayed values on data update
            //
            $item.on('update', function () {
                var data = view.getPlannerDay(key);

                view.mergeItem(fields.main, data);

                data.data.forEach(function (data) {
                    view.mergeComponent(fields.components[data.code], data);
                });

                $(this).find('[data-negative]').each(function () {
                    var $item = $(this);

                    if (1.00 * $item.text() < 0.00) {
                        $item.closest('.--numeric').addClass($item.data('negative'));
                    } else {
                        $item.closest('.--numeric').removeClass($item.data('negative'));
                    }
                });
            });
        }

        function mergeItem(fields, source) {
            var data = {
                volumes: source.meta.volumes,
                departure_at: moment(source.meta.departure_at, 'YYYY-MM-DD').format('dddd DD MMMM YYYY')
            };

            App.deepMerge(fields, data);
        }

        function mergeComponent(fields, source) {
            App.deepMerge(fields, source);
        }

        function updateStocks() {
            var view = this,
                $item = state.$firstItem,
                current, previous;

            do {
                current = view.getPlannerDay($item.data('key'));
                previous = view.getPlannerDay($item.data('prev').data('key'));

                current.data = current.data.map(function (component) {
                    var previousComponent = previous.data.filter(function (other) {
                        return other.code === component.code;
                    })[0];

                    component.starting_stock_quantity = previousComponent.planned_stock + component.entrance_quantity;
                    component.stock_quantity = component.starting_stock_quantity - component.sold_quantity;
                    component.planned_sold_quantity = Math.ceil(component.sold_quantity * current.meta.volumes.coefficient);
                    component.planned_stock = component.starting_stock_quantity - component.planned_sold_quantity + component.planned_stock_variation;

                    return component;
                });

                view.setPlannerDay($item.data('key'), current);

                $item.trigger('update');
            } while ($item = $item.data('next'))
        }
    }
}(window, jQuery);