+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.SaisieComplete = window.App.Pages.SaisieComplete || {};
    window.App.Pages.SaisieComplete.views = window.App.Pages.SaisieComplete.views || {};

    window.App.Pages.SaisieComplete.views.home = viewFactory;

    function viewFactory(App) {
        var state = {
                bindings: [],
                focusInterval: null,
                canFocus: true
            },
            FormValidation = window.FormValidation;

        return {
            el: '#homeView',
            // Called by App lifecycle
            onEnter: onEnter,
            onExit: onExit,
            // Local methods
            bindEvents: bindEvents,
            reset: reset,
            validateNumber: validateNumber,
            canEditParcel: canEditParcel
        };

        // --------------------------------------------------------------------
        // VIEW FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Initialize fields states, start autofocus on number
         */
        function onEnter() {
            var view = this,
                form = view.el('form'),
                number = view.el('numerock'),
                alert = view.el('alert');

            alert.addClass('hidden');

            state.focusInterval = setInterval(function () {
                if (state.canFocus) {
                    number.prop('disabled', false);
                    number.focus();
                } else {
                    number.prop('disabled', true);
                }
            }, 250);

            // Auto switch view if number is set
            if (number.val()) {
                view.validateNumber()
                    .fail(function () {
                        view.bindEvents();
                    })
            } else {
                view.bindEvents();
            }
        }

        /**
         * Binds all events
         */
        function bindEvents() {
            var view = this,
                form = view.el('form');

            /*
             * Validate the number when requested
             */
            state.bindings.push((function () {
                form
                    .on('submit', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        view.validateNumber();
                    })
                    .on('reset', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        view.reset();
                    });

                return function () {
                    form.off('submit reset');
                };
            })());

            /*
             * Reset the form
             */
            state.bindings.push((function () {
                var reset = view.el('reset')
                    .on('click', function () {
                        view.reset();
                    });

                return function () {
                    reset.off('click');
                };
            })());

            /*
             * Handle history
             */
            if ('function' === typeof window.history.replaceState) {
                window.history.replaceState(
                    {
                        page: 'home-full'
                    }, null, view.$$el.closest('[v-page]').data('url-home')
                );
            }
        }

        /**
         * Clear bindings, stop autofocus
         */
        function onExit() {
            var i;

            state.focusInterval && clearInterval(state.focusInterval);

            for (i = 0; i < state.bindings.length; ++i) {
                state.bindings[i]();
            }

            state.bindings = [];
        }

        /**
         * Clear the number field, keep the carrier
         */
        function reset() {
            var view = this,
                form = view.el('form'),
                number = view.el('numerock'),
                alert = view.el('alert');

            number.val('').focus();
            alert.text('').addClass('hidden');
        }

        /**
         * Call API to check the number if it matches the provided pattern
         */
        function validateNumber() {
            var view = this,
                defer = $.Deferred(),
                form = view.el('form'),
                number = view.el('numerock'),
                fields = form.find('input,select,button,a'),
                value = number.val(),
                failureAlert = function (msg) {
                    msg = msg || '!!! Numéro [<strong>' + value + '</strong>] invalide !!!';
                    view.el('alert').html(msg).removeClass('hidden');
                };

            FormValidation.validate(view.el('form').get(0))
                .success(function (validation) {
                    validation.reset();
                    view.reset();
                    fields.prop('disabled', true);

                    var wait = App.showWaiting('Récupération des données');

                    App.getParcel(value)
                        .done(function (parcel) {
                            wait.close();
                            fields.prop('disabled', false);

                            view.canEditParcel(parcel)
                                .done(function () {
                                    defer.resolve();

                                    App.goto('edit', {
                                        parcel: parcel
                                    });
                                })
                                .fail(function (errors) {
                                    failureAlert(
                                        'Le colis <strong>' + parcel.numero + '</strong> ne peut pas être saisie pour le(s) motif(s) suivant(s) : <ul>' +
                                        errors.join('</li><li>') +
                                        '</ul>'
                                    );
                                    defer.reject();
                                });
                        })
                        .fail(function () {
                            fields.prop('disabled', false);
                            wait.close();
                            failureAlert();
                            defer.reject();
                        });
                })
                .fail(function () {
                    view.reset();
                    failureAlert();
                    defer.reject();
                });

            return defer;
        }

        function canEditParcel(parcel) {
            var canEdit = true,
                test = null,
                errors = [],
                defer = $.Deferred();

            // Parcel mus not be loaded or if loaded must have been quickly inputed
            test = !parcel.fret.embarque || (!parcel.saisie.termine && parcel.saisie.rapide);
            canEdit = canEdit && test;

            if (!test) {
                errors.push('Le bordereau est déjà associé à une palette, modification impossible.');
            }

            // Parcel must not be linked to non valid order for COLIPAYS and COLIKADO point of sales (at least one valid order must exists
            if (
                typeof parcel.vente == 'object' && typeof parcel.vente.achat == 'object' && parcel.vente.achat.code &&
                (parcel.vente.achat.code === '000' || parcel.vente.achat.code === '022') && !parcel.numero.match(/^TT.+$/)
            ) {
                test = typeof parcel.commandes == 'object' && parcel.commandes.data && parcel.commandes.data.length &&
                    parcel.commandes.data.reduce(function (valid, order) {
                        return valid || order.valid;
                    }, false)

                if (!test) {
                    errors.push('Le bordereau n\'a pas été facturé en caisse.');
                }
            } else {
                test = true;
            }

            canEdit = canEdit && test;

            if (canEdit) {
                defer.resolve();
            } else {
                defer.reject(errors)
            }

            return defer;
        }
    }
}(window, jQuery);