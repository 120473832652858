jQuery(function ($) {
    $('input')
        .not('[type=submit],[type=reset],[type=button],[type=hidden],[type=radio],[type=checkbox]')
        .add($('select,textarea'))
        .not('.form-control')
        .addClass('form-control');

    $('label')
        .not('.control-label')
        .addClass('control-label');

    $('.form-horizontal .checkbox-custom label')
        .removeClass('control-label');

    $('[type=submit], .bouton', $('form, table'))
        .removeClass('bouton')
        .addClass('btn btn-primary btn-squared');

    $('[type=button]', $('form, table'))
        .not('.btn')
        .removeClass('bouton')
        .addClass('btn btn-info btn-squared');

    $('[type=reset]', $('form, table'))
        .not('.btn')
        .removeClass('bouton')
        .addClass('btn btn-default btn-squared');
});