+function (window, document, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Api = window.App.Api || {};
    window.App.Api.Colipays = window.App.Api.Colipays || {};
    window.App.Api.Colipays.Planner = {
        // Public functions,
        $$api: base('$$api'),
        // Data functions
        getComponentsLists: getComponentsLists,
        getComponentsList: getComponentsList,
        createComponentsList: createComponentsList,
        updateComponentsList: updateComponentsList,
        removeComponentsList: removeComponentsList,
        addComponentToList: addComponentToList,
        removeComponentFromList: removeComponentFromList,
        getVolumesByDate: getVolumesByDate,
        setVolumesByDate: setVolumesByDate,
        getInventoriesByDate: getInventoriesByDate,
        setInventoryMovement: setInventoryMovement,
        // Transformers
        transformIn: base('transformIn'),
        transformOut: base('transformOut'),
        // Utility functions
        _buildParams: base('_buildParams')
    };

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    // ***

    // --------------------------------------------------------------------
    // DATA LOAD FUNCTIONS
    // --------------------------------------------------------------------


    /**
     * Get all components lists
     *
     * @param {[]|string=} include relations to include
     *
     * @returns {*} a promise that is resolve in `done()` with lists data or rejected `fail()` with error
     */
    function getComponentsLists(include) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/stocks/planner/lists', api._buildParams(null, include))
            .done(function (result) {
                result.data = result.data.map(function (item) {
                    return api.transformIn(item);
                });

                defer.resolve(result);
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Get a components list
     *
     * @param {int} id the list ID
     * @param {[]|string=} include relations to include
     *
     * @returns {*} a promise that is resolve in `done()` with list data or rejected `fail()` with error
     */
    function getComponentsList(id, include) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/stocks/planner/lists/' + id, api._buildParams(null, include))
            .done(function (list) {
                defer.resolve(api.transformIn(list));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Create a new components list
     *
     * @param {*} data the list data
     *
     * @returns {*} a promise that is resolve in `done()` with the list data or rejected `fail()` with error
     */
    function createComponentsList(data) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$post('/stocks/planner/lists', api._buildParams({name: data.name}))
            .done(function (list) {
                defer.resolve(api.transformIn(list));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Update a components list
     *
     * @param {*} data the component data
     *
     * @returns {*} a promise that is resolve in `done()` with the list data or rejected `fail()` with error
     */
    function updateComponentsList(data) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$put('/stocks/planner/lists/' + data.id, api._buildParams({code: data.code, name: data.name}))
            .done(function (list) {
                defer.resolve(api.transformIn(list));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Add a component to a list
     *
     * @param {int} id the component ID
     *
     * @returns {*} a promise that is resolve in `done()` with no data or rejected `fail()` with error
     */
    function removeComponentsList(id) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$delete('/stocks/planner/lists/' + id, api._buildParams())
            .done(function (list) {
                defer.resolve(api.transformIn(list));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Add a component to a list
     *
     * @param {int} id the list ID
     * @param {*} data the component's data
     *
     * @returns {*} a promise that is resolve in `done()` with the component data or rejected `fail()` with error
     */
    function addComponentToList(id, data) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$post('/stocks/planner/lists/' + id + '/components', api._buildParams({code: data.code, name: data.name}))
            .done(function (list) {
                defer.resolve(api.transformIn(list));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }


    /**
     * Remove a component from a list
     *
     * @param {int} id the component ID
     *
     * @returns {*} a promise that is resolve in `done()` with no data or rejected `fail()` with error
     */
    function removeComponentFromList(id) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$delete('/stocks/planner/lists/components/' + id, api._buildParams())
            .done(function (list) {
                defer.resolve(api.transformIn(list));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Get a components list
     *
     * @param {string} date in YYYY-mm-dd format
     *
     * @returns {*} a promise that is resolve in `done()` with volumes data or rejected `fail()` with error
     */
    function getVolumesByDate(date) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/stocks/planner/volumes/' + date, api._buildParams())
            .done(function (list) {
                defer.resolve(api.transformIn(list));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Get volumes by date
     *
     * @param {string} date departure date in YYYY-mm-dd format
     * @param {float} planned the planned volume amount
     *
     * @returns {*} a promise that is resolve in `done()` with volumes data or rejected `fail()` with error
     */
    function setVolumesByDate(date, planned) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$post('/stocks/planner/volumes/' + date, api._buildParams({planned: planned}))
            .done(function (list) {
                defer.resolve(api.transformIn(list));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Get inventories list by date
     *
     * @param {string} date departure date in YYYY-mm-dd format
     * @param {int=} list the list ID to filter
     *
     * @returns {*} a promise that is resolve in `done()` with inventories data and metadata or rejected `fail()` with error
     */
    function getInventoriesByDate(date, list) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/stocks/planner/inventories/' + date, api._buildParams({list: list}))
            .done(function (result) {
                result.data = result.data.map(function (item) {
                    return api.transformIn(item);
                });

                defer.resolve(result);
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Get inventories list by date
     *
     * @param {string} date departure date in YYYY-mm-dd format
     * @param {string} code the component code
     * @param {int} entrance_quantity the quantity received at departure date
     * @param {int} planned_stock_variation the stock variation to match reality of planned quantity at the end departure date
     *
     * @returns {*} a promise that is resolve in `done()` with inventory data or rejected `fail()` with error
     */
    function setInventoryMovement(date, code, entrance_quantity, planned_stock_variation) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$post('/stocks/planner/inventories/' + date + '/' + code, api._buildParams({
            entrance_quantity: entrance_quantity,
            planned_stock_variation: planned_stock_variation
        }))
            .done(function (inventory) {
                return api.transformIn(inventory);
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    // --------------------------------------------------------------------
    // DATA MANIPULATION FUNCTIONS
    // --------------------------------------------------------------------

    // ***

    // --------------------------------------------------------------------
    // ACTION HELPERS FUNCTIONS
    // --------------------------------------------------------------------

    // ***

    // --------------------------------------------------------------------
    // HTML HELPERS FUNCTIONS
    // --------------------------------------------------------------------

    // ***

    // --------------------------------------------------------------------
    // UTILITY FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Proxy an API Base function
     *
     * @param member
     * @returns {*}
     */
    function base(member) {
        return window.App.Api.Base[member];
    }
}(window, document, jQuery);