+function (window, document, $) {
    "use strict";

    var template = "<div class=\"AppForm__Product\">\n    <h3 class=\"form-content AppForm__Section_Titre\">\n        <span class=\"ico ico-2x ico-{{icon}}\"></span>\n        {{title}}\n    </h3>\n\n    <div class=\"row\">\n        <div class=\"col-sm-4 AppForm__Product_InternalNumber\">\n            <div class=\"form-group\">\n                <label class=\"control-label\">N° Bordereau</label>\n                <span v-el=\"{{modelPrefix}}numero\" class=\"as-input form-control-static\"></span>\n            </div>\n        </div>\n        <div class=\"col-sm-4 AppForm__Product_MeasuredWeight\">\n            <div class=\"form-group\">\n                <label for=\"{{modelPrefixSlug}}-poids_reel\" class=\"control-label\">Poids réel pesé</label>\n                <input type=\"number\" v-el=\"{{modelPrefix}}dimensions.poids_reel\" id=\"{{modelPrefixSlug}}-poids_reel\"\n                       class=\"form-control\" placeholder=\"0.000\" min=\"0\" max=\"30\" step=\"0.001\">\n                <span class=\"hint-text\">en KG</span>\n            </div>\n        </div>\n        <div class=\"col-sm-4 AppForm__Product_Product\">\n            <div class=\"form-group\">\n                <label for=\"{{modelPrefixSlug}}-product\" class=\"control-label\">Type de produit</label>\n                <select v-el=\"{{modelPrefix}}produit.id\" id=\"{{modelPrefixSlug}}-product\" class=\"form-control\">\n                    <option value=\"\">-- Sélectionnez un produit --</option>\n                </select>\n            </div>\n        </div>\n    </div>\n</div>",
        module = {
            directive: 'product-input',
            compile: compile,
            defaults: {
                modelPrefix: 'parcel',
                icon: 'parcel',
                title: 'Produit'
            }
        };

    window.App = window.App || {};
    window.App.Components = window.App.Components || {};
    window.App.Components.ParcelProductInput = module;

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    function compile(el) {
        var module = this,
            state = {
                // Builder
                prepare: prepare,
                build: build,
                bind: bind,
                // Events
                // Actions
                validateProduct: validateProduct,
                selectFirst: selectFirst
            };

        return window.App.Components.initComponent(module, el, state);

        /*
         * --------- FUNCTIONS ---------
         */

        function prepare(view) {
            return template;
        }

        function build(component, view) {
            var builder = this;

            // ***

            var natures = builder.el('nature');

            for (var nature in module.natures) {
                if (module.natures.hasOwnProperty(nature)) {
                    $('<option></option>')
                        .attr('value', nature)
                        .text(module.natures[nature])
                        .appendTo(natures);
                }
            }

            builder.selectFirst(natures);
        }

        function bind(component, view) {
            state.el('dimensions.poids_reel')
                .on('focus', function () {
                    var $this = $(this);

                    $this.val() == 0 && $this.val('');
                })
                .on('change keypress', function (e) {
                    if (e.type != 'keypress' || e.keyCode == 13) {
                        state.validateProduct();

                        e.preventDefault();
                        e.stopPropagation();
                    }
                });

            component.on('parcel.loaded', function (event, parcel) {
                state.numero = parcel.numero;
            });
        }

        /**
         * Auto select the best matching product in the list (only if not already selected !)
         */
        function validateProduct() {
            var i,
                poids_reel = window.App.Math.round(state.el('dimensions.poids_reel').val(), 3),
                produits = state.el('produit.id'),
                products = produits.data('products').filter(function (a) {
                    return window.App.Utils.parcelNumberMatch(state.numero, a.code);
                }).sort(function (a, b) {
                    return a.dimensions.poids - b.dimensions.poids;
                });

            if (produits.val() > 0 || !products || !products.length) {

                return void 0;
            }

            for (i = 1; i < products.length; ++i) {
                if (poids_reel < 0.5 * (products[i].dimensions.poids - products[i - 1].dimensions.poids) + products[i - 1].dimensions.poids) {
                    selectProduct(products[i - 1]);

                    // Exit now
                    return void 0;
                }
            }

            // Fallback bigger products
            selectProduct(products[products.length - 1]);

            return void 0;

            /*
             * Internal functions
             */

            function selectProduct(product) {
                state.el('produit.id').val(product.id);
                state.el('destinataire.adresse.code_postal').focus();
            }
        }


        /**
         * Select the first element of a select
         *
         * @internal not exposed
         */
        function selectFirst(element) {
            return window.App.Utils.selectFirst(element);
        }
    }
}(window, document, jQuery);
