+function (window, document, $) {

    var module = {
        // Public functions,
        datepicker: datepicker,
        selectFirst: selectFirst,
        selectValue: selectValue,
        toBoolean: toBoolean,
        equals: equals,
        parcelNumberVersion: parcelNumberVersion,
        parcelNumberMatch: parcelNumberMatch,
        parcelCodeMatch: parcelCodeMatch,
        // Properties
        _datepickerDefaults: {
            format: "dd/mm/yyyy",
            weekStart: 1,
            clearBtn: true,
            autoclose: true,
            language: "fr"
        }
    };

    window.App = window.App || {};
    window.App.Utils = module;

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Activate datepicker on jQuery elements
     */
    function datepicker() {
        var module = this, el;

        if (arguments.length) {

            if (arguments.length > 1) {
                var i = 0,
                    limit = arguments.length,
                    options = module._datepickerDefaults;

                if (!arguments[limit - 1].length) {
                    options = $.extend(true, {}, module._datepickerDefaults, arguments[limit - 1]);
                    --limit;
                }

                for (; i < limit; ++i) {
                    el = arguments[i].datepicker(options);

                    if ('function' === typeof options.onSelect) {
                        el.on('changeDate', function (e) {
                            options.onSelect(e.format(), e.date);
                        });
                    }
                }
            } else {
                arguments[0].datepicker(module._datepickerDefaults);
            }
        }
    }

    /**
     * If no value is set, select the first option value of a HTML select
     *
     * @param {*} element Select DOM element
     * @param {boolean} [force] force the selection even if a value is set
     */
    function selectFirst(element, force) {
        if (element && (force || !element.val())) {
            element.val($('option:first', element).prop('selected', true).val());
        }
    }

    /**
     * If no value is set, select the option with the given value of a HTML select
     *
     * @param {*} element Select DOM element
     * @param {string} value
     * @param {boolean} [force] force the selection even if a value is set
     */
    function selectValue(element, value, force) {
        if (element && (force || !element.val())) {
            var option = $('option[value="' + value + '"]', element);
            element.val(option.val());
            option.prop('selected', true)
        }
    }

    /**
     * Parse a string to a boolean value :
     * - yes / no
     * - y / n
     * - on / off
     * - true / false
     * - t / f
     * - 1 / 0
     *
     * @param str
     * @returns {boolean}
     */
    function toBoolean(str) {
        if (str === true) {
            return true;
        }

        if (str === false) {
            return false;
        }

        if (str == "true") {
            return true;
        }

        if (!str) {
            return false;
        }

        switch (str.length) {
            case 1: {
                var ch0 = str.charAt(0);

                if (ch0 == 'y' || ch0 == 'Y' ||
                    ch0 == 't' || ch0 == 'T' ||
                    ch0 == '1') {
                    return true;
                }

                if (ch0 == 'n' || ch0 == 'N' ||
                    ch0 == 'f' || ch0 == 'F' ||
                    ch0 == '0') {
                    return false;
                }

                break;
            }
            case 2: {
                var ch0 = str.charAt(0);
                var ch1 = str.charAt(1);

                if ((ch0 == 'o' || ch0 == 'O') &&
                    (ch1 == 'n' || ch1 == 'N')) {
                    return true;
                }

                if ((ch0 == 'n' || ch0 == 'N') &&
                    (ch1 == 'o' || ch1 == 'O')) {
                    return false;
                }

                break;
            }
            case 3: {
                var ch0 = str.charAt(0);
                var ch1 = str.charAt(1);
                var ch2 = str.charAt(2);

                if ((ch0 == 'y' || ch0 == 'Y') &&
                    (ch1 == 'e' || ch1 == 'E') &&
                    (ch2 == 's' || ch2 == 'S')) {
                    return true;
                }

                if ((ch0 == 'o' || ch0 == 'O') &&
                    (ch1 == 'f' || ch1 == 'F') &&
                    (ch2 == 'f' || ch2 == 'F')) {
                    return false;
                }

                break;
            }
            case 4: {
                var ch0 = str.charAt(0);
                var ch1 = str.charAt(1);
                var ch2 = str.charAt(2);
                var ch3 = str.charAt(3);

                if ((ch0 == 't' || ch0 == 'T') &&
                    (ch1 == 'r' || ch1 == 'R') &&
                    (ch2 == 'u' || ch2 == 'U') &&
                    (ch3 == 'e' || ch3 == 'E')) {
                    return true;
                }

                break;
            }
            case 5: {
                var ch0 = str.charAt(0);
                var ch1 = str.charAt(1);
                var ch2 = str.charAt(2);
                var ch3 = str.charAt(3);
                var ch4 = str.charAt(4);

                if ((ch0 == 'f' || ch0 == 'F') &&
                    (ch1 == 'a' || ch1 == 'A') &&
                    (ch2 == 'l' || ch2 == 'L') &&
                    (ch3 == 's' || ch3 == 'S') &&
                    (ch4 == 'e' || ch4 == 'E')) {
                    return false;
                }

                break;
            }
            default:
                break;
        }

        return false;
    }

    function equals(a, b) {
        return JSON.stringify(a) === JSON.stringify(b);
    }

    function parcelNumberVersion(numero) {
        if (numero.substr(0, 2) === 'TT') {
            return 'v2';
        }

        var sequence = numero && Math.floor((parseInt(numero.substr(2))) / 10);

        if (sequence <= 10108000) {
            return 'v1';
        }

        if (sequence >= 10108001 && sequence <= 10138000) {
            return 'v2';
        }

        return 'v2';
    }

    function parcelNumberMatch(number, code) {
        return this.parcelCodeMatch(this.parcelNumberVersion(number), code);
    }

    function parcelCodeMatch(version, code) {
        if (!version || !code) {
            return false;
        }

        switch (version) {
            case 'v1':
                // return code.match(/^[CE][0-9]{1,2}$/);
            case 'v2':
                return code.match(/^[CE][0-9]{3}$/);
        }

        return false;
    }

}(window, document, jQuery);
