+function (window, document, $) {

    var module = {
        // Public functions,
        round: round
    };

    window.App = window.App || {};
    window.App.Math = module;

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------


    /**
     * Rounds a value to the given precision (accepts strings ; passed to parseFloat)
     * @param {*} value
     * @param {int} precision
     *
     * @returns {number}
     */
    function round(value, precision) {
        var power = Math.pow(10, precision);

        return Math.round(parseFloat(('' + value).replace(',', '.')) * power) / power;
    }

}(window, document, jQuery);