+function (window, document, $) {

    var module = {
        // Public functions,
        print: print
    }, PrintServer = window.PrintServer;

    window.App = window.App || {};
    window.App.Print = module;

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------


    /**
     * Send a job to local print server
     *
     * @param {*} job
     *
     * @returns {number}
     */
    function print(job) {
        var deferred = $.Deferred();

        PrintServer.open().then(
            function (server) {
                server.Print(job).then(
                    function () {
                        deferred.resolve();
                    },
                    function (error) {
                        deferred.reject(error);
                    }
                );
            },
            function (error) {
                deferred.reject(error);
            }
        );

        return deferred.promise();
    }

}(window, document, jQuery);