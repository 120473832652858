+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.SaisieFax = window.App.Pages.SaisieFax || {};
    window.App.Pages.SaisieFax.views = window.App.Pages.SaisieFax.views || {};

    window.App.Pages.SaisieFax.views.new = viewFactory;

    function viewFactory(App) {
        var state = {
                bindings: [],
                fields: {
                    /** @namespace state.fields.dimensions */
                    /** @namespace state.fields.dimensions.poids_reel */
                    /** @namespace state.fields.produit */
                    /** @namespace state.fields.produit.id */
                    /** @namespace state.fields.expediteur.nom */
                    /** @namespace state.fields.expediteur.prenom */
                    /** @namespace state.fields.expediteur.adresse.code_postal */
                    /** @namespace state.fields.expediteur.adresse.pays.code */
                    /** @namespace state.fields.destinataire.nom */
                    /** @namespace state.fields.destinataire.prenom */
                    /** @namespace state.fields.destinataire.adresse.code_postal */
                    /** @namespace state.fields.destinataire.adresse.pays.code */
                },
                modes: {
                    control: null,
                    fastInput: null
                },
                carrier: null,
                number: null
            },
            swal = window.swal,
            Api = window.App.Api;

        return {
            el: '#newView',
            // Called by App lifecycle
            onInit: onInit,
            onEnter: onEnter,
            onExit: onExit,
            // Local methods
            bindEvents: bindEvents,
            loadProducts: loadProducts,
            loadPointsOfSale: loadPointsOfSale,
            loadCountries: loadCountries,
            loadParcel: loadParcel,
            submitControl: submitControl,
            submitFastInput: submitFastInput,
            submit: submit,
            // Utils methods
            compile: compile,
            merge: merge
        };

        // --------------------------------------------------------------------
        // VIEW FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Binds inputs to state.fields
         */
        function onInit() {
            var view = this;

            window.App.Bind.bindFields(view.$$el, 'mode', state.modes);
            window.App.Bind.bindFields(view.$$el, 'parcel', state.fields);

            $.when(
                view.loadPointsOfSale(),
                view.loadCountries()
            )
                .done(function () {
                    setTimeout(function () {
                        view.$$loaded = true;
                        view.$$el.trigger('loaded');
                    }, 10);
                });
        }

        /**
         * Initialize the form with passed data
         * @param {*} data `{ "parcel": *, "carrier": * }`
         */
        function onEnter(data) {
            var view = this;

            state.carrier = 'object' === typeof data.carrier ? data.carrier.id : data.carrier;

            if (view.$$loaded) {
                load();
            } else {
                view.$$el.on('loaded', function () {
                    load();
                });
            }

            return void 0;

            function load() {
                view.loadParcel(data.parcel);

                // Init event
                view.bindEvents();

                setTimeout(function () {
                    state.fields.dimensions.poids_reel.focus();
                }, 250); // At least the home interval
            }
        }

        function bindEvents() {
            var view = this;

            /*
             * Submit short save + ask for redirect to complet input or new scan
             */
            state.bindings.push((function () {
                view.el('submit-control')
                    .on('click', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        view.submit('control');
                    });

                return function () {
                    view.el('submit-control').off('click');
                };
            })());

            /*
             * Submit the form with validation
             */
            state.bindings.push((function () {
                view.el('form')
                    .on('submit', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        view.submit();
                    });

                return function () {
                    view.el('form').off('submit reset');
                };
            })());

            /*
             * Go back to home
             */
            state.bindings.push((function () {
                view.el('reset').on('click', function () {
                    App.goto('home');
                });

                return function () {
                    view.el('reset').off('click');
                };
            })());

            /*
             * Handle history
             */
            if ('function' === typeof window.history.replaceState) {
                window.history.replaceState(
                    {
                        page: 'new-control',
                        number: state.number
                    }, null, view.$$el.closest('[v-page]').data('url-new').replace(/--numerock--/, state.number)
                );
            }
        }


        /**
         * Clear bindings, stop autofocus
         */
        function onExit() {
            var i;

            state.focusInterval && clearInterval(state.focusInterval);

            for (i = 0; i < state.bindings.length; ++i) {
                state.bindings[i]();
            }

            state.bindings = [];
        }

        /**
         * Get products from API v2, remove products and restore previous value
         */
        function loadProducts() {
            return Api.Colikado.loadProductOptions(state.fields.produit.id, ['dimensions'], null, state.number);
        }


        /**
         * Get points of sale from API v2, remove points of sale and restore previous value
         */
        function loadPointsOfSale() {
            return Api.Colikado.loadPointsOfSaleOptions(state.fields.vente.achat.id, {
                actif: true
            })
                .then(
                    Api.Colikado.loadPointsOfSaleOptions(state.fields.vente.depot.id, {
                        actif: true
                    })
                );
        }

        /**
         * Hardcoded in fast input mode
         */
        function loadCountries() {
            $('<option></option>')
                .attr('value', 'RE')
                .text('REUNION')
                .appendTo(state.fields.expediteur.adresse.pays.code.empty());

            $('<option></option>')
                .attr('value', 'FR')
                .text('FRANCE')
                .appendTo(state.fields.expediteur.adresse.pays.code);

            $('<option></option>')
                .attr('value', 'FR')
                .text('FRANCE')
                .appendTo(state.fields.destinataire.adresse.pays.code.empty());
        }

        /**
         * Load the parcel data into the view
         *
         * @param {*} parcel
         */
        function loadParcel(parcel) {
            var view = this;

            state.number = 'object' === typeof parcel ? parcel.numero : parcel;

            // Empty all fields
            view.merge(parcel);

            $.when(
                view.loadProducts()
            )
                .done(function () {

                    App.selectFirst(state.fields.expediteur.adresse.pays.code);
                    App.selectFirst(state.fields.destinataire.adresse.pays.code);

                    // Activate the correct mode
                    if (parcel.saisie.terminee) {
                        App.selectFirst(state.fields.vente.achat.id);
                        App.selectFirst(state.fields.vente.depot.id);

                        state.modes.control.removeClass('hidden');
                        state.modes.fastInput.addClass('hidden');

                        state.modes.$$current = state.modes.control;
                        view.el('mode-title').text('NON CONTROLE');
                    } else {
                        state.modes.fastInput.removeClass('hidden');
                        state.modes.control.addClass('hidden');

                        state.modes.$$current = state.modes.fastInput;
                        view.el('mode-title').text('SAISIE RAPIDE');
                    }

                    view.component('productInput').trigger('parcel.loaded', parcel);
                });
        }

        /**
         * Submit with the correct mode handler
         */
        function submit(mode) {
            var view = this,
                autoSwitch = state.modes.control.is(':visible')  // Auto switch if control only
            ;

            if ('undefined' === typeof mode) {
                mode = autoSwitch ? 'control' : 'fastInput';
            }

            if ('control' === mode) {
                view.submitControl(function (parcel) {
                    if (autoSwitch) {
                        App.goto('existing', {
                            parcel: parcel
                        })
                    } else {
                        App.redirectFullInput(parcel.numero);
                    }
                }, autoSwitch);
            } else {
                view.submitFastInput();
            }
        }

        /**
         * Fast input save mode
         */
        function submitFastInput() {
            var view = this,
                buttons = $('button', view.$$el);

            FormValidation.validate(view.el('form').get(0))
                .success(function (validation) {
                    var data = view.compile(),
                        failure = function (error) {
                            buttons.prop('disabled', false);

                            App.showError('Échec de l\'enregistrement', error);
                        };

                    validation.reset();
                    buttons.prop('disabled', true);

                    data.mode = 'fax';

                    Api.Colikado.validateParcel(data)
                        .done(function () {
                            Api.Colikado.saveParcelFast(data)
                                .done(function (parcel) {
                                    onSuccess(parcel);
                                })
                                .fail(failure)
                        })
                        .fail(failure);
                })
                .fail(function () {
                    App.showError({
                        title: 'Saisie incomplète ou erronée',
                        html: '<ul><li>Tous champs obligatoires doivent être renseignés.</li><li>Vérifiez le format des données saisies</li></ul>'
                    });
                });

            /*
             * ---- Functions
             */

            function onSuccess(parcel) {
                swal({
                    type: 'success',
                    title: 'Enregistrement réussi',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showCancelButton: true,
                    closeOnCancel: true,
                    cancelButtonText: 'Scanner un autre colis',
                    showConfirmButton: true,
                    closeOnConfirm: false,
                    showLoaderOnConfirm: true,
                    confirmButtonText: 'Imprimer l\'étiquette',
                    confirmButtonColor: '#0459B7'
                }, function (print) {
                    buttons.prop('disabled', false);

                    if (print) {
                        swal.disableButtons();

                        Api.Colikado.printLabel(parcel.numero)
                            .done(function () {
                                // Reload the data
                                App.getParcel(parcel.numero)
                                    .done(function (parcel) {
                                        view.loadParcel(parcel);
                                        swal.enableButtons();
                                    })
                                    .fail(function () {
                                        swal.enableButtons();
                                    });
                            })
                            .fail(function (error) {
                                swal.enableButtons();

                                App.showError({
                                    title: 'Échec de l\'impression',
                                    closeOnConfirm: false
                                }, error, function () {
                                    onSuccess(parcel);
                                });
                            });
                    } else {
                        App.goto('home');
                    }
                });
            }
        }

        /**
         * Control save mode
         *
         * @param {*} [next] callback function call for next action with parcel as param
         * @param {boolean} autoNext Automatically switch to next()
         */
        function submitControl(next, autoNext) {
            var view = this,
                wait = null,
                buttons = $('button', view.$$el);

            // Validate weight and product
            if (state.fields.produit.id.val() <= 0) {
                App.showError(
                    'Veuillez renseigner le type de produit',
                    {
                        message: 'Saisie incomplète, type de produit manquant'
                    }
                );
            } else {
                // Prevents validation of quick input
                var shouldHideFastInput = !state.modes.fastInput.hasClass('hidden');

                if (shouldHideFastInput) {
                    state.modes.fastInput.addClass('hidden');
                    shouldHideFastInput = function () {
                        state.modes.fastInput.removeClass('hidden');
                    };
                }

                FormValidation.validate(view.el('form').get(0))
                    .success(function (validation) {
                        var buttons = $('button', view.$$el),
                            data = view.compile(),
                            failure = function (error) {
                                buttons.prop('disabled', false);

                                App.showError('Échec de l\'enregistrement', error);
                            },
                            success = function (parcel) {
                                onSuccess(parcel);
                            };

                        validation.reset();
                        buttons.prop('disabled', true);

                        wait = App.showWaiting('Enregistrement en cours');

                        Api.Colikado.saveParcelControlFax(data)
                            .done(success)
                            .fail(failure);

                        shouldHideFastInput && shouldHideFastInput();
                    })
                    .fail(function () {
                        App.showError({
                            title: 'Saisie incomplète ou erronée',
                            html: '<ul><li>Tous champs obligatoires doivent être renseignés.</li><li>Vérifiez le format des données saisies</li></ul>'
                        });

                        shouldHideFastInput && shouldHideFastInput();
                    });
            }

            /*
             * ---- Functions
             */

            function onSuccess(parcel) {
                var hasNext = 'function' === typeof next;

                if (autoNext) {
                    buttons.prop('disabled', false);
                    wait.close();

                    hasNext && next(parcel);
                } else {
                    swal({
                        type: 'success',
                        title: 'Enregistrement réussi',
                        text: 'Comment souhaitez-vous poursuivre ?',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showCancelButton: true,
                        closeOnCancel: true,
                        cancelButtonText: 'Scanner un autre colis',
                        showConfirmButton: true,
                        closeOnConfirm: false,
                        showLoaderOnConfirm: true,
                        confirmButtonText: 'Saisie complète du colis',
                        confirmButtonColor: '#0459B7'
                    }, function (input) {
                        buttons.prop('disabled', false);

                        if (input) {
                            hasNext && next(parcel);
                        } else {
                            App.goto('home');
                        }
                    });
                }
            }
        }

        // --------------------------------------------------------------------
        // UTILITY FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Merge the given object into the form fields
         */
        function merge(source) {
            App.deepMerge(state.fields, source);
        }

        /**
         * Merge the given object into the form fields
         */
        function compile() {
            var data = App.deepCompile(state.fields, {});

            // Complete with fields provided by view param
            data.numero = "" + state.number;
            data.transporteur = {
                id: parseInt(state.carrier)
            };

            return data;
        }
    }
}(window, jQuery);
