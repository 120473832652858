+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.ColikadoSalesDataTable = window.App.Pages.ColikadoSalesDataTable || {};
    window.App.Pages.ColikadoSalesDataTable.views = window.App.Pages.ColikadoSalesDataTable.views || {};

    window.App.Pages.ColikadoSalesDataTable.views.home = viewFactory;

    function viewFactory(App) {
        var state = {
                bindings: [],
                data: null
            },
            Api = window.App.Api;

        return {
            el: '#homeView',
            $$loaded: false,
            // Called by App lifecycle
            onEnter: onEnter,
            onExit: onExit,
            // Internal function
            bindEvents: bindEvents,
            getDate: getDate,
            loadData: loadData,
            prepareData: prepareData,
            updateUI: updateUI,
            displayData: displayData,
            exportToCsv: exportToCsv
        };

        /**
         * Bind events
         */
        function onEnter() {
            var view = this;

            view.bindEvents();
        }

        /**
         * Binds all events
         */
        function bindEvents() {
            var view = this;

            state.bindings.push((function () {
                view.$$el.on('data.updated', function () {
                    view.updateUI();
                });

                return function () {
                    view.$$el.off('data.updated');
                };
            })());

            state.bindings.push((function () {
                view.el('submit').on('click.module', function () {
                    view.loadData();
                });

                return function () {
                    view.el('submit').off('click.module');
                };
            })());

            state.bindings.push((function () {
                view.el('download').on('click.module', function () {
                    view.exportToCsv();
                });

                return function () {
                    view.el('download').off('click.module');
                };
            })());
        }

        /**
         * Clear bindings
         */
        function onExit() {
            var i;

            for (i = 0; i < state.bindings.length; ++i) {
                state.bindings[i]();
            }

            state.bindings = [];
        }

        /**
         * Get formated name
         * @param {string} name el field name
         */
        function getDate(name) {
            var view = this;

            return moment(view.el(name).val(), 'DD/MM/YYYY').format('YYYY-MM-DD');
        }

        /**
         * Call API with dates
         */
        function loadData() {
            var view = this;

            var modal = App.showWaiting('Calcul en cours...');

            Api.Colikado.getSalesStats(view.getDate('date-debut'), view.getDate('date-fin')).then(function (data) {
                view.prepareData(data);

                view.$$el.trigger('data.updated');

                modal.close();
            }, function () {
                App.showError('Le calcul des données a échoué.');
            });
        }

        /**
         * Map fields to real labels
         */
        function prepareData(data) {
            var mapping = {
                'point_of_sale_code': 'Code PDV',
                'point_of_sale_name': 'Nom PDV',
                'product_code': 'Code Produit',
                'product_name': 'Nom Produit',
                'year': 'Année',
                'month': 'Mois'
            };

            state.data = data.map(function (record) {
                var data = {};

                for (var key in record) {
                    if (record.hasOwnProperty(key)) {
                        if (mapping.hasOwnProperty(key)) {
                            data[mapping[key]] = record[key];
                        } else {
                            data[key] = record[key];
                        }
                    }

                }

                return data;
            })
        }

        function updateUI() {
            var view = this;

            view.displayData();
        }

        /**
         * Count Data
         */
        function displayData() {
            var view = this;

            if (!state.data) {
                return;
            }

            var tpl = $.pivotUtilities.aggregatorTemplates,
                numberFormat = $.pivotUtilities.numberFormat,
                intFormat = numberFormat({
                    digitsAfterDecimal: 0,
                    thousandsSep: "\u00A0",
                    decimalSep: ","
                }),
                currencyFormat = numberFormat({
                    digitsAfterDecimal: 2,
                    thousandsSep: "\u00A0",
                    decimalSep: ",",
                    suffix: "\u00A0€"
                });

            var $panel = view.el('stats-table');

            $panel.closest('.panel').removeClass('hidden');
            $panel.pivotUI(state.data, {
                rows: ['Code PDV', 'Nom PDV'],
                cols: ['Année', 'Mois'],
                hiddenAttributes: ['year_month', 'sold_count', 'deposited_count', 'raw_revenue', 'revenue', 'commission'],
                aggregators: {
                    'Nombre de ventes': function () {
                        return tpl.sum(intFormat)(['sold_count'])
                    },
                    'Nombre de dépôts': function () {
                        return tpl.sum(intFormat)(['deposited_count'])
                    },
                    'CA Brut': function () {
                        return tpl.sum(currencyFormat)(['raw_revenue'])
                    },
                    'CA Net': function () {
                        return tpl.sum(currencyFormat)(['revenue'])
                    },
                    'Commissions': function () {
                        return tpl.sum(currencyFormat)(['commission'])
                    }
                }
            }, false, 'fr');
        }

        /**
         * Export data to CSV
         */
        function exportToCsv() {
            var view = this;

            if (!state.data) {
                return;
            }

            var headers = {
                'year_month': 'ANNEE-MOIS',
                'year': 'ANNEE',
                'month': 'MOIS',
                'zone': 'ZONE',
                'point_of_sale_code': 'CODE PDV',
                'point_of_sale_name': 'NOM PDV',
                'product_code': 'CODE PRODUIT',
                'product_name': 'NOM PRODUIT',
                'sold_count': 'NB. VENTE',
                'deposited_count': 'NB. DEPOT',
                'revenue': 'CHIFFRES D. AFFAIRES NET',
                'commission': 'COMMISSIONS',
                'raw_revenue': 'CHIFFRES D. AFFAIRES BRUT'
            };

            App.exportCSVFile(headers, state.data.map(function (data) {
                data.raw_revenue = data.raw_revenue.toString().replace(/\./, ',');
                data.revenue = data.revenue.toString().replace(/\./, ',');
                data.commission = data.commission.toString().replace(/\./, ',');
                return data;
            }), 'statistiques_colikado');
        }
    }
}(window, jQuery);