+function (window, document, $) {

    var module = {
        // Required
        transformIn: transformIn,
        transformOut: transformOut
        // Internals
    };

    window.App = window.App || {};
    window.App.Api = window.App.Api || {};
    window.App.Api.Transformers = window.App.Api.Transformers || {};
    window.App.Api.Transformers.ColipaysCreditNote = module;

    return void 0;

    // --------------------------------------------------------------------
    // REQUIRED FUNCTIONS
    // --------------------------------------------------------------------


    /**
     * Transform a COLIPAYS CUSTOMER from API to local
     * @param {*} credit source object
     * @returns {*} transformed object
     */
    function transformIn(credit) {
        var transformer = this;

        credit.created_at = credit.created_at ? moment(credit.created_at).format('DD/MM/YYYY') : null;
        credit.activated_at = credit.activated_at ? moment(credit.activated_at).format('DD/MM/YYYY') : null;
        credit.expires_at = credit.expires_at ? moment(credit.expires_at).format('DD/MM/YYYY') : null;
        credit.total_amount = parseFloat(credit.total_amount);
        credit.used_amount = parseFloat(credit.used_amount);
        credit.available_amount = parseFloat(credit.available_amount);

        return credit;
    }


    /**
     * Transform a COLIPAYS CUSTOMER from local to API
     * @param {*} credit source object
     * @returns {*} transformed object
     */
    function transformOut(credit) {
        var transformer = this;

        credit = $.extend(true, {}, credit);

        credit.created_at = credit.created_at ? moment(credit.created_at).format() : null;
        credit.activated_at = credit.activated_at ? moment(credit.activated_at).format() : null;
        credit.expires_at = credit.expires_at ? moment(credit.expires_at).format() : null;

        return credit;
    }

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

}(window, document, jQuery);