+function (window, document, $) {

    var module = {
        // Public functions,
        showError: showError,
        showWarning: showWarning,
        showWaiting: showWaiting,
        showConfirm: showConfirm
    }, swal = window.swal;

    window.App = window.App || {};
    window.App.Alert = module;

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Display a SWAL alert box
     * @param {*} title The title as string
     * @param {*} message The message as string or `{ title: string, html: string }`
     * @param {*} accepted Callback function when Oui clicked or `{ text: string, color: string, callback: fn }`
     * @param {*} rejected Callback function when Non clicked or `{ text: string, color: string, callback: fn }`
     */
    function showConfirm(title, message, accepted, rejected) {
        var defaultYes = {
                callback: accepted,
                text: 'Oui',
                color: '#0459B7'
            },
            defaultNo = {
                callback: rejected,
                text: 'Non',
                color: '#7489A0'
            },
            yes = {}, no = {};

        if ('function' !== typeof accepted) {
            yes.callback = accepted.callback || null;
            yes.text = accepted.text || defaultYes.text;
            yes.color = accepted.color || defaultYes.color;
        } else {
            yes = defaultYes;
        }

        if ('function' !== typeof rejected) {
            no.callback = rejected.callback || null;
            no.text = rejected.text || defaultNo.text;
            no.color = rejected.color || defaultNo.color;
        } else {
            no = defaultNo;
        }

        if (typeof message === 'object') {
            if (message.html) {
                message.text = message.html;
            }
        } else {
            message.text = message;
        }

        var options = $.extend({
            type: 'info',
            title: title,
            text: message,
            allowEscapeKey: false,
            showCancelButton: true,
            closeOnCancel: true,
            cancelButtonText: no.text,
            cancelButtonColor: no.color,
            showConfirmButton: true,
            closeOnConfirm: false,
            showLoaderOnConfirm: false,
            confirmButtonText: yes.text,
            confirmButtonColor: yes.color
        }, message);

        if ('object' === typeof message) {
            options = $.extend(true, options, message);
        }

        setTimeout(function () {
            swal(options, function (confirmed) {
                if (confirmed && typeof yes.callback === 'function') {
                    yes.callback(swal);
                } else if (typeof no.callback === 'function') {
                    no.callback(swal);
                }
            });
        }, 1);
    }

    /**
     * Display a SWAL alert box
     * @param {*} message The title as string or `{ title: string, html: string }`
     * @param {*} error Optional error data
     * @param {*} cb callback function on close
     */
    function showError(message, error, cb) {
        if ('function' === typeof error) {
            cb = error;
            error = null;
        }

        if ('undefined' !== typeof error && error) {
            error = '<code>' + JSON.stringify(error) + '</code>';
        } else {
            error = '';
        }

        if (typeof message === 'object') {
            if (message.html) {
                message.text = message.html;
            }
        } else {
            message.text = message;
        }

        var options = $.extend({
            type: 'error',
            title: message,
            text: '<div style="max-height: 400px; overflow-y: scroll;"><p>Si l\'erreur persiste, contactez l\'administrateur.</p>' + error + '</div>',
            html: true,
            closeOnConfirm: true,
            showLoaderOnConfirm: false
        }, message);

        if ('object' === typeof message) {
            options = $.extend(true, options, message);
        }

        setTimeout(function () {
            swal(options, cb);
        }, 1);
    }

    /**
     * Display a SWAL warning box
     * @param {*} message The title as string or `{ title: string, html: string }`
     * @param {*} cb callback function on close
     */
    function showWarning(message, cb) {
        if (typeof message === 'object') {
            if (message.html) {
                message.text = message.html;
            }
        } else {
            message.text = message;
        }

        var options = $.extend({
            type: 'warning',
            title: 'Attention',
            text: message,
            html: true,
            closeOnConfirm: true,
            showLoaderOnConfirm: false
        }, message);

        if ('object' === typeof message) {
            options = $.extend(true, options, message);
        }

        setTimeout(function () {
            swal(options, cb);
        }, 1);
    }

    /**
     * Display a waiting SWAL alert box
     *
     * @returns {{close: Function}} Call close to close the waiting bo
     */
    function showWaiting(title) {
        swal({
            imageUrl: '/assets/images/wait.png',
            title: title || 'Chargement',
            text: 'Veuillez patientez...',
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false
        });

        return {
            close: function () {
                swal.close();
            }
        }
    }

}(window, document, jQuery);