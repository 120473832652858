+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.PlannerLists = window.App.Pages.PlannerLists || {};
    window.App.Pages.PlannerLists.views = window.App.Pages.PlannerLists.views || {};

    window.App.Pages.PlannerLists.views.home = viewFactory;

    function viewFactory(App) {
        var state = {
                bindings: [],
                template: null,
                emptyTemplate: null
            },
            Api = window.App.Api;

        return {
            el: '#homeView',
            // Called by App lifecycle
            onEnter: onEnter,
            onExit: onExit,
            // Local methods
            bindEvents: bindEvents,
            loadLists: loadLists
        };

        // --------------------------------------------------------------------
        // VIEW FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Initialize fields states, start autofocus on number
         */
        function onEnter() {
            var view = this;

            state.template = view.el('row-template').html();
            state.emptyTemplate = view.el('row-empty-template').html();

            view.loadLists().then(function () {
                view.bindEvents();
            });
        }

        /**
         * Binds all events
         */
        function bindEvents() {
            var view = this;

            /*
            * Submit the form with validation
            */
            state.bindings.push((function () {
                view.el('create')
                    .on('click', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        App.goto('edit');
                    });

                return function () {
                    view.el('create').off('click');
                };
            })());
        }

        /**
         * Clear bindings, stop autofocus
         */
        function onExit() {
            var i;

            for (i = 0; i < state.bindings.length; ++i) {
                state.bindings[i]();
            }

            state.bindings = [];
        }

        function loadLists() {
            var view = this,
                addListItem = function (item) {
                    var template = $(state.template);

                    template.find('.__Name').text(item.name);

                    template.find('[data-action="view-components"]').on('click', function () {
                        App.goto('edit', item);
                    });

                    template.find('[data-action="delete-list"]').on('click', function () {
                        var $btn = $(this).prop('disabled', true);

                        Api.Colipays.Planner.removeComponentsList(item.id).then(function () {
                            template.remove();
                        }, function (error) {
                            App.showError('Impossible de supprimer la liste', error && error.errors ? [error.message, error.errors] : error);

                            $btn.prop('disabled', false)
                        });
                    });

                    view.el('items').append(template);
                },
                wait = App.showWaiting();

            return Api.Colipays.Planner.getComponentsLists().then(function (items) {
                view.el('items').empty();

                if (!items || !items.data || !items.data.length) {
                    view.el('items').append($(state.emptyTemplate));
                } else {
                    items.data.forEach(addListItem);
                }

                wait.close();
            });
        }
    }
}(window, jQuery);