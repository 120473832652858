+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.ColikadoSalesDataTable = window.App.Pages.ColikadoSalesDataTable || {};

    var module = window.App.Pages.ColikadoSalesDataTable;

    module.views = module.views || {};
    module.name = 'ColikadoSalesDataTable';

    $(document).ready(function () {
        window.App.OnePage.load(module.name, initPage);
    });

    return void 0;

    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------
    // ---------- App Definition
    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------

    function initPage(pageEl) {
        var App = {
            views: {
                'home': module.views.home
            },
            init: init,
            goto: goto,
            showError: showError,
            showWaiting: showWaiting,
            // Internals,
            convertToCSV: convertToCSV,
            exportCSVFile: exportCSVFile
        };

        App.init();

        // ------------------------------------------------------------------------
        // ------------------------------------------------------------------------
        // ---------- App functions
        // ------------------------------------------------------------------------
        // ------------------------------------------------------------------------

        /**
         * Bootstrap the page views
         */
        function init() {
            window.App.OnePage.initViews(App, pageEl);

            App.goto('home');
        }

        /**
         * Change the view
         * @param {string} view The view key
         * @param {*} [data] Optional data to pass to the view onEnter event
         */
        function goto(view, data) {
            return window.App.OnePage.gotoView(this, view, data);
        }

        // --------------------------------------------------------------------
        // INTERNAL FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Display a SWAL alert box
         * @param {*} message The title as string or `{ title: string, text: string }`
         * @param {*} error Optional error data
         * @param {*} cb callback function on close
         */
        function showError(message, error, cb) {
            return window.App.Alert.showError(message, error, cb);
        }

        /**
         * Display a waiting SWAL alert box
         *
         * @returns {{close: Function}} Call close to close the waiting bo
         */
        function showWaiting(title) {
            return window.App.Alert.showWaiting(title);
        }

        // --------------------------------------------------------------------
        // UTILITY FUNCTIONS
        // --------------------------------------------------------------------

        function convertToCSV(objArray) {
            var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
            var str = '';

            for (var i = 0; i < array.length; i++) {
                var line = '';
                for (var index in array[i]) {
                    if (line != '') line += ';'

                    line += '"' + array[i][index] + '"';
                }

                str += line + '\r\n';
            }

            return str;
        }

        function exportCSVFile(headers, items, fileTitle) {
            if (headers) {
                items.unshift(headers);
            }

            // Convert Object to JSON
            var jsonObject = JSON.stringify(items);

            var csv = this.convertToCSV(jsonObject);

            var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

            var blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", exportedFilenmae);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }
    }
}(window, jQuery);