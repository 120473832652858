+function (window, $) {
    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.SaisieArrivee = window.App.Pages.SaisieArrivee || {};
    window.App.Pages.SaisieArrivee.views = window.App.Pages.SaisieArrivee.views || {};

    window.App.Pages.SaisieArrivee.views.existing = viewFactory;

    function viewFactory(App) {
        var state = {
                fields: {},
                bindings: [],
                parcel: null
            },
            Api = window.App.Api;

        return {
            el: '#existingView',
            onInit: onInit,
            onEnter: onEnter,
            onExit: onExit,
            bindEvents: bindEvents,
            loadParcel: loadParcel,
            // Utilities
            merge: merge
        };


        // --------------------------------------------------------------------
        // VIEW FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Binds inputs to state.fields
         */
        function onInit() {
            var view = this;

            window.App.Bind.bindFields(view.$$el, 'parcel', state.fields);
        }

        /**
         * Load parcel data and initialize state
         *
         * @param data
         */
        function onEnter(data) {
            var view = this;

            view.loadParcel(data.parcel);
            view.bindEvents();
        }

        /**
         * Bind all UI events
         */
        function bindEvents() {
            var view = this;

            /*
             * Actions callbacks
             */
            state.bindings.push((function () {
                view.component('actions')
                    .on('label.printed', function () {
                        // Reload the data
                        App.getParcel(state.parcel.numero)
                            .done(function (parcel) {
                                view.loadParcel(parcel);
                            });
                    })
                    .on('edit.full', function (event, parcel) {
                        App.redirectFullInput(parcel.numero);
                    })
                    .on('edit.fast', function (event, parcel) {
                        App.goto('new', {
                            parcel: parcel,
                            carrier: parcel.transporteur
                        });
                    });

                return function () {
                    view.component('actions').off('label.printed edit.full edit.fast');
                };
            })());

            /*
             * Go back to home
             */
            state.bindings.push((function () {
                view.el('reset')
                    .on('click', function () {
                        App.goto('home');
                    });

                return function () {
                    view.el('reset').off('click');
                };
            })());

            /*
             * Handle history
             */
            if ('function' === typeof window.history.replaceState) {
                window.history.replaceState(
                    {
                        page: 'existing-control',
                        number: state.parcel.numero
                    }, null, view.$$el.closest('[v-page]').data('url-existing').replace(/--numerock--/, state.parcel.numero)
                );
            }
        }

        /**
         * Unbind listeners
         */
        function onExit() {
            var i;

            state.focusInterval && clearInterval(state.focusInterval);

            for (i = 0; i < state.bindings.length; ++i) {
                state.bindings[i]();
            }

            state.bindings = [];
        }

        /**
         * Update UI with parcel data
         * @param parcel
         */
        function loadParcel(parcel) {
            var view = this;

            state.parcel = parcel;

            view.merge(parcel);

            view.component('freePackage').trigger('parcel.loaded', parcel);
            view.component('productView').trigger('parcel.loaded', parcel);
            view.component('actions').trigger('parcel.loaded', parcel);
        }

        // --------------------------------------------------------------------
        // UTILITY FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Merge the given object into the form fields
         */
        function merge(source) {
            App.deepMerge(state.fields, source);
        }
    }
}(window, jQuery);
