+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.RetraitPalette = window.App.Pages.RetraitPalette || {};
    window.App.Pages.RetraitPalette.views = window.App.Pages.RetraitPalette.views || {};

    window.App.Pages.RetraitPalette.views.home = viewFactory;

    function viewFactory(App) {
        var state = {
                bindings: [],
                focusInterval: null,
                canFocus: true
            },
            Mustache = window.Mustache,
            Api = window.App.Api,
            FormValidation = window.FormValidation;

        return {
            el: '#homeView',
            // Called by App lifecycle
            onEnter: onEnter,
            onExit: onExit,
            // Local methods
            bindEvents: bindEvents,
            reset: reset,
            validateNumber: validateNumber,
            onPaletUnloaded: onPaletUnloaded
        };

        // --------------------------------------------------------------------
        // VIEW FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Initialize fields states, start autofocus on number
         */
        function onEnter() {
            var view = this,
                form = view.el('form'),
                number = view.el('numerock'),
                alert = view.el('alert');

            alert.addClass('hidden');

            view.bindEvents();

            // ***

            state.focusInterval = setInterval(function () {
                if (state.canFocus) {
                    number.prop('disabled', false);
                    number.focus();
                } else {
                    number.prop('disabled', true);
                }
            }, 250);
        }

        /**
         * Binds all events
         */
        function bindEvents() {
            var view = this,
                form = view.el('form'),
                number = view.el('numerock');

            /*
             * Validate the number when requested
             */
            state.bindings.push((function () {
                form
                    .on('submit', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        view.validateNumber();
                    })
                    .on('reset', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        view.reset();
                    });

                return function () {
                    form.off('submit reset');
                };
            })());

            /*
             * Reset the form
             */
            state.bindings.push((function () {
                var reset = view.el('reset')
                    .on('click', function () {
                        view.reset();
                    });

                return function () {
                    reset.off('click');
                };
            })());

            /*
             * Autofocus on change + Session save when palet is selected
             */
            state.bindings.push((function () {
                var palet = view.el('palette')
                    .on('change', function () {
                        var self = $(this);

                        self.blur();

                        if (window.sessionStorage) {
                            window.sessionStorage.setItem('Page.RetraitPalette', self.val());
                        }
                    });

                return function () {
                    palet.off('change');
                };
            })());

            /*
             * Cancel/Force focus on number field when others field gain/lost focus
             */
            state.bindings.push((function () {
                var palet = view.el('palette'),
                    fields = form.find('input,select,textarea').not(number)
                        .on('focus', function () {
                            state.canFocus = false;
                        })
                        .on('blur', function () {
                            state.canFocus = canFocus();
                        }),
                    canFocus = function () {
                        return palet.val() > 0;
                    };

                return function () {
                    fields.off('focus blur');
                };
            })());

            /*
             * Handle history
             */
            if ('function' === typeof window.history.replaceState) {
                window.history.replaceState(
                    {
                        page: 'home-control'
                    }, null, view.$$el.closest('[v-page]').data('url-home')
                );
            }
        }

        /**
         * Clear bindings, stop autofocus
         */
        function onExit() {
            var i;

            state.focusInterval && clearInterval(state.focusInterval);

            for (i = 0; i < state.bindings.length; ++i) {
                state.bindings[i]();
            }

            state.bindings = [];
        }

        /**
         * Clear the number field, keep the palet
         */
        function reset() {
            var view = this,
                form = view.el('form'),
                number = view.el('numerock'),
                alert = view.el('alert'),
                buttons = form.find('input,select,button,a');

            buttons.prop('disabled', false);
            number.val('');
            alert.text('').addClass('hidden');
        }

        /**
         * Call API to check the number if it matches the provided pattern
         */
        function validateNumber() {
            var view = this,
                defer = $.Deferred(),
                form = view.el('form'),
                number = view.el('numerock'),
                fields = form.find('input,select,button,a'),
                value = number.val(),
                failureAlert = function (errors) {
                    var alert = view.el('alert')
                        .html('!!! Numéro [<strong>' + value + '</strong>] invalide !!!')
                        .removeClass('hidden');

                    if ('string' === typeof errors) {
                        errors = {
                            erreur: errors
                        };
                    }

                    if ('object' === typeof errors && null !== errors) {
                        var ul = $('<ul></ul>').appendTo(alert);
                        for (var error in errors) {
                            if (errors.hasOwnProperty(error)) {
                                $('<li></li>')
                                    .text(errors[error])
                                    .appendTo(ul);
                            }
                        }
                    }

                };

            FormValidation.validate(view.el('form').get(0))
                .success(function (validation) {
                    validation.reset();
                    view.reset();
                    fields.prop('disabled', true);

                    var wait = App.showWaiting('Mise à jour des données');

                    Api.Colikado.unloadParcelFromPalet(value)
                        .done(function (data) {
                            fields.prop('disabled', false);
                            wait.close();
                            view.onPaletUnloaded(data.parcel, data.palet);
                            defer.resolve();
                        })
                        .fail(function (error) {
                            fields.prop('disabled', false);
                            wait.close();
                            failureAlert(error.errors || error.message);
                            defer.reject();
                        });
                })
                .fail(function () {
                    view.reset();
                    failureAlert();
                    defer.reject();
                });

            return defer;
        }

        /**
         * Handle UI refresh when parcel is loaded on palet
         *
         * @param {*} parcel
         * @param {*} palet
         */
        function onPaletUnloaded(parcel, palet) {
            var view = this,
                container = view.el('listing'),
                count = view.el('listing-count'),
                template = view.el('listing-item-template').html(),
                data = {
                    type: parcel._type == 'ColikadoParcel' ? 'COLIKADO' : 'COLIPAYS',
                    numero: parcel.numero,
                    bordereau: parcel.fret.bordereau,
                    produit: parcel.produit.nom,
                    destination: parcel.destinataire.adresse.code_postal
                    + ' ' + parcel.destinataire.adresse.ville
                    + ' (' + parcel.destinataire.adresse.pays.code + ')',
                    palette: palet.numero
                };

            $(Mustache.render(template, data)).prependTo(container.empty());

            count.text($('.RetraitPalette__Listing_Row', container).length);
        }
    }
}(window, jQuery);