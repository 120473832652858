+function (window, document, $) {
    "use strict";

    var template = "<div class=\"row\">\n    <div class=\"col-sm-12 AppForm__FreePackage_Mode\">\n        <div class=\"form-group\">\n            <label class=\"control-label\">Mode de facturation</label>\n            <div class=\"as-input form-control-static\">\n                <span v-el=\"{{modelPrefix}}mode\"></span>\n            </div>\n        </div>\n    </div>\n</div>",
        module = {
            directive: 'freepackage-view',
            compile: compile,
            defaults: {
                modelPrefix: 'parcel.prix'
            }
        };

    window.App = window.App || {};
    window.App.Components = window.App.Components || {};
    window.App.Components.FreePackageView = module;

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    function compile(el) {
        var module = this,
            state = {
                last_mode_invoicable: true,
                // Builder
                prepare: prepare,
                bind: bind,
                // Events
                loadParcel: loadParcel
                // Actions
            };

        return window.App.Components.initComponent(module, el, state);

        /*
         * --------- FUNCTIONS ---------
         */

        function prepare(view) {
            return template;
        }

        function bind(component, view) {
            var builder = this;

            component.on('parcel.loaded', function (event, parcel) {
                builder.loadParcel(parcel);
            });
        }

        function loadParcel(parcel) {
            var builder = this,
                formatted = null;

            switch (parcel.prix.mode) {
                case 'cadeau':
                    formatted = 'Bordereau non facturé : CADEAU';
                    break;
                case 'reexpedition':
                    formatted = 'Bordereau non facturé : REEXPEDITION';
                    break;
            }

            if (formatted) {
                builder.el('mode').text(formatted);
            } else {
                builder.el('mode').closest('.row').remove();
            }
        }
    }
}(window, document, jQuery);