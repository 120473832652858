+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.ManifesteSurete = window.App.Pages.ManifesteSurete || {};
    window.App.Pages.ManifesteSurete.views = window.App.Pages.ManifesteSurete.views || {};

    window.App.Pages.ManifesteSurete.views.edit = viewFactory;

    function viewFactory(App) {
        var state = {
                bindings: [],
                focusInterval: null,
                canFocus: true,
                rowTemplate: null,
                safetyCompany: null,
                fields: {
                    add: {
                        numero: null,
                    },
                    close: {
                        controlled_at: null,
                        time_hour: null,
                        time_minute: null,
                        safety_company: null,
                    },
                    print: {
                        safety_company: null,
                    },
                },
                parcel: null
            },
            Api = window.App.Api,
            FormValidation = window.FormValidation;

        return {
            el: '#editView',
            $$loaded: false,
            $$intialized: false,
            // Called by App lifecycle
            onInit: onInit,
            onEnter: onEnter,
            onExit: onExit,
            // Local methods
            bindEvents: bindEvents,
            bindRow: bindRow,
            selectSafetyCompany: selectSafetyCompany,
            add: add,
            reset: reset,
            close: close,
            print: print,
            // Utils methods
            compile: compile,
            merge: merge
        };

        // --------------------------------------------------------------------
        // VIEW FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Initialize lists from API and load bindings
         */
        function onInit() {
            var view = this;

            window.App.Bind.bindFields(view.$$el, 'add', state.fields.add);
            window.App.Bind.bindFields(view.$$el, 'print', state.fields.print);
            window.App.Bind.bindFields(view.$$el, 'close', state.fields.close);

            state.safety = view.$$el.data('safety');
            state.rowTemplate = view.el('rowTemplate').html();

            view.$$loaded = true;
            view.$$el.trigger('loaded');
        }

        /**
         * Initialize fields states, start autofocus on number
         */
        function onEnter(data) {
            var view = this;

            view.$$initialized = false;

            state.carrier = 'object' === typeof data.carrier ? data.carrier.id : data.carrier;

            if (view.$$loaded) {
                load();
            } else {
                view.$$el.on('loaded', function () {
                    load();
                });
            }

            return void 0;

            /*
             * Internal functions
             */

            function load() {
                // Init event
                view.bindEvents();
            }
        }

        /**
         * Binds all events
         */
        function bindEvents() {
            var view = this;

            /*
            * Autofocus
            */
            state.focusInterval = setInterval(function () {
                var field = state.fields.add.numero;

                if (state.canFocus) {
                    if (!field.val()) {
                        field.select();
                    } else {
                        field.focus();
                    }
                }
            }, 250);

            /*
             * Submit the add form with validation
             */
            state.bindings.push((function () {
                view.el('formAdd')
                    .on('submit', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        view.add();
                    });

                return function () {
                    view.el('formAdd').off('submit reset');
                };
            })());

            /*
             * Submit the print form with validation
             */
            state.bindings.push((function () {
                view.el('formPrint')
                    .on('submit', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        view.print();
                    });

                return function () {
                    view.el('formPrint').off('submit reset');
                };
            })());

            /*
             * Submit the close form with validation
             */
            state.bindings.push((function () {
                view.el('formClose')
                    .on('submit', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        view.close();
                    });

                return function () {
                    view.el('formClose').off('submit reset');
                };
            })());

            /*
             * Activate focus
             */
            state.bindings.push((function () {
                state.fields.add.numero
                    .on('focus', function (e) {
                        state.canFocus = true;
                    });

                return function () {
                    state.fields.add.numero.off('focus');
                };
            })());

            /*
             * Disable focus
             */
            state.bindings.push((function () {
                view.el('formPrint')
                    .on('click', function (e) {
                        state.canFocus = false;
                    });
                view.el('formClose')
                    .on('click', function (e) {
                        state.canFocus = false;
                    });

                return function () {
                    view.el('formPrint').off('click');
                    view.el('formClose').off('click');
                };
            })());

            /*
             * Bind Safety Companies
             */
            state.bindings.push((function () {
                state.fields.close.safety_company.on('change', function () {
                    view.selectSafetyCompany($(this).val());
                });
                state.fields.print.safety_company.on('change', function () {
                    view.selectSafetyCompany($(this).val());
                });

                return function () {
                    state.fields.close.safety_company.off('change');
                    state.fields.print.safety_company.off('change');
                };
            })());

            view.el('parcels').find('tr').each(function () {
                view.bindRow($(this));
            })
        }

        /**
         * Clear bindings, stop autofocus
         */
        function onExit() {
            var view = this,
                i;

            view.component('customerPickup').trigger('reset.customer');

            state.focusInterval && clearInterval(state.focusInterval);

            for (i = 0; i < state.bindings.length; ++i) {
                state.bindings[i]();
            }

            state.bindings = [];
        }

        /**
         * Listen row event
         */
        function bindRow($row) {
            var view = this;

            $row.find('button').on('click', function () {
                var $row = $(this).closest('tr');

                $row.find('button').css('visibility', 'hidden');

                Api.Colikado.removeParcelFromSafety(state.safety, $row.data('id'))
                    .done(function () {
                        var $count = view.el('count');

                        $row.remove();

                        $count.text(parseInt($count.text()) - 1);
                    })
                    .fail(function (error) {
                        window.App.Alert.showError('Erreur interne,', error);

                        $row.find('button').css('visibility', 'visible');
                    });
            });
        }

        /**
         * Copy safety company
         */
        function selectSafetyCompany(value) {
            state.fields.close.safety_company.val(value);
            state.fields.print.safety_company.val(value);
        }

        /**
         * Add the parcel
         */
        function add() {
            var view = this,
                value = state.fields.add.numero.val();

            view.reset();

            Api.Colikado.addParcelToSafety(state.safety, value)
                .done(function (parcel) {
                    var $row = $(state.rowTemplate);
                    var $count = view.el('count');

                    $row.data('id', parcel.parcel_id);
                    $row.find('.date').text(moment(parcel.created_at).format('DD/MM/YYYY à HH[h]mm') || '');
                    $row.find('.internal_number').text(parcel.internal_number || '');
                    $row.find('.tracking_number').text(parcel.tracking_number || '');
                    $row.find('.product_code').text(parcel.product_code || '');
                    $row.find('.product_name').text(parcel.product_name || '');
                    $row.find('.weight').text(parcel.weight || '');

                    view.el('parcels').prepend($row);

                    $count.text(parseInt($count.text()) + 1);

                    view.bindRow($row);
                })
                .fail(function (error) {
                    if (error.status_code === 422) {
                        window.App.Alert.showError(error.message);
                    } else if (error.status_code === 404) {
                        window.App.Alert.showError('Numéro inconnu.');
                    } else {
                        window.App.Alert.showError('Erreur interne,', error);
                    }
                });
        }

        /**
         * Reset Add Form the parcel
         */
        function reset() {
            var view = this;

            state.fields.add.numero.val('');
        }

        /**
         * Close the document
         */
        function close() {
            var view = this;

            FormValidation.validate(view.el('formClose').get(0))
                .success(function (validation) {
                    validation.reset();

                    window.App.Alert.showConfirm(
                        'Confirmation',
                        'Veuillez confirmer que le contrôle a bien été fait le ' + state.fields.close.controlled_at.val() + ' à ' + state.fields.close.time_hour.val() + 'h' + state.fields.close.time_minute.val() + ' par ' + state.fields.close.safety_company.val(),
                        function () {
                            Api.Colikado.closeSafetyManifest(state.safety, state.fields.close.safety_company.val(),
                                moment(state.fields.close.controlled_at.val() + ' ' + state.fields.close.time_hour.val() + ':' + state.fields.close.time_minute.val(), 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'))
                                .done(function () {
                                    window.location.reload();
                                })
                                .fail(function () {
                                    // NTD
                                })
                        },
                        function () {
                            //NTD
                        }
                    )
                })
                .fail(function () {
                    App.showError('Saisie incomplète ou erronée', '<ul><li>Tous champs obligatoires doivent être renseignés.</li><li>Vérifiez le format des données saisies.</li></ul>');
                });
        }

        /**
         * Print the document
         */
        function print() {
            var view = this;

            Api.Colikado.printSafetyManifest(state.safety, state.fields.print.safety_company.val())
                .done(function () {
                    // NTD
                })
                .fail(function () {
                    // NTD
                })
        }

        // --------------------------------------------------------------------
        // UTILITY FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Merge the given object into the form fields
         */
        function merge(source) {
            return App.deepMerge(state.fields, source);
        }

        /**
         * Merge the given object into the form fields
         */
        function compile() {
            var data = App.deepCompile(state.fields, {});

            data.numero = state.parcel.numero;

            return data;
        }

    }
}(window, jQuery);
