+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.PlannerLists = window.App.Pages.PlannerLists || {};
    window.App.Pages.PlannerLists.views = window.App.Pages.PlannerLists.views || {};

    window.App.Pages.PlannerLists.views.edit = viewFactory;

    function viewFactory(App) {
        var state = {
                bindings: [],
                fields: {},
                template: null,
                emptyTemplate: null,
                list: null,
                isCreation: false,
                components: null,
                componentsIndex: null
            },
            Api = window.App.Api,
            elasticlunr = window.elasticlunr,
            FormValidation = window.FormValidation;

        return {
            el: '#editView',
            $$ready: false,
            // Called by App lifecycle
            onInit: onInit,
            onEnter: onEnter,
            onExit: onExit,
            // Local methods
            bindEvents: bindEvents,
            submit: submit,
            onSave: onSave,
            loadList: loadList,
            addComponentToList: addComponentToList,
            autocomplete: autocomplete,
            // Utils methods
            compile: compile,
            merge: merge
        };

        // --------------------------------------------------------------------
        // VIEW FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Initialize lists from API and load bindings
         */
        function onInit() {
            var view = this,
                done = function () {
                    view.$$ready = true;

                    view.autocomplete(view.el('component-search'), function (component) {
                        view.el('component').val(component ? JSON.stringify(component) : null);
                    });

                    view.$$el.trigger('ready');
                };

            elasticlunr.clearStopWords();

            state.template = view.el('row-template').html();
            state.emptyTemplate = view.el('row-empty-template').html();
            state.componentsIndex = elasticlunr(function () {
                this.addField('code');
                this.addField('name');
                this.setRef('id');
                this.saveDocument(false);
            });

            window.App.Bind.bindFields(view.$$el, 'list', state.fields);

            Api.Colipays.getComponents().then(function (components) {
                if (components.data && components.data.length) {
                    state.components = {};

                    components.data.forEach(function (component) {
                        state.components[component.id] = component;

                        state.componentsIndex.addDoc(component)
                    });
                }

                done();
            }, done)
        }

        /**
         * Initialize fields states
         */
        function onEnter(data) {
            var view = this,
                wait = null,
                load = function () {
                    if (wait) {
                        wait.close();
                    }

                    view.loadList(data);
                    view.bindEvents();
                };

            state.isCreation = !data.id;

            view.el('items').empty();

            if (state.isCreation) {
                view.el('title').text('Créer une liste de composants');
                view.el('items-panel').hide();
            } else {
                view.el('title').text('Modifier une liste de composants');
                view.el('items-panel').show();
            }

            if (view.$$ready) {
                load();
            } else {
                wait = App.showWaiting();

                view.$$el.on('ready', load);
            }
        }

        /**
         * Binds all events
         */
        function bindEvents() {
            var view = this,
                form = view.el('form');

            /*
             * Submit the form with validation
             */
            state.bindings.push((function () {
                view.el('form')
                    .on('submit', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        view.submit();
                    });

                return function () {
                    view.el('form').off('submit reset');
                };
            })());

            /*
             * Go back to home
             */
            state.bindings.push((function () {
                view.el('reset').on('click', function () {
                    App.goto('home');
                });

                return function () {
                    view.el('reset').off('click');
                };
            })());

            /*
             * Go back to home
             */
            state.bindings.push((function () {
                view.el('component-add').on('click', function () {
                    var $component = view.el('component'),
                        data = $component.val();

                    if (data) {
                        view.addComponentToList(JSON.parse(data), true);

                        $component.val(null);
                    }

                    view.el('component-search').val(null);
                });

                return function () {
                    view.el('component-add').off('click');
                };
            })());
        }

        /**
         * Clear bindings, stop autofocus
         */
        function onExit() {
            var view = this,
                i;

            for (i = 0; i < state.bindings.length; ++i) {
                state.bindings[i]();
            }

            state.bindings = [];
        }

        /**
         * Save
         */
        function submit() {
            var view = this,
                buttons = $('button', view.$$el);

            FormValidation.validate(view.el('form').get(0))
                .success(function (validation) {
                    var data = view.compile(),
                        failure = function (error) {
                            buttons.prop('disabled', false);

                            if ((error.code || error.status_code) === 422) {
                                App.showError('Les données saisies ne sont pas valides', error && error.errors ? [error.message, error.errors] : error)
                            } else {
                                App.showError('Échec de l\'enregistrement', error && error.errors ? [error.message, error.errors] : error)
                            }
                        },
                        success = function (list) {
                            buttons.prop('disabled', false);

                            onSuccess(list);
                        };

                    validation.reset();
                    buttons.prop('disabled', true);

                    App.showWaiting();

                    if (state.isCreation) {
                        Api.Colipays.Planner.createComponentsList(data).then(success, failure);
                    } else {
                        Api.Colipays.Planner.updateComponentsList(data).then(success, failure);
                    }

                })
                .fail(function () {
                    App.showError('Saisie incomplète ou erronée', '<ul><li>Tous champs obligatoires doivent être renseignés.</li><li>Vérifiez le format des données saisies.</li></ul>');
                });

            /*
             * ---- Functions
             */

            function onSuccess(list) {
                view.onSave(list);
            }
        }

        /**
         * Handle list saving
         *
         * @param list
         */
        function onSave(list) {
            var view = this,
                buttons = $('button', view.$$el);

            buttons.prop('disabled', false);

            if (state.isCreation) {
                App.goto('edit', list);
            }

            swal({
                type: 'success',
                title: 'Enregistrement réussi',
                allowOutsideClick: true,
                allowEscapeKey: true,
                showCancelButton: false,
                showConfirmButton: true,
                closeOnConfirm: true,
                showLoaderOnConfirm: false,
                confirmButtonText: 'Ok',
                confirmButtonColor: '#0459B7'
            });
        }

        function loadList(data) {
            var view = this;

            if (data && data.id) {
                var wait = App.showWaiting();

                Api.Colipays.Planner.getComponentsList(data.id).then(function (list) {
                    state.list = list;

                    view.merge(list);

                    wait.close();
                }, function () {
                    wait.close();
                });
            } else {
                view.merge({
                    name: '',
                    components: {
                        data: []
                    }
                });
            }
        }

        function addComponentToList(component, remote) {
            var view = this,
                item = $(state.template),
                appendToTable = function (component) {
                    item.find('.__Name').text(component.name);
                    item.find('.__Code').text(component.code);
                    item.find('[data-action="remove"]').on('click', function () {
                        var $btn = $(this).prop('disabled', true);

                        Api.Colipays.Planner.removeComponentFromList(component.id).then(function () {
                            item.remove();
                        }, function (error) {
                            App.showError('Impossible de supprimer le composant de la liste', error && error.errors ? [error.message, error.errors] : error);

                            $btn.prop('disabled', false);
                        });
                    });

                    view.el('items').prepend(item);

                    state.list.components.data.push(component);
                };

            if (remote) {
                var $btn = view.el('component-add').prop('disabled', true);

                Api.Colipays.Planner.addComponentToList(state.list.id, component).then(function (component) {
                    appendToTable(component);

                    $btn.prop('disabled', false);
                }, function (error) {
                    App.showError('Impossible d’ajouter le composant à la liste', error && error.errors ? [error.message, error.errors] : error);

                    $btn.prop('disabled', false);
                });
            } else {
                appendToTable(component);
            }
        }

        function autocomplete($field, cb) {
            var view = this;

            $field.suggest({
                accent: false,
                minLength: 3,
                dropdownWidth: 'auto',
                appendMethod: 'replace',
                limit: 50,
                visibleLimit: 50,
                valid: function () {
                    return true;
                },
                source: [
                    function (q, add) {
                        cb(null);

                        if (q) {

                            add(state.componentsIndex.search(q, {expand: true})
                                .map(function (component) {
                                    return state.components[component.ref];
                                })
                                .filter(function (component) {
                                    return state.list.components.data.filter(function (item) {
                                        return item.code === component.code;
                                    }).length === 0;
                                }));
                        }
                    }
                ],
                getTitle: function (item) {
                    return item.code + ' - ' + item.name;
                },
                getValue: function (item) {
                    return item.code + ' - ' + item.name
                }
            }).on('selected.suggest', function (e, item) {
                if (item) {
                    cb(item);
                }
            });
            ;
        }

        // --------------------------------------------------------------------
        // UTILITY FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Merge the given object into the form fields
         */
        function merge(source) {
            var view = this;

            App.deepMerge(state.fields, source);

            if (source.components.data.length) {
                source.components.data.forEach(function (component) {
                    view.addComponentToList(component);
                })
            }
        }

        /**
         * Merge the given object into the form fields
         */
        function compile() {
            return App.deepCompile(state.fields, {id: state.list ? state.list.id : null});
        }

    }
}(window, jQuery);