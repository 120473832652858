+function (window, document, $) {
    "use strict";

    var module = {
        silentPrint: true, // window.navigator && window.navigator.userAgent && window.navigator.userAgent.toLowerCase().match(/firefox/),
        // Public functions,
        $$api: base('$$api'),
        $$blankPdf: $('body').data('autoprint-blank-pdf') || '/pdf/blank.pdf',
        // Data functions
        me: me,
        getParcel: getParcel,
        saveParcelComplete: saveParcelComplete,
        saveParcelFast: saveParcelFast,
        saveParcelControl: saveParcelControl,
        saveParcelControlOnline: saveParcelControlOnline,
        saveParcelControlFax: saveParcelControlFax,
        validateParcel: validateParcel,
        getSkybill: getSkybill,
        getLabel: getLabel,
        getProducts: getProducts,
        getCarriers: getCarriers,
        getCountries: getCountries,
        getPointsOfSale: getPointsOfSale,
        getPalet: getPalet,
        getPalets: getPalets,
        getPaletLimits: getPaletLimits,
        getSalesStats: getSalesStats,
        addParcelToSafety: addParcelToSafety,
        removeParcelFromSafety: removeParcelFromSafety,
        printSafetyManifest: printSafetyManifest,
        closeSafetyManifest: closeSafetyManifest,
        // Transformers
        transformIn: base('transformIn'),
        transformOut: base('transformOut'),
        // Action helpers
        printLabel: printLabel,
        printSkybill: printSkybill,
        loadParcelOnPalet: loadParcelOnPalet,
        unloadParcelFromPalet: unloadParcelFromPalet,
        // HTML helpers
        loadProductOptions: loadProductOptions,
        loadCarriersOptions: loadCarriersOptions,
        loadCountriesOptions: loadCountriesOptions,
        loadPointsOfSaleOptions: loadPointsOfSaleOptions,
        loadPaletsOptions: loadPaletsOptions,
        // Utility functions
        initAutoPrint: initAutoPrint,
        autoPrint: autoPrint,
        _buildParams: base('_buildParams')
    };

    window.App = window.App || {};
    window.App.Api = window.App.Api || {};
    window.App.Api.Colikado = module;

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Get the api instance
     *
     * @private
     * @returns {*|{}} Colipays V2 API gateway
     */
    function $$api() {
        return window.Colipays.Api;
    }

    // --------------------------------------------------------------------
    // DATA LOAD FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Current user profile
     *
     * @returns {*} a promise that is resolve in `done()` with user profile data or rejected `fail()` with error
     */
    function me() {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/me')
            .done(function (me) {
                defer.resolve(api.transformIn(me));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Load a parcel data
     *
     * @param {string} number NumeroCK
     * @param {[]|string} include relations to include
     *
     * @returns {*} a promise that is resolve in `done()` with parcel data or rejected `fail()` with error
     */
    function getParcel(number, include) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/shipping/parcels/' + number.toUpperCase(), api._buildParams(null, include))
            .done(function (parcel) {
                defer.resolve(api.transformIn(parcel));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Save a parcel data in full input mode
     *
     * @param {*} data parcel data to persist
     *
     * @returns {*} a promise that is resolve in `done()` with parcel data or rejected `fail()` with error
     */
    function saveParcelComplete(data) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$post('/shipping/parcels/colikado', api._buildParams(api.transformOut(data, 'ColikadoParcel')))
            .done(function (parcel) {
                defer.resolve(api.transformIn(parcel));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Save a parcel data in fast input mode
     *
     * @param {*} data parcel data to persist
     *
     * @returns {*} a promise that is resolve in `done()` with parcel data or rejected `fail()` with error
     */
    function saveParcelFast(data) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$post('/shipping/parcels/colikado/fast', api._buildParams(api.transformOut(data, 'ColikadoParcel')))
            .done(function (parcel) {
                defer.resolve(api.transformIn(parcel));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Save a parcel data in control only mode
     *
     * @param {*} data parcel data to persist
     *
     * @returns {*} a promise that is resolve in `done()` with parcel data or rejected `fail()` with error
     */
    function saveParcelControl(data) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$post('/shipping/parcels/colikado/control', api._buildParams(api.transformOut(data, 'ColikadoParcel')))
            .done(function (parcel) {
                defer.resolve(api.transformIn(parcel));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Save a parcel data in control only mode for parcel entered online (website)
     *
     * @param {*} data parcel data to persist
     *
     * @returns {*} a promise that is resolve in `done()` with parcel data or rejected `fail()` with error
     */
    function saveParcelControlOnline(data) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$post('/shipping/parcels/colikado/control/online', api._buildParams(api.transformOut(data, 'ColikadoParcel')))
            .done(function (parcel) {
                defer.resolve(api.transformIn(parcel));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Save a parcel data in control only mode for parcel entered online (website)
     *
     * @param {*} data parcel data to persist
     *
     * @returns {*} a promise that is resolve in `done()` with parcel data or rejected `fail()` with error
     */
    function saveParcelControlFax(data) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$post('/shipping/parcels/colikado/fax', api._buildParams(api.transformOut(data, 'ColikadoParcel')))
            .done(function (parcel) {
                defer.resolve(api.transformIn(parcel));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Checks that the parcel data can be saved :
     * - check the receiver postcode / country
     *
     * @param {*} data parcel data to persist
     *
     * @returns {*} a promise that is resolve in `done()` with parcel data or rejected `fail()` with error
     */
    function validateParcel(data) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$post('/shipping/parcels/colikado/validate', api._buildParams(api.transformOut(data, 'ColikadoParcel')))
            .done(function (result) {
                if (result && result.valid) {
                    defer.resolve();
                } else {
                    defer.reject(result && result.error || 'Erreur inconnue');
                }
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Load a parcel skybill data link
     *
     * @param {string} number NumeroCK
     * @param {boolean} [print] print flag to get a print job
     *
     * @returns {*} a promise that is resolve in `done()` with label data or rejected `fail()` with error
     */
    function getSkybill(number, print) {
        var api = this,
            defer = $.Deferred(),
            data = {};

        if (print) {
            data.print = 1;
        }

        api.$$api().$get('/supply/colikado/' + number.toUpperCase() + '/skybill', data)
            .done(function (label) {
                defer.resolve(api.transformIn(label));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Load a parcel label data link
     * @param {string} number NumeroCK
     * @param {boolean} [print] print flag to get a print job
     *
     * @returns {*} a promise that is resolve in `done()` with label data or rejected `fail()` with error
     */
    function getLabel(number, print) {
        var api = this,
            defer = $.Deferred(),
            data = {};

        if (print) {
            data.print = 1;
        }

        api.$$api().$get('/shipping/parcels/' + number.toUpperCase() + '/label', data)
            .done(function (label) {
                defer.resolve(api.transformIn(label));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Load all products data
     *
     * @param {{}} [options] URL parameters
     * @param {[]} [includes] relations to include
     *
     * @returns {*} a promise that is resolve in `done()` with products list or rejected `fail()` with error
     */
    function getProducts(options, includes) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/products/colikado', this._buildParams(options, includes))
            .done(function (result) {
                result.data = result.data.map(function (item) {
                    return api.transformIn(item);
                });

                defer.resolve(result);
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }


    /**
     * Load all carriers data
     *
     * @param {{}} [options] URL parameters
     * @param {[]} [includes] relations to include
     *
     * @returns {*} a promise that is resolve in `done()` with carriers list or rejected `fail()` with error
     */
    function getCarriers(options, includes) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/supply/colikado/carriers', this._buildParams(options, includes))
            .done(function (result) {
                result.data = result.data.map(function (item) {
                    return api.transformIn(item);
                });

                defer.resolve(result);
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }


    /**
     * Load all countries data
     *
     * @param {{}} [options] URL parameters
     * @param {[]} [includes] relations to include
     *
     * @returns {*} a promise that is resolve in `done()` with countries list or rejected `fail()` with error
     */
    function getCountries(options, includes) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/geo/countries', this._buildParams(options, includes))
            .done(function (result) {
                result.data = result.data.map(function (item) {
                    return api.transformIn(item);
                });

                defer.resolve(result);
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }


    /**
     * Load all points of sales data
     *
     * @param {{}} [options] URL parameters
     * @param {[]} [includes] relations to include
     *
     * @returns {*} a promise that is resolve in `done()` with points of sale list or rejected `fail()` with error
     */
    function getPointsOfSale(options, includes) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/supply/colikado/points-of-sale', this._buildParams(options, includes))
            .done(function (result) {
                result.data = result.data.map(function (item) {
                    return api.transformIn(item);
                });

                defer.resolve(result);
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Load a single palet data
     *
     * @param {{}} id palet id
     * @param {[]} [includes] relations to include
     *
     * @returns {*} a promise that is resolve in `done()` with a palet or rejected `fail()` with error
     */
    function getPalet(id, includes) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/shipping/palets/' + id, this._buildParams(null, includes))
            .done(function (palet) {
                defer.resolve(api.transformIn(palet));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Load all palet data with filter option
     *
     * @param {{}} [options] URL parameters
     * @param {[]} [includes] relations to include
     *
     * @returns {*} a promise that is resolve in `done()` with palets list or rejected `fail()` with error
     */
    function getPalets(options, includes) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/shipping/palets', this._buildParams(options, includes))
            .done(function (result) {
                result.data = result.data.map(function (item) {
                    return api.transformIn(item);
                });

                defer.resolve(result);
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Compute limits statistics for a palet
     *
     * @param {{}} id palet id
     *
     * @returns {*} a promise that is resolve in `done()` with the load statistics (total, colikado, colipays) or rejected `fail()` with error
     */
    function getPaletLimits(id) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/shipping/palets/' + id + '/limits')
            .done(function (statistics) {
                defer.resolve(api.transformIn(statistics));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Compute sales statistics for a period
     *
     * @param {string} from date in Y-m-d
     * @param {string} until date in Y-m-d
     *
     * @returns {*} a promise that is resolve in `done()` with the monthly sales statistics or rejected `fail()` with error
     */
    function getSalesStats(from, until) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$get('/colikado/resellers/invoices/stats/monthly', {
            from: from,
            until: until
        })
            .done(function (statistics) {
                defer.resolve(api.transformIn(statistics));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Add Parcel To a Safety Manifest
     *
     * @param {int} safety safety ID
     * @param {string} number parcel number or Tracking number
     *
     * @returns {*} a promise that is resolve in `done()` with the parcel data or rejected `fail()` with error
     */
    function addParcelToSafety(safety, number) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$put('/colikado/safeties/' + safety + '/add', {
            number: number,
        })
            .done(function (parcel) {
                defer.resolve(api.transformIn(parcel));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }

    /**
     * Remove A Parcel From a Safety Manifest
     *
     * @param {int} safety safety ID
     * @param {int} parcel parcel ID
     *
     * @returns {*} a promise that is resolve in `done()` with the parcel data or rejected `fail()` with error
     */
    function removeParcelFromSafety(safety, parcel) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$delete('/colikado/safeties/' + safety + '/remove/' + parcel)
            .done(function (parcel) {
                defer.resolve(api.transformIn(parcel));
            })
            .fail(function (error) {
                defer.reject(error);
            });

        return defer;
    }


    /**
     * AutoPrint a Safety Manifest
     *
     * @param {int} id the safety id
     * @param {string} safety_company the safety company name
     *
     * @returns {*} promised that resolves when the skybill has been loaded
     */
    function printSafetyManifest(id, safety_company) {
        var api = this,
            defer = $.Deferred(),
            done = function (data) {
                window.open(data.url + '?ts=' + new Date().getTime(), '_blank');
            },
            error = function (error) {
                App.Alert.showError('Échec de l\'impression du bordereau [E10]', error, function () {
                    defer.reject();
                });
            };

        if (safety_company) {
            api.$$api().$post('/colikado/safeties/' + id + '/print', {
                safety_company: safety_company
            })
                .done(done)
                .fail(error);
        } else {
            api.$$api().$get('/colikado/safeties/' + id + '/print')
                .done(done)
                .fail(error);
        }

        return defer;
    }

    /**
     * Close a Safety Manifest
     *
     * @param {int} id the safety id
     * @param {string} safety_company the safety company name
     * @param {string} controlled_at the control date with time "Y-m-d H:i"
     *
     * @returns {*} promised that resolves when the skybill has been loaded
     */
    function closeSafetyManifest(id, safety_company, controlled_at) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$post('/colikado/safeties/' + id + '/close', {
            safety_company: safety_company,
            controlled_at: controlled_at
        })
            .done(defer.resolve)
            .fail(defer.reject);

        return defer;
    }

    // --------------------------------------------------------------------
    // DATA MANIPULATION FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Transform an API structure from an API representation to a local representation based on `_type` property
     * @param {*} object source object
     * @returns {*} transformed object
     */
    function transformIn(object) {
        var api = this,
            transformers = window.App.Api.Transformers;

        if (null === object || 'object' !== typeof object || !object.hasOwnProperty('_type') || 'undefined' === typeof transformers[object._type]) {
            return object;
        }

        // Deep transformIn recursively
        for (var property in object) {
            if (object.hasOwnProperty(property)) {
                object[property] = api.transformIn(object[property]);
            }
        }

        return transformers[object._type].transformIn(object);
    }

    /**
     * Transform an API structure from local representation to an API representation based on `_type` property
     *
     * @param {*} object source object
     * @param {string} type Object type (transformer binding)
     *
     * @returns {*} transformed object
     */
    function transformOut(object, type) {
        var transformers = window.App.Api.Transformers;

        if ('object' !== typeof object || 'string' !== typeof type || 'undefined' === typeof transformers[type]) {
            return object;
        }

        return transformers[type].transformOut(object);
    }

    // --------------------------------------------------------------------
    // ACTION HELPERS FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * AutoPrint a parcel label
     *
     * @param {string} number the parcel number
     *
     * @returns {*} promised that resolves when the label has been loaded
     */
    function printLabel(number) {
        var api = this,
            defer = $.Deferred();

        api.getLabel(number, true)
            .done(function (job) {
                App.Print.print(job).then(
                    function () {
                        defer.resolve();
                    },
                    function (error) {
                        App.Alert.showError('Échec de l\'impression de l\'étiquette [E20]', error, function () {
                            defer.reject();
                        });
                    }
                );
            })
            .fail(defer.reject);

        return defer;
    }

    /**
     * AutoPrint a parcel skybill
     *
     * @param {string} number the parcel number
     *
     * @returns {*} promised that resolves when the skybill has been loaded
     */
    function printSkybill(number) {
        var api = this,
            defer = $.Deferred();

        api.getSkybill(number, true)
            .done(function (job) {
                App.Print.print(job).then(
                    function () {
                        defer.resolve();
                    },
                    function (error) {
                        App.Alert.showError('Échec de l\'impression de l\'étiquette [E20]', error, function () {
                            defer.reject();
                        });
                    }
                );
            })
            .fail(function (error) {
                App.Alert.showError('Échec de l\'impression du bordereau [E10]', error, function () {
                    defer.reject();
                });
            });

        return defer;
    }

    /**
     * Load a parcel on a palet
     *
     * @param {string} number the parcel number
     * @param {string} palet the palet id
     *
     * @returns {*} promised that resolves when the parcel has been loaded
     */
    function loadParcelOnPalet(number, palet) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$post('/shipping/parcels/' + number.toUpperCase() + '/load/' + palet)
            .done(function (parcel) {
                defer.resolve(parcel);
            })
            .fail(defer.reject);

        return defer;
    }

    /**
     * Unload a parcel from the palet it is loaded onto
     *
     * @param {string} number the parcel number
     *
     * @returns {*} promised that resolves when the parcel has been unloaded with the parcel and palet data
     */
    function unloadParcelFromPalet(number, silent) {
        var api = this,
            defer = $.Deferred();

        api.$$api().$post('/shipping/parcels/' + ('' + number).toUpperCase() + '/unload', {
            silent: !!silent ? true : null
        })
            .done(function (data) {
                defer.resolve({
                    parcel: api.transformIn(data.parcel),
                    palet: api.transformIn(data.palet)
                });
            })
            .fail(defer.reject);

        return defer;
    }

    // --------------------------------------------------------------------
    // HTML HELPERS FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Load COLIKADO products into a select HTML element
     *
     * @param {*} select The select DOM element
     * @param {{}} [options] URL parameters
     * @param {[]} [includes] relations to include
     * @param {*} numerock
     *
     * @returns {number}
     */
    function loadProductOptions(select, options, includes, numerock) {
        var api = this,
            defer = $.Deferred();

        api.getProducts(options, includes)
            .done(function (products) {
                var i = 0,
                    el = select,
                    lastValue = el.val(),
                    firstOption = el.find('option').first(),
                    appendProduct = function (i) {
                        $('<option></option>')
                            .attr('value', products[i].id)
                            .text(products[i].nom + ' (' + products[i].code + ')')
                            .appendTo(el);
                    };

                el
                    .find('option')
                    .remove()
                    .end()
                    .append(firstOption);

                // DataArray API no pagination
                products = products.data;

                for (; i < products.length; ++i) {
                    if (!numerock) {
                        appendProduct(i);
                    } else if (window.App.Utils.parcelNumberMatch(numerock, products[i].code)) {
                        appendProduct(i);
                    }
                }

                el
                    .val(lastValue)
                    .data('products', products);

                // Defer for UI repaint
                setTimeout(function () {
                    defer.resolve(products);
                }, 250);
            })
            .fail(function (error) {
                window.App.Alert.showError('Les produits COLIKADO ne sont pas disponibles', error);

                defer.reject(error);
            });

        return defer;
    }

    /**
     * Load COLIKADO carriers into a select HTML element
     *
     * @param {*} select The select DOM element
     * @param {{}} [options] URL parameters
     * @param {[]} [includes] relations to include
     *
     * @returns {number}
     */
    function loadCarriersOptions(select, options, includes) {
        var api = this,
            defer = $.Deferred();

        api.getCarriers(options, includes)
            .done(function (carriers) {
                var i = 0,
                    el = select,
                    lastValue = el.val();

                // DataArray API no pagination
                carriers = carriers.data;

                for (; i < carriers.length; ++i) {
                    $('<option></option>')
                        .attr('value', carriers[i].id)
                        .text(carriers[i].nom)
                        .data('carrier', carriers[i])
                        .appendTo(el);
                }

                el
                    .val(lastValue)
                    .data('carriers', carriers);

                // Defer for UI repaint
                setTimeout(function () {
                    defer.resolve(carriers);
                }, 250);
            })
            .fail(function (error) {
                window.App.Alert.showError('Les transporteurs COLIKADO ne sont pas disponibles', error);

                defer.reject(error);
            });

        return defer;
    }

    /**
     * Load COLIKADO countries into a select HTML element
     *
     * @param {*} select The select DOM element
     * @param {{}} [options] URL parameters
     * @param {[]} [includes] relations to include
     *
     * @returns {number}
     */
    function loadCountriesOptions(select, options, includes) {
        var api = this,
            defer = $.Deferred();

        api.getCountries(options, includes)
            .done(function (countries) {
                // DataArray API no pagination
                countries = countries.data;

                select.each(function (index, el) {
                    var i = 0,
                        $el = $(el),
                        lastValue = $el.val();

                    for (; i < countries.length; ++i) {
                        $('<option></option>')
                            .attr('value', countries[i].code)
                            .text(countries[i].nom)
                            .data('country', countries[i])
                            .appendTo($el);
                    }

                    $el
                        .val(lastValue)
                        .data('countries', countries);
                });

                // Defer for UI repaint
                setTimeout(function () {
                    defer.resolve(countries);
                }, 250);
            })
            .fail(function (error) {
                window.App.Alert.showError('Les pays COLIKADO ne sont pas disponibles', error);

                defer.reject(error);
            });

        return defer;
    }

    /**
     * Load COLIKADO pointsOfSale into a select HTML element
     *
     * @param {*} select The select DOM element
     * @param {{}} [options] URL parameters
     * @param {[]} [includes] relations to include
     *
     * @returns {number}
     */
    function loadPointsOfSaleOptions(select, options, includes) {
        var api = this,
            defer = $.Deferred();

        api.getPointsOfSale(options, includes)
            .done(function (pointsOfSale) {
                var i = 0,
                    el = select,
                    lastValue = el.val();

                // DataArray API no pagination
                pointsOfSale = pointsOfSale.data;

                for (; i < pointsOfSale.length; ++i) {
                    $('<option></option>')
                        .attr('value', pointsOfSale[i].id)
                        .text(pointsOfSale[i].code + ' ' + pointsOfSale[i].nom)
                        .data('pointOfSale', pointsOfSale[i])
                        .appendTo(el);
                }

                el
                    .val(lastValue)
                    .data('pointsOfSale', pointsOfSale);

                // Defer for UI repaint
                setTimeout(function () {
                    defer.resolve(pointsOfSale);
                }, 250);
            })
            .fail(function (error) {
                window.App.Alert.showError('Les transporteurs COLIKADO ne sont pas disponibles', error);

                defer.reject(error);
            });

        return defer;
    }

    /**
     * Load COLIKADO palets into a select HTML element
     *
     * @param {*} select The select DOM element
     * @param {{}} [options] URL parameters
     * @param {[]} [includes] relations to include
     *
     * @returns {number}
     */
    function loadPaletsOptions(select, options, includes) {
        var api = this,
            defer = $.Deferred();

        api.getPalets(options, includes)
            .done(function (palets) {
                var i = 0,
                    text = null,
                    el = select,
                    lastValue = el.val();

                // DataArray API no pagination
                palets = palets.data;

                for (; i < palets.length; ++i) {
                    text = palets[i].numero;

                    if ('object' === typeof palets[i].vol) {
                        text += ' (' + palets[i].vol.lta + ' ' + palets[i].vol.numero + ')';
                    }

                    $('<option></option>')
                        .attr('value', palets[i].id)
                        .text(text)
                        .data('palet', palets[i])
                        .appendTo(el);
                }

                el
                    .val(lastValue)
                    .data('palets', palets);

                // Defer for UI repaint
                setTimeout(function () {
                    defer.resolve(palets);
                }, 250);
            })
            .fail(function (error) {
                window.App.Alert.showError('Les palettes ne sont pas disponibles', error);

                defer.reject(error);
            });

        return defer;
    }


    // --------------------------------------------------------------------
    // UTILITY FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Proxy an API Base function
     *
     * @param member
     * @returns {*}
     */
    function base(member) {
        return window.App.Api.Base[member];
    }

    /**
     * Preloads a blank pdf in an iframe to preload the PDF plugin (faster print first time)
     */
    function initAutoPrint() {
        var api = this;

        if (0 === $('.blank-label-printer').length) {
            $('<iframe></iframe>')
                .addClass('label-printer blank-label-printer')
                .css('width', 1 + 'px')
                .css('height', 1 + 'px')
                .css('bottom', 0)
                .css('right', 0)
                .css('position', 'absolute')
                .attr('src', api.$$blankPdf)
                .appendTo($('body'));
        }
    }

    /**
     *
     * @param {string} link URL to print
     * @private
     */
    function autoPrint(link) {
        var api = this,
            autoDisposeDelay = 30000,
            defer = $.Deferred();

        if (!api.silentPrint) {
            var frame = window.open(link, '_blank');

            // Auto dispose in 1 min
            setTimeout(function () {
                try {
                    frame.close();
                } catch (e) {
                    // Ignore
                }
            }, autoDisposeDelay);
        } else {
            var printer = $('.blank-label-printer')
                .removeClass('blank-label-printer')
                .attr('src', link)
                .appendTo($('body'));

            // Auto dispose in 1 min
            setTimeout(function () {
                printer.remove();
            }, autoDisposeDelay);

            // Preload another iframe
            api.initAutoPrint();
        }

        setTimeout(function () {
            defer.resolve();
        }, 3000);

        return defer;
    }
}(window, document, jQuery);
