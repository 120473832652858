+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.EtatSaisieRapide = window.App.Pages.EtatSaisieRapide || {};
    window.App.Pages.EtatSaisieRapide.views = window.App.Pages.EtatSaisieRapide.views || {};

    window.App.Pages.EtatSaisieRapide.views.home = viewFactory;

    function viewFactory(App) {
        var state = {
            bindings: []
        }, Utils = window.App.Utils;

        return {
            el: '#homeView',
            // Called by App lifecycle
            onInit: onInit,
            onEnter: onEnter,
            onExit: onExit,
            bindEvents: bindEvents
        };

        // --------------------------------------------------------------------
        // VIEW FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Prepare fields
         */
        function onInit() {
            var view = this,
                dateDebut = view.el('date-debut'),
                dateFin = view.el('date-fin');

            Utils.datepicker(dateDebut, dateFin);
        }

        /**
         * Initialize fields states, start autofocus on number
         */
        function onEnter() {
            var view = this;

            view.bindEvents();
        }

        /**
         * Binds all events
         */
        function bindEvents() {
            var view = this,
                form = view.el('form');

            /*
             * Handle history
             */
            if (window.history.replaceState) {
                var dateDebut = view.el('date-debut').val(),
                    dateFin = view.el('date-debut').val();

                window.history.replaceState(
                    {
                        dateDebut: moment(dateDebut, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                        dateFin: moment(dateFin, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    },
                    'État de saisies du ' + dateDebut + 'au ' + dateFin,
                    view.$$el.closest('[v-page]').data('url-home')
                );

                state.bindings.push((function () {
                    var listen = function (event) {
                        var dateDebut,
                            dateFin;

                        if (event.state) {
                            dateDebut = moment(event.state.dateDebut, 'YYYY-MM-DD').format('DD/MM/YYYY');
                            dateFin = moment(event.state.dateFin, 'YYYY-MM-DD').format('DD/MM/YYYY');
                        } else {
                            dateDebut = moment().format('DD/MM/YYYY');
                            dateFin = moment().format('DD/MM/YYYY');
                        }

                        view.el('date-debut').val(dateDebut);
                        view.el('date-fin').val(dateFin);
                        // view.el('submit').click();
                    };

                    window.addEventListener('popstate', listen);

                    return function () {
                        window.removeEventListener('popstate', listen);
                    };
                })());
            }
        }

        /**
         * Clear bindings, stop autofocus
         */
        function onExit() {
            var i;

            for (i = 0; i < state.bindings.length; ++i) {
                state.bindings[i]();
            }

            state.bindings = [];
        }
    }
}(window, jQuery);