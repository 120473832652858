+function (window, document, $) {

    var module = {
        // Required
        transformIn: transformIn,
        transformOut: transformOut,
        // Internals
        inCivility: inCivility,
        outCivility: outCivility
    };

    window.App = window.App || {};
    window.App.Api = window.App.Api || {};
    window.App.Api.Transformers = window.App.Api.Transformers || {};
    window.App.Api.Transformers.ColipaysCustomer = module;

    return void 0;

    // --------------------------------------------------------------------
    // REQUIRED FUNCTIONS
    // --------------------------------------------------------------------


    /**
     * Transform a COLIPAYS CUSTOMER from API to local
     * @param {*} customer source object
     * @returns {*} transformed object
     */
    function transformIn(customer) {
        var transformer = this;

        customer.created_at = customer.created_at ? moment(customer.created_at).format('DD/MM/YYYY') : null;
        customer.civility = transformer.inCivility(customer.civility);

        return customer;
    }


    /**
     * Transform a COLIPAYS CUSTOMER from local to API
     * @param {*} customer source object
     * @returns {*} transformed object
     */
    function transformOut(customer) {
        var transformer = this;

        customer = $.extend(true, {}, customer);

        customer.created_at = customer.created_at ? moment(customer.created_at).format() : null;
        customer.civility = transformer.outCivility(customer.civility);

        return customer;
    }

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    function inCivility(civility) {
        switch (civility) {
            case 'MME':
                return 'Mme';
            case 'MLLE':
                return 'Mlle';
            case 'M & MME':
                return 'M & Mme';
            case 'ME':
                return 'Maitre';
            case 'FAMILLE':
                return 'Famille';
            default:
                return 'M';
        }
    }

    function outCivility(civility) {
        switch (civility) {
            case 'Mme':
                return 'MME';
            case 'Mlle':
                return 'MLLE';
            case 'M & Mme':
                return 'M & MME';
            case 'Maitre':
            case 'Maître':
                return 'ME';
            case 'Famille':
                return 'FAMILLE';
            default:
                return 'M';
        }
    }

}(window, document, jQuery);