+function (window, document, $) {

    var module = {
        // Public functions,
        discover: discover
    };

    window.Colipays = window.Colipays || {};
    window.Colipays.Api = window.Colipays.Api || {};
    window.Colipays.Api.LocalApiDiscovery = module;

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------


    /**
     * Locate any local server if one is provided in current /me
     *
     * @returns {promise}
     */
    function discover() {
        var defer = $.Deferred(),
            App = window.App,
            Colikado = window.App.Api.Colikado;

        var lastAttempt = parseInt(window.localStorage.getItem('local-api-discovery') || 0);

        if (!lastAttempt || lastAttempt < (new Date().getTime() - 300000)) {
            var wait = App.Alert.showWaiting('Localisation du serveur');

            defer.always(function () {
                wait.close();
            });

            defer.done(function () {
                window.localStorage.setItem('local-api-discovery', new Date().getTime());
            });

            Colikado.me()
                .done(function (data) {
                    if (data && data.endpoints && data.endpoints.colikado && data.endpoints.colikado.length) {
                        var isLocalSsl = window.location.href.split(':')[0] === 'https',
                            isRemoteSsl;

                        data.endpoints.colikado.forEach(function (endpoint) {
                            if (endpoint.match(/\/$/)) {
                                endpoint = endpoint.substring(0, endpoint.length - 1);
                            }

                            isRemoteSsl = endpoint.split(':')[0] === 'https';

                            if ((isLocalSsl && !isRemoteSsl) || (!isLocalSsl && isRemoteSsl)) {
                                defer.resolve();
                                window.location.href = endpoint;
                            } else {
                                $.ajax({
                                    url: endpoint + '/version',
                                    timeout: 10000,
                                    dataType: 'json'
                                }).done(function (data) {
                                    if (data && data.cluster_id >= 0) {
                                        window.location.href = endpoint;
                                    } else {
                                        defer.resolve();
                                    }
                                }).fail(function () {
                                    defer.resolve();
                                })
                            }
                        });
                    } else {
                        defer.resolve();
                    }
                })
                .fail(function (data) {
                    if (!data || data.code != 503) {
                        defer.resolve();
                    } else {
                        wait.close();
                    }
                });
        } else {
            defer.resolve();
        }

        return defer;
    }

}(window, document, jQuery);