+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.EtatSaisieRapide = window.App.Pages.EtatSaisieRapide || {};

    var module = window.App.Pages.EtatSaisieRapide;

    module.views = module.views || {};
    module.name = 'EtatSaisieRapide';

    $(function () {
        var page = $('[v-page=' + module.name + ']');

        if (page.length) {
            initPage(page);
        }
    });

    return void 0;

    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------
    // ---------- App Definition
    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------

    function initPage(pageEl) {
        var App = {
            views: {
                'home': module.views.home
            },
            init: init,
            goto: goto
        };

        App.init();

        // ------------------------------------------------------------------------
        // ------------------------------------------------------------------------
        // ---------- App functions
        // ------------------------------------------------------------------------
        // ------------------------------------------------------------------------

        /**
         * Bootstrap the page views
         */
        function init() {
            window.App.OnePage.initViews(App, pageEl);

            App.goto('home');
        }

        /**
         * Change the view
         * @param {string} view The view key
         * @param {*} [data] Optional data to pass to the view onEnter event
         */
        function goto(view, data) {
            return window.App.OnePage.gotoView(this, view, data);
        }

    }
}(window, jQuery);