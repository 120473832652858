+function (window, document, $) {

    var module = {
        // Public functions,
        deepMerge: deepMerge,
        deepCompile: deepCompile,
        bindFields: bindFields
    };

    window.App = window.App || {};
    window.App.Bind = module;

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    /**
     *
     * @param {*} element DOM container
     * @param {string} prefix v-el prefix string
     * @param {*} target target object to bind to
     */
    function bindFields(element, prefix, target) {
        $('[v-el^="' + prefix + '."]', element)
            .not('a, button, input[type=submit], input[type=reset], input[type=button]')
            .each(function (idx, item) {
                var $item = $(item),
                    path = $.camelCase($item.attr('v-el').replace(prefix + '.', ''));

                if ($item.attr('type') === 'radio') {
                    var $items = window.dotProp.get(target, path) || $([]);

                    $items.push($item.get(0));

                    window.dotProp.set(target, path, $items);
                } else {
                    window.dotProp.set(target, path, $item);
                }
            });
    }

    /**
     * Deep merge an object into form fields through `field.val()` function
     *
     * @internal not exposed
     */
    function deepMerge(target, source) {
        var app = this;

        for (var name in target) {
            if (!target.hasOwnProperty(name)) continue;

            var targetItem = target[name];

            if ('function' !== typeof targetItem && 'undefined' !== typeof targetItem) {
                var sourceItem = source[name];

                if ('object' === typeof sourceItem && null !== sourceItem) {
                    app.deepMerge(targetItem, sourceItem);
                } else if ('undefined' !== typeof sourceItem) {
                    if (targetItem.length) {
                        if ((targetItem.is('input') || targetItem.is('textarea') || targetItem.is('select'))) {
                            if (targetItem.attr('type') === 'radio') {
                                targetItem.map(function (index, item) {
                                    var $item = $(item);

                                    if ($item.val() == sourceItem) {
                                        $item.prop('checked', true);
                                    } else {
                                        $item.prop('checked', false);
                                    }
                                })
                            } else {
                                targetItem.val(sourceItem);
                            }
                        } else {
                            if (!$.trim(sourceItem).length) {
                                targetItem.html('&nbsp;'); // Use a non-breaking space to have a DOM child
                                targetItem.addClass('__Empty');
                            } else {
                                targetItem.text(sourceItem);
                            }
                        }
                    }
                } else if (targetItem.length) {
                    if (targetItem.is('input') || targetItem.is('textarea') || targetItem.is('select')) {
                        targetItem.val(null);
                    }

                    if (targetItem.is('select')) {
                        $('option:first', targetItem).prop('selected', true);
                    }
                } else {
                    app.deepMerge(targetItem, {});
                }
            }
        }
    }


    /**
     * Deep merge an object into form fields through `field.val()` function
     *
     * @internal not exposed
     */
    function deepCompile(source, target, prefix) {
        var app = this;

        if (prefix) {
            target = target[prefix] = {};
        }

        for (var name in source) {
            if (source.hasOwnProperty(name) &&
                typeof source[name] !== 'function' && typeof source[name] !== 'undefined') {
                if (source[name].length) {
                    if (source[name].attr('type') === 'number') {
                        target[name] = window.App.Math.round(1.000 * source[name].val(), 3);
                    } else if (source[name].attr('type') === 'radio') {
                        source[name].map(function (index, item) {
                            var $item = $(item);

                            if ($item.is(':checked')) {
                                target[name] = $item.val();
                            }
                        })
                    } else {
                        target[name] = source[name].val();
                    }
                } else {
                    target[name] = app.deepCompile(source[name], {}, name);
                }
            }
        }

        return target;
    }

}(window, document, jQuery);