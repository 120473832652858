+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.AttributionPalette = window.App.Pages.AttributionPalette || {};
    window.App.Pages.AttributionPalette.views = window.App.Pages.AttributionPalette.views || {};

    window.App.Pages.AttributionPalette.views.home = viewFactory;

    function viewFactory(App) {
        var state = {
                bindings: [],
                focusInterval: null,
                canFocus: false,
                palet: {
                    id: null
                },
                limits: {},
                sounds: {
                    ok: null,
                    ko: null,
                    warn: null
                }
            },
            Api = window.App.Api,
            Utils = window.App.Utils,
            FormValidation = window.FormValidation,
            moment = window.moment;

        return {
            el: '#homeView',
            $$loaded: false,
            // Called by App lifecycle
            onInit: onInit,
            onEnter: onEnter,
            onExit: onExit,
            // Local methods
            bindEvents: bindEvents,
            reset: reset,
            validateNumber: validateNumber,
            addProcessing: addProcessing,
            clearProcessing: clearProcessing,
            onPaletLoaded: onPaletLoaded,
            onPaletChanged: onPaletChanged,
            onPaletUnloaded: onPaletUnloaded,
            watchPaletLoadContent: watchPaletLoadContent,
            updatePaletLoadContent: updatePaletLoadContent,
            watchPaletLimits: watchPaletLimits,
            updatePaletLimits: updatePaletLimits
        };

        // --------------------------------------------------------------------
        // VIEW FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Load the palets from the API
         */
        function onInit() {
            var view = this,
                $view = view.el(),
                palet = view.el('palette');

            state.sounds.ok = $($view.data('sound-ok'), $view);
            state.sounds.ko = $($view.data('sound-ko'), $view);
            state.sounds.warn = $($view.data('sound-warn'), $view);

            Api.Colikado.loadPaletsOptions(palet, {
                chargee_apres: moment().format('YYYY-MM-DD'),
                chargement: true
            }, ['vol'])
                .done(function (palets) {
                    if (!palets.length) {
                        App.showError({
                            title: 'Aucun palette n\'est configurée pour le chargement',
                            html: 'Contactez le responsable logistique pour configurer les palettes.'
                        });
                    } else {
                        view.$$loaded = true;
                        view.$$el.trigger('loaded');
                    }
                })
                .fail(function (error) {
                    App.showError('Impossible de charger la liste des palettes', error);
                })
        }

        /**
         * Initialize fields states, start autofocus on number
         */
        function onEnter() {
            var view = this,
                form = view.el('form'),
                alert = view.el('alert'),
                palet = view.el('palette');

            form.find('input,select,button,a').prop('disabled', true);

            if (view.$$loaded) {
                load();
            } else {
                view.$$el.on('loaded', function () {
                    load();
                });
            }

            return void 0;

            /*
             * Internal functions
             */

            function load() {
                form.find('input,select,button,a').prop('disabled', false);
                alert.addClass('hidden');

                // ***

                if (window.sessionStorage) {
                    var val = window.sessionStorage.getItem('Page.AttributionPalette');

                    if (val) {
                        palet.val(val);
                    }
                }

                App.selectFirst(palet);

                // ***

                view.bindEvents();

                // ***

                palet.change();
            }
        }

        /**
         * Binds all events
         */
        function bindEvents() {
            var view = this,
                form = view.el('form'),
                number = view.el('numerock');

            /*
             * Autofocus
             */
            state.focusInterval = setInterval(function () {
                if (state.canFocus) {
                    number.prop('disabled', false);

                    if (!number.val()) {
                        number.select();
                    } else {
                        number.focus();
                    }
                } else {
                    number.prop('disabled', true);
                }
            }, 250);

            /*
             * Validate the number when requested
             */
            state.bindings.push((function () {
                form
                    .on('submit', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        view.validateNumber();
                    })
                    .on('reset', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        view.reset();
                    });

                return function () {
                    form.off('submit reset');
                };
            })());

            /*
             * Reset the form
             */
            state.bindings.push((function () {
                var reset = view.el('reset')
                    .on('click', function () {
                        view.reset();
                    });

                return function () {
                    reset.off('click');
                };
            })());

            /*
             * Autofocus on change + Session save when palet is selected
             */
            state.bindings.push((function () {
                var palet = view.el('palette')
                    .on('change', function () {
                        var self = $(this).blur();

                        view.onPaletChanged($('option:selected', self).data('palet'));
                    });

                return function () {
                    palet.off('change');
                };
            })());

            /*
             * Cancel/Force focus on number field when others field gain/lost focus
             */
            state.bindings.push((function () {
                var palet = view.el('palette'),
                    fields = form.find('input,select,textarea').not(number)
                        .on('focus', function () {
                            state.canFocus = false;
                        })
                        .on('blur', function () {
                            state.canFocus = canFocus();
                        }),
                    canFocus = function () {
                        return palet.val() > 0;
                    };

                return function () {
                    fields.off('focus blur');
                };
            })());

            /*
             * Handle parcel unload button
             */
            state.bindings.push((function () {
                var listing = view.el('listing')
                    .on('click', '[data-trigger=unload]', function () {
                        var wait = App.showWaiting('Retrait en cours'),
                            row = $(this);

                        Api.Colikado.unloadParcelFromPalet(row.data('id'), true)
                            .done(function () {
                                wait.close();
                                view.onPaletUnloaded(row.data('id'));
                                row.closest('.AttributionPalette__Listing_Row').remove();
                            })
                            .fail(function (error) {
                                wait.close();
                                App.showError('Impossible de retirer ce colis de la palette', error);
                            });
                    });

                return function () {
                    listing.off('click', '[data-trigger=unload]');
                };
            })());

            /*
             * Handle history
             */
            if ('function' === typeof window.history.replaceState) {
                window.history.replaceState(
                    {
                        page: 'home-palet-load'
                    }, null, view.$$el.closest('[v-page]').data('url-home')
                );
            }
        }

        /**
         * Clear bindings, stop autofocus
         */
        function onExit() {
            var i;

            state.focusInterval && clearInterval(state.focusInterval);
            state.$$watchPaletLoadContent = false;

            for (i = 0; i < state.bindings.length; ++i) {
                state.bindings[i]();
            }

            state.bindings = [];
        }

        /**
         * Clear the number field, keep the palet
         */
        function reset() {
            var view = this,
                form = view.el('form'),
                number = view.el('numerock'),
                alert = view.el('alert'),
                buttons = form.find('input,select,button,a');

            buttons.prop('disabled', false);
            number.val('');
            alert.text('').addClass('hidden');
        }

        /**
         * Call API to check the number if it matches the provided pattern
         */
        function validateNumber() {
            var view = this,
                defer = $.Deferred(),
                form = view.el('form'),
                number = view.el('numerock'),
                palet = view.el('palette').val(),
                fields = form.find('input,select,button,a'),
                value = number.val(),
                failureAlert = function (errors) {
                    var alert = view.el('alert')
                        .html('!!! Numéro [<strong>' + value + '</strong>] invalide !!!')
                        .addClass('alert-danger')
                        .removeClass('alert-success alert-warning hidden');

                    if ('string' === typeof errors) {
                        errors = {
                            erreur: errors
                        };
                    }

                    if ('object' === typeof errors && null !== errors) {
                        var ul = $('<ul></ul>').appendTo(alert);
                        for (var error in errors) {
                            if (errors.hasOwnProperty(error)) {
                                $('<li></li>')
                                    .text(errors[error])
                                    .appendTo(ul);
                            }
                        }
                    }

                    setTimeout(function () {
                        if (state.sounds.ko) {
                            state.sounds.ko.trigger('play');
                        }
                    }, 250);

                },
                successAlert = function (errors) {
                    var alert = view.el('alert')
                        .html('Colis [<strong>' + value + '</strong>] attribué à la palette')
                        .addClass('alert-success')
                        .removeClass('alert-danger alert-warning hidden');

                    if (state.$$confirmTimeout) {
                        clearTimeout(state.$$confirmTimeout);
                    }

                    state.$$confirmTimeout = setTimeout(function () {
                        if (alert.hasClass('alert-success')) {
                            alert.addClass('hidden');
                        }
                    }, 5000);
                },
                chronoSuffixMap = function (code) {
                    switch (1 * code) {
                        case 209:
                            return 'EE';
                        case 248:
                            return 'FR';
                        case 336:
                            return 'JB';
                        case 497:
                            return 'PG';
                        case 544:
                            return 'RB';
                        case 564:
                            return 'RV';
                        case 422:
                            return 'MJ';
                    }

                    return 'FR';
                };

            if (value.match(/^[^A-Za-z0-9][0-9]{7}[a-zA-Z]{2}[0-9]{12}/)) {
                value = value.substr(8, 11) + chronoSuffixMap(value.substr(19, 3));
            }

            if (!value.match(/^(?:(?:(?:TT|C[AP])|(?:tt|c[ap]))[0-9]{9})$/)
                && !value.match(/^(?:[A-Za-z]{2}[0-9]{9}[A-Za-z]{2})/)
                && !value.match(/^(?:(?:R|r)?[0-9]{13})/)) {
                view.reset();
                failureAlert();
                defer.reject();
            }

            FormValidation.validate(view.el('form').get(0))
                .success(function (validation) {
                    var id = value + '-' + moment().format('x') + '-' + Math.round(1000 * Math.random);

                    validation.reset();
                    view.reset();
                    view.addProcessing(id, value);

                    Api.Colikado.loadParcelOnPalet(value, palet)
                        .done(function (parcel) {
                            view.clearProcessing(id);
                            successAlert();
                            view.onPaletLoaded(parcel);
                            defer.resolve();
                        })
                        .fail(function (error) {
                            view.clearProcessing(id, error.errors || error.message, error.code);
                            failureAlert(error.errors || error.message);
                            defer.reject();

                            if (error && 423 === error.code) {
                                setTimeout(function () {
                                    App.showError({
                                        title: 'INTERCEPTION REQUISE',
                                        html: 'Le colis [ ' + value + ' ] doit être intercepté.<br><strong>Aucune action autorisée.</strong>'
                                    });
                                }, 250);
                            }
                        });
                })
                .fail(function () {
                    view.reset();
                    failureAlert();
                    defer.reject();
                });

            return defer;
        }

        /**
         *
         * @param {*} palet
         */
        function onPaletChanged(palet) {
            var view = this;

            state.palet = palet || {id: null};

            if (window.sessionStorage) {
                window.sessionStorage.setItem('Page.AttributionPalette', state.palet.id);
            }

            // ***

            if (state.statsInterval) {
                clearInterval(state.statsInterval);
            }

            state.$$statsDelayLoadContent = 10000;
            state.$$statsDelayLimits = 5000;
            state.$$watchPaletLoadContent = !!state.palet.id;
            state.$$watchPaletLimits = !!state.palet.id;

            view.watchPaletLoadContent(true);
            view.watchPaletLimits(true);
        }

        /**
         * Handle UI refresh when parcel is laoded on palet
         *
         * @param {*} parcel
         */
        function onPaletLoaded(parcel) {
            var view = this;
        }

        /**
         *
         * @param {string} number
         */
        function onPaletUnloaded(number) {
            var view = this;
        }

        /**
         * Continuously fetch statistics data if enabled
         * Auto adjusting interval in [5,30] sec
         *
         * @param {boolean} autostart if `true` triggers the load immediatly then launch the watch
         */
        function watchPaletLoadContent(autostart) {
            var view = this;

            if (autostart) {
                view.updatePaletLoadContent()
                    .always(function () {
                        view.watchPaletLoadContent();
                    });
            }
            else if (state.$$watchPaletLoadContent) {
                if (state.$$watchPaletLoadContentTimeout) {
                    clearTimeout(state.$$watchPaletLoadContentTimeout);
                }

                state.$$watchPaletLoadContentTimeout = setTimeout(function () {
                    state.$$watchPaletLoadContent = false;

                    view.updatePaletLoadContent()
                        .done(function () {
                            state.$$statsDelayLoadContent = 5000;
                        })
                        .fail(function () {
                            state.$$statsDelayLoadContent = Math.min(30000, state.$$statsDelayLoadContent + 0.5 * state.$$statsDelayLoadContent);
                        })
                        .always(function () {
                            state.$$watchPaletLoadContent = true;
                            view.watchPaletLoadContent();
                        });

                    state.$$watchPaletLoadContentTimeout = null;
                }, state.$$statsDelayLoadContent);
            }
        }

        /**
         * Triggers instant refresh of live palet loaded content (incremental update)
         */
        function updatePaletLoadContent() {
            var view = this,
                defer = $.Deferred(),
                container = view.el('listing');

            if (state.palet.id) {
                // Pull data
                Api.Colikado.getPalet(state.palet.id, ['colikado.destinataire', 'colikado.produit', 'colipays.destinataire', 'colipays.produit'])
                    .done(function (palet) {
                        if (
                            !state.palet.colikado || !state.palet.colipays || !Utils.equals(palet.colikado.data, state.palet.colikado.data)
                            || !Utils.equals(palet.colipays.data, state.palet.colipays.data)
                        ) {
                            state.palet = palet;
                            defer.resolve([].concat(palet.colikado.data, palet.colipays.data).sort(function (a, b) {
                                return b.fret.depart > a.fret.depart ? '1' : '-1';
                            }));
                        } else {
                            defer.reject();
                        }
                    })
                    .fail(function () {
                        defer.reject();
                    });

                // If modified, update UI
                defer.then(function (parcels) {
                    var template = view.el('listing-item-template').html(),
                        i, html = '', data;

                    for (i = 0; i < parcels.length; ++i) {
                        if (parcels[i]._type == 'ColikadoParcel') {
                            data = {
                                css: 'colikado',
                                type: 'COLIKADO',
                                numero: parcels[i].numero,
                                bordereau: parcels[i].fret.bordereau,
                                produit: parcels[i].produit.nom,
                                destination: parcels[i].destinataire.adresse.code_postal
                                + ' ' + parcels[i].destinataire.adresse.ville
                                + ' (' + parcels[i].destinataire.adresse.pays.code + ')',
                                date: moment(parcels[i].fret.depart).format('DD/MM/YYYY HH:mm:ss')
                            };
                        } else {
                            data = {
                                css: 'colipays',
                                type: parcels[i].produit.code.match(/^APF-.*/) ? 'APF' : 'COLIPAYS',
                                numero: parcels[i].numero,
                                bordereau: parcels[i].fret.bordereau,
                                produit: parcels[i].produit.nom,
                                destination: parcels[i].destinataire.adresse.code_postal
                                + ' ' + parcels[i].destinataire.adresse.ville
                                + ' (' + parcels[i].destinataire.adresse.pays.code + ')',
                                date: moment(parcels[i].fret.depart).format('DD/MM/YYYY HH:mm:ss')
                            }
                        }

                        html += Mustache.render(template, data);
                    }

                    $(html).prependTo(container.empty());
                });
            } else {
                var template = view.el('listing-item-empty-template').html();

                $(Mustache.render(template, {})).appendTo(container.empty());

                defer.reject();
            }

            return defer;
        }

        /**
         * Continuously fetch statistics data if enabled
         * Auto adjusting interval in [5,30] sec
         *
         * @param {boolean} autostart if `true` triggers the load immediatly then launch the watch
         */
        function watchPaletLimits(autostart) {
            var view = this;

            if (autostart) {
                view.updatePaletLimits()
                    .always(function () {
                        view.watchPaletLimits();
                    });
            }
            else if (state.$$watchPaletLimits) {
                if (state.$$watchPaletLimitsTimeout) {
                    clearTimeout(state.$$watchPaletLimitsTimeout);
                }

                state.$$watchPaletLimitsTimeout = setTimeout(function () {
                    state.$$watchPaletLimits = false;

                    view.updatePaletLimits()
                        .done(function () {
                            state.$$statsDelayLimits = 2500;
                        })
                        .fail(function () {
                            state.$$statsDelayLimits = Math.min(30000, state.$$statsDelayLimits + 0.25 * state.$$statsDelayLimits);
                        })
                        .always(function () {
                            state.$$watchPaletLimits = true;
                            view.watchPaletLimits();
                        });

                    state.$$watchPaletLimitsTimeout = null;
                }, state.$$statsDelayLimits);
            }
        }

        /**
         * Triggers instant refresh of live palet loaded content (incremental update)
         */
        function updatePaletLimits() {
            var view = this,
                defer = $.Deferred(),
                container = view.el('limits'),
                parseInfinite = function (obj) {
                    var keyAttribute;

                    for (keyAttribute in obj) {
                        if (obj.hasOwnProperty(keyAttribute) && obj[keyAttribute] == -1) {
                            obj[keyAttribute] = '∞';
                        }
                    }

                    return obj;
                };

            if (state.palet.id) {
                // Pull data
                Api.Colikado.getPaletLimits(state.palet.id)
                    .done(function (limits) {
                        if (!Utils.equals(limits, state.limits)) {
                            var i;

                            limits.limits.palette = parseInfinite(limits.limits.palette);
                            limits.limits.vol = parseInfinite(limits.limits.vol);

                            for (i in limits.limits.palettes) {
                                limits.limits.palettes[i] = parseInfinite(limits.limits.palettes[i]);
                            }

                            if (!Utils.equals(state.limits, limits)) {
                                state.limits = limits;
                                defer.resolve(limits);
                            } else {
                                defer.reject();
                            }
                        } else {
                            defer.reject();
                        }
                    })
                    .fail(function () {
                        defer.reject();
                    });

                // If modified, update UI
                defer.then(function (limits) {
                    var template = view.el('limits-template').html(),
                        templateCategoryRow = view.el('limits-template-category-row').html(),
                        templateCategoryPaletteRow = view.el('limits-template-category-palette-row').html(),
                        templateTotalPaletteRow = view.el('limits-template-total-palette-row').html(),
                        palet = $('option:selected', view.el('palette')).data('palet'),
                        i = 0;

                    template = $(Mustache.render(template, $.extend(true, limits, {
                        rowspan: 2 + Object.keys(limits.limits.palettes).length
                    })))
                        .appendTo(container.empty());

                    var lastRow = $(Mustache.render(templateCategoryRow, {
                        name: 'COLIKADO',
                        rowspan: 2 + Object.keys(limits.colikado.palettes).length,
                        vol: limits.colikado.vol,
                        limits: limits.limits.vol
                    }))
                        .prependTo(template.find('tbody'));

                    lastRow = $(Mustache.render(templateCategoryPaletteRow, $.extend(true, limits.colikado.palette, {
                        cssClass: 'current',
                        limits: limits.limits.palette
                    })))
                        .insertAfter(lastRow);

                    for (i in limits.colikado.palettes) {
                        lastRow = $(Mustache.render(templateCategoryPaletteRow, $.extend(true, limits.colikado.palettes[i], {
                            limits: limits.limits.palettes[limits.colikado.palettes[i].number]
                        })))
                            .insertAfter(lastRow);
                    }

                    lastRow = $(Mustache.render(templateCategoryRow, {
                        name: 'COLIPAYS',
                        rowspan: 2 + Object.keys(limits.colipays.palettes).length,
                        vol: limits.colipays.vol,
                        limits: limits.limits.vol
                    }))
                        .insertAfter(lastRow);

                    lastRow = $(Mustache.render(templateCategoryPaletteRow, $.extend(true, limits.colipays.palette, {
                        cssClass: 'current',
                        limits: limits.limits.palette
                    })))
                        .insertAfter(lastRow);

                    for (i in limits.colipays.palettes) {
                        lastRow = $(Mustache.render(templateCategoryPaletteRow, $.extend(true, limits.colipays.palettes[i], {
                            limits: limits.limits.palettes[limits.colipays.palettes[i].number]
                        })))
                            .insertAfter(lastRow);
                    }

                    lastRow = $(Mustache.render(templateTotalPaletteRow, {
                        number: limits.total.palette.number,
                        total: limits.total.palette,
                        limits: limits.limits.palette,
                        cssClass: 'current'
                    }))
                        .insertAfter(template.find('.limit--total'));

                    for (i in limits.total.palettes) {
                        lastRow = $(Mustache.render(templateTotalPaletteRow, {
                            number: limits.total.palettes[i].number,
                            total: limits.total.palettes[i],
                            limits: limits.limits.palettes[limits.total.palettes[i].number]
                        }))
                            .insertAfter(lastRow);
                    }
                });
            } else {
                var template = view.el('listing-empty-template').html();

                $(Mustache.render(template, {})).appendTo(container.empty());

                defer.reject();
            }

            return defer;
        }

        /**
         * Add a row to the last scanned table
         * @param {string} id the row id
         * @param {string} number the parcel number
         */
        function addProcessing(id, number) {
            var view = this,
                template = view.el('processing-template').html(),
                container = view.el('processing-list');

            $('[data-processing-id=' + id + ']', container).remove();

            $(Mustache.render(template, {
                id: id,
                number: number,
                date: moment().format('HH:mm:ss')
            })).prependTo(container);
        }

        /**
         * Update the last scanned number table when the server respond
         *
         * @param {string} id the row id
         * @param {*} errors string or object (key/value) of the error messages
         * @param {int} errorCode the error code
         */
        function clearProcessing(id, errors, errorCode) {
            var view = this,
                container = view.el('processing-list'),
                target = $('[data-processing-id=' + id + ']', container);

            if (target.length) {
                // 304 === Already Scanned
                if (304 === errorCode) {
                    target.addClass('warning');
                    $('.Processing__Status', target).text('DEJA ATTRIBUE A UNE PALETTE');

                    setTimeout(function () {
                        if (state.sounds.ko) {
                            state.sounds.ko.trigger('play');
                        }
                    }, 250);

                    setTimeout(function () {
                        target.fadeOut('normal', function () {
                            target.remove();
                        });
                    }, 120000);
                }
                else if (!errors) {
                    target.addClass('success');
                    $('.Processing__Status', target).text('OK');

                    setTimeout(function () {
                        if (state.sounds.ok) {
                            state.sounds.ok.trigger('play');
                        }
                    }, 250);

                    setTimeout(function () {
                        target.fadeOut('normal', function () {
                            target.remove();
                        });
                    }, 60000);
                } else {
                    var errorTarget = $('.Processing__Details', target).empty();

                    if ('object' === typeof errors && null !== errors) {
                        var ul = $('<ul></ul>').appendTo(errorTarget);
                        for (var error in errors) {
                            if (errors.hasOwnProperty(error)) {
                                $('<li></li>')
                                    .text(errors[error])
                                    .appendTo(ul);
                            }
                        }
                    } else {
                        errorTarget.text(errors);
                    }

                    target.addClass('danger');
                    $('.Processing__Status', target).text('ERREUR');

                    setTimeout(function () {
                        if (state.sounds.ko) {
                            state.sounds.ko.trigger('play');
                        }
                    }, 250);

                    setTimeout(function () {
                        target.fadeOut('normal', function () {
                            target.remove();
                        });
                    }, 180000);
                }
            }
        }
    }
}(window, jQuery);