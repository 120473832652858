+function (window, document, $) {
    "use strict";

    var module = {
        // Public functions,
        $$api: $$api,
        // Transformers
        transformIn: transformIn,
        transformOut: transformOut,
        // Utility functions
        _buildParams: _buildParams
    };

    window.App = window.App || {};
    window.App.Api = window.App.Api || {};
    window.App.Api.Base = module;

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Get the api instance
     *
     * @private
     * @returns {*|{}} Colipays V2 API gateway
     */
    function $$api() {
        return window.Colipays.Api;
    }

    // --------------------------------------------------------------------
    // DATA MANIPULATION FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Transform an API structure from an API representation to a local representation based on `_type` property
     * @param {*} object source object
     * @returns {*} transformed object
     */
    function transformIn(object) {
        var api = this,
            transformers = window.App.Api.Transformers;

        if (null === object || 'object' !== typeof object || !object.hasOwnProperty('_type') || 'undefined' === typeof transformers[object._type]) {
            return object;
        }

        // Deep transformIn recursively
        for (var property in object) {
            if (object.hasOwnProperty(property)) {
                object[property] = api.transformIn(object[property]);
            }
        }

        return transformers[object._type].transformIn(object);
    }

    /**
     * Transform an API structure from local representation to an API representation based on `_type` property
     *
     * @param {*} object source object
     * @param {string} type Object type (transformer binding)
     *
     * @returns {*} transformed object
     */
    function transformOut(object, type) {
        var transformers = window.App.Api.Transformers;

        if ('object' !== typeof object || 'string' !== typeof type || 'undefined' === typeof transformers[type]) {
            return object;
        }

        return transformers[type].transformOut(object);
    }

    // --------------------------------------------------------------------
    // UTILITY FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Parse query options/include for API requests
     *
     * @param {{}} params URL parameters
     * @param {[]|string} includes list of relations to include
     *
     * @returns {[]}
     */
    function _buildParams(params, includes) {
        if (!$.isPlainObject(params)) {
            params = {};

            if (1 === arguments.length) {
                includes = params;
            }
        }

        if ('string' === typeof includes) {
            includes = [includes];
        } else if (!$.isArray(includes)) {
            includes = [];
        }

        params.include = includes.join(',');

        return params;
    }
}(window, document, jQuery);