+function (window, document, $) {
    "use strict";

    var template = "<div class=\"{{templateId}} ColikadoParcel_Actions\">\n    <div class=\"row\">\n        <div class=\"col-sm-4 text-center\">\n            <button type=\"button\" v-el=\"{{modelPrefix}}documents\" class=\"btn btn-info btn-squared ColikadoParcel_Actions__Action\">\n                <span class=\"ico ico-documents\"></span>\n                IMPRIMER\n                <br>\n                LE BORDEREAU\n            </button>\n        </div>\n        <div class=\"col-sm-4 text-center\">\n            <button type=\"button\" v-el=\"{{modelPrefix}}label\" class=\"btn btn-info btn-squared ColikadoParcel_Actions__Action\">\n                <span class=\"ico ico-printer\"></span>\n                IMPRIMER\n                <br>\n                L\'ETIQUETTE\n            </button>\n        </div>\n        <div class=\"col-sm-4 text-center\">\n            <button type=\"button\" v-el=\"{{modelPrefix}}input\" class=\"btn btn-info btn-squared ColikadoParcel_Actions__Action\">\n                <span class=\"ico ico-keyboard\"></span>\n                MODIFIER\n                <br>\n                LA SAISIE\n            </button>\n        </div>\n    </div>\n</div>",
        module = {
            directive: 'parcel-actions',
            compile: compile,
            defaults: {
                modelPrefix: 'action'
            }
        };

    window.App = window.App || {};
    window.App.Components = window.App.Components || {};
    window.App.Components.ParcelActions = module;

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    function compile(el) {
        var module = this,
            state = {
                // Builder
                prepare: prepare,
                build: build,
                bind: bind,
                // Events
                loadParcel: loadParcel,
                // Actions
                printSkybill: printSkybill,
                printLabel: printLabel,
                inputData: inputData,
                // Data
                parcel: null
            },
            App = window.App,
            Api = window.App.Api;

        return App.Components.initComponent(module, el, state);

        /*
         * --------- LIFECYCLE FUNCTIONS ---------
         */

        /**
         * Prepare the view state and the tempalte string before the compilation
         *
         * @param {*} view view state
         *
         * @returns {string} the template string
         */
        function prepare(view) {
            view.disableInput = App.Utils.toBoolean(view.disableInput);

            return template;
        }


        /**
         * Build the component element
         *
         * @param {*} view view state
         *
         * @returns {string} the template string
         */
        function build(component, view) {
            if (view.disableInput) {
                state.el('input').closest('.col-sm-4').remove();
            }

            // Preload PDF plugin
            // Api.Colikado.initAutoPrint();
        }

        /**
         * Binds event on the element
         *
         * @param {*} component jQuery element
         * @param {*} view view state
         */
        function bind(component, view) {
            component.on('parcel.loaded', function (event, parcel) {
                state.loadParcel(parcel);
            });

            state.el('documents').on('click', function () {
                state.printSkybill()
                    .done(function (data) {
                        component.trigger('skybill.printed', data);
                    });
            });

            state.el('label').on('click', function () {
                state.printLabel()
                    .done(function (data) {
                        component.trigger('label.printed', data);
                    });
            });

            state.el('input').on('click', function () {
                state.inputData()
                    .done(function (data) {
                        component.trigger('edit.' + data.mode, data.parcel);
                    });
            });
        }

        /*
         * --------- FUNCTIONAL FUNCTIONS ---------
         */

        /**
         * Update the state with a new parcel
         *
         * @param {*} parcel the loaded parcel data
         */
        function loadParcel(parcel) {
            state.parcel = parcel;

            // Buttons state
            state.el('documents').prop('disabled', !parcel.saisie.terminee);
            state.el('label').prop('disabled', !parcel.saisie.terminee && !parcel.saisie.rapide);
            state.el('input').prop('disabled', parcel.fret.embarque);
        }

        /**
         * Fetch & Print the label
         */
        function printSkybill() {
            var defer = $.Deferred(),
                wait = App.Alert.showWaiting('Impression en cours');

            Api.Colikado.printSkybill(state.parcel.numero)
                .done(function () {
                    wait.close();

                    defer.resolve();
                })
                .fail(function (error) {
                    wait.close();

                    App.Alert.showError({
                        title: 'Erreur de saisie',
                        html: 'Vérifiez les données saisies :<br><strong>' + JSON.stringify(error) + '</strong>'
                    });

                    defer.reject();
                });

            return defer;
        }

        /**
         * Fetch & Print the label
         */
        function printLabel() {
            var defer = $.Deferred(),
                wait = App.Alert.showWaiting('Impression en cours');

            Api.Colikado.printLabel(state.parcel.numero)
                .done(function () {
                    wait.close();

                    defer.resolve();
                })
                .fail(function (error) {
                    wait.close();

                    App.Alert.showError({
                        title: 'Erreur de saisie',
                        html: 'Vérifiez les données saisies :<br><strong>' + JSON.stringify(error) + '</strong>'
                    });

                    defer.reject();
                });

            return defer;
        }


        /**
         * Redirect the user to the input screen depending on the parcel state
         * If the parcel is not entered at all -> let the user choose otherwise redirect to full input
         */
        function inputData() {
            var defer = $.Deferred(),
                parcel = state.parcel;

            if (parcel.saisie.terminee || parcel.saisie.rapide) {
                defer.resolve({
                    mode: 'full',
                    parcel: parcel
                });
            } else {
                swal({
                    type: 'warning',
                    title: 'Ce colis n\'a jamais été saisi',
                    text: 'Comment souhaitez-vous poursuivre ?',
                    allowEscapeKey: false,
                    showCancelButton: true,
                    closeOnCancel: true,
                    cancelButtonText: 'Saisie Rapide',
                    cancelButtonColor: '#7489A0',
                    showConfirmButton: true,
                    closeOnConfirm: false,
                    showLoaderOnConfirm: true,
                    confirmButtonText: 'Saisie Complète',
                    confirmButtonColor: '#0459B7'
                }, function (full) {
                    if (full) {
                        defer.resolve({
                            mode: 'full',
                            parcel: parcel
                        });
                    } else {
                        defer.resolve({
                            mode: 'fast',
                            parcel: parcel
                        });
                    }
                });
            }

            return defer;
        }
    }
}(window, document, jQuery);