+function (window, document, $) {
    var module = {
            /* internals */
            _endpoint: null, // Central API
            _apiToken: null, // Central API token
            _loginUrl: null, // Local Login Proxy URI
            _loggedIn: true,
            _queue: {},
            _login: login,
            _progress: {  // Progress bar
                start: function () {
                },
                stop: function () {
                }
            },
            /* required module methods */
            init: init,
            /* exposed methods  */
            $post: $post, // shortcut $ajax(POST)
            $put: $put,   // shortcut $ajax(PUT)
            $get: $get,   // shortcut $ajax(GET)
            $delete: $delete,   // shortcut $ajax(DELETE)
            $ajax: $ajax  // Generic AJAX call
        },
        tokenKey = 'colipays-v2-token';

    window.Colipays = window.Colipays || {};
    window.Colipays.Api = $.extend(true, module, window.Colipays.Api || {});

    // Bootstrap
    $(function () {
        module.init();
    });

    return void 0;

    // ---------- FUNCTIONS ---------------------------------------------------

    function init() {
        var $body = $('body'),
            progress = window.NProgress;
        this._endpoint = $body.data('colipays-api-endpoint');
        this._loginUrl = $body.data('colipays-api-login');
        this._apiToken = window.sessionStorage.getItem(tokenKey);

        if ($('[data-api-logout]').length) {
            this._apiToken = null;
            window.sessionStorage.removeItem(tokenKey);
        }

        // Activate progress only if NProgress is installed
        if (progress) {
            progress.configure({
                showSpinner: false,
                trickleRate: 0.05,
                trickleSpeed: 500
            });

            this._progress = {
                start: progress.start,
                stop: progress.done
            }
        }

        setTimeout(function () {
            var Api = window.Colipays.Api;

            if (typeof Api.LocalApiDiscovery === 'object') {
                Api.LocalApiDiscovery.discover().done(function () {
                    $(document).trigger('colipays:api:loaded');
                });
            } else {
                $(document).trigger('colipays:api:loaded')
            }
        }, 0);
    }

    function login() {
        var self = this,
            defer = null;

        if (self._queue.login) {

            defer = self._queue.login;
        } else {
            defer = $.Deferred();

            self._queue.login = defer;

            $.ajax({
                method: 'GET',
                url: self._loginUrl,
                dataType: 'json'
            })
                .done(function (data) {
                    self._apiToken = data.token;
                    window.sessionStorage.setItem(tokenKey, self._apiToken);
                    self._loggedIn = true;
                    self._queue.login = null;
                    defer.resolve();
                })
                .fail(function () {
                    self._loggedIn = false;
                    if (typeof console.error == 'function') {
                        console.error('Central API access denied');
                        alert('Vous avez été déconnecté, veuillez-vous re-connecter');
                        window.location.href = $('body').data('colipays-home');
                    }
                    self._queue.login = null;
                    defer.reject({
                        status_code: 401,
                        message: 'Access denied'
                    });
                });
        }

        return defer;
    }

    /*
     * Ajax Utils
     */

    function $post(uri, data) {
        return this.$ajax('POST', uri, data);
    }

    function $put(uri, data) {
        return this.$ajax('PUT', uri, data);
    }

    function $get(uri, data) {
        return this.$ajax('GET', uri, data);
    }

    function $delete(uri, data) {
        return this.$ajax('DELETE', uri, data);
    }

    function $ajax(method, uri, data) {
        var self = this,
            defer = $.Deferred(),
            done = function (data) {
                defer.resolve(data);
                self._progress.stop();
            },
            reject = function (data) {
                defer.reject(data);
                self._progress.stop();
            };

        if (!self._endpoint) {
            defer.reject({error: 'no endpoint configured', code: 503});

            return defer;
        }

        self._progress.start();

        if (self._loggedIn) {
            var ajax = function (retry) {
                var contentType, key;

                if ('/' !== uri.substring(0, 1)) {
                    uri = '/' + uri;
                }

                // Do not send null parameters
                for (key in data) {
                    if (data.hasOwnProperty(key) && null === data[key]) {
                        delete data[key];
                    }
                }

                if ('POST' === method || 'PUT' === method) {
                    contentType = 'application/json';
                    data = JSON.stringify(data);
                }

                $.ajax({
                    method: method,
                    url: self._endpoint + uri,
                    data: data,
                    contentType: contentType,
                    dataType: 'json',
                    crossDomain: true,
                    headers: {
                        'Authorization': 'Bearer ' + self._apiToken
                    }
                })
                    .done(done)
                    .fail(function (xhr) {
                        if (401 == xhr.status || 403 == xhr.status) {
                            window.sessionStorage.removeItem(tokenKey);

                            self._login()
                                .done(function () {
                                    if (retry) {
                                        ajax(false);
                                    }
                                })
                                .fail(reject);
                        } else if (404 == xhr.status) {
                            var message = 'Resource not found';

                            if ($.isPlainObject(xhr.responseJSON) && xhr.responseJSON.message) {
                                message += ': ' + xhr.responseJSON.message;
                            }

                            reject({
                                message: message,
                                status_code: 404
                            });
                        } else if (xhr.responseText) {
                            reject(JSON.parse(xhr.responseText));
                        } else {
                            reject({
                                message: 'Unknown error',
                                status_code: xhr.status
                            });
                        }
                    });
            };

            if (self._apiToken) {
                ajax(true);
            } else {
                self._login()
                    .done(function () {
                        ajax(true);
                    })
                    .fail(reject);
            }
        }

        return defer;
    }

}(window, document, jQuery);