+function (window, document, $) {
    "use strict";

    var template = "<div class=\"AppForm__FreePackage\">\n    <div class=\"form-content\">\n        <div class=\"row\">\n            <div class=\"col-sm-12 AppForm__FreePackage_Mode\">\n                <div class=\"form-group\">\n                    <label class=\"control-label\">Mode de facturation</label>\n                    <div class=\"radio\">\n                        <label>\n                            <input type=\"radio\" v-el=\"{{modelPrefix}}mode\" id=\"{{modelPrefixSlug}}mode-facture\"\n                                   name=\"{{modelPrefixSlug}}mode\" value=\"facture\" checked>\n                            Facturation payante (mode par défaut)\n                        </label>\n                    </div>\n                    <div class=\"radio\">\n                        <label>\n                            <input type=\"radio\" v-el=\"{{modelPrefix}}mode\" id=\"{{modelPrefixSlug}}mode-reexpedition\"\n                                   name=\"{{modelPrefixSlug}}mode\" value=\"reexpedition\">\n                            Facturation Gratuite en <strong>Réexpédition</strong>\n                        </label>\n                    </div>\n                    <div class=\"radio\">\n                        <label>\n                            <input type=\"radio\" v-el=\"{{modelPrefix}}mode\" id=\"{{modelPrefixSlug}}mode-cadeau\"\n                                   name=\"{{modelPrefixSlug}}mode\" value=\"cadeau\">\n                            Facturation Gratuite en <strong>Cadeau</strong>\n                        </label>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>",
        module = {
            directive: 'freepackage-input',
            compile: compile,
            defaults: {
                modelPrefix: 'parcel.prix'
            }
        };

    window.App = window.App || {};
    window.App.Components = window.App.Components || {};
    window.App.Components.FreePackageInput = module;

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    function compile(el) {
        var module = this,
            state = {
                last_mode_invoicable: true,
                // Builder
                prepare: prepare,
                build: build,
                bind: bind,
                // Events
                loadParcel: loadParcel,
                onModeSelect: onModeSelect
                // Actions
            };

        return window.App.Components.initComponent(module, el, state);

        /*
         * --------- FUNCTIONS ---------
         */

        function prepare(view) {
            return template;
        }

        function build(component, view) {

        }

        function bind(component, view) {
            var builder = this;

            component.on('parcel.loaded', function (event, parcel) {
                builder.loadParcel(parcel);
            });

            builder.el('mode').on('change', function () {
                builder.onModeSelect($('input[type=radio]:checked', builder.el('mode').closest('.AppForm__FreePackage_Mode')).val());
            })
        }

        function onModeSelect(mode) {
            var builder = this,
                invoicable = mode != 'cadeau' && mode != 'reexpedition';

            if (builder.last_mode_invoicable !== invoicable) {
                if (!invoicable) {
                    App.Alert.showWarning('Les informations de facturation seront effacées définitivement : prix à 0 pour les statistiques et la facturation.');
                }

                builder.last_mode_invoicable = invoicable;
            }
        }

        function loadParcel(parcel) {
            var builder = this;

            if (parcel.prix.mode === 'cadeau' || parcel.prix.mode === 'reexpedition') {
                builder.last_mode_invoicable = false;

                builder.el('mode').map(function (index, item) {
                    var $item = $(item);

                    if ($item.val() === 'facture') {
                        $item.closest('label').remove();
                    }
                });
            }
        }
    }
}(window, document, jQuery);