(function ($) {

    $.fn.extend({
        groupTable: function (startIndex, total) {
            this.find('tr:has(td)').groupTableRows(startIndex, total);
            this.find('.deleted').remove();
        },

        groupTableRows: function (startIndex, total) {
            if (total === 0) {
                return;
            }

            var $rows = this,
                i, currentIndex = startIndex, count = 1, lst = [],
                tds = $rows.find('td:eq(' + currentIndex + ')'),
                ctrl = $(tds[0]);

            lst.push($rows[0]);

            for (i = 1; i <= tds.length; i++) {
                if (ctrl.text() == $(tds[i]).text()) {
                    count++;
                    $(tds[i]).addClass('deleted');
                    lst.push($rows[i]);
                } else {
                    if (count > 1) {
                        ctrl.attr('rowspan', count);
                        ctrl.addClass('groupTableRow');
                        $(lst).last().addClass('groupTableRow');
                        $(lst).groupTableRows(startIndex + 1, total - 1);
                    } else if (count == 1 && total == 1) {
                        $($rows[i]).addClass('groupTableRowUp');
                    }

                    count = 1;
                    lst = [];
                    ctrl = $(tds[i]);
                    lst.push($rows[i]);
                }
            }
        }
    });

})(jQuery);