+function (window, document, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.RetraitPointDeVente = window.App.Pages.RetraitPointDeVente || {};

    var module = window.App.Pages.RetraitPointDeVente;

    module.views = module.views || {};
    module.name = 'RetraitPointDeVente';

    $(document).ready(function () {
        window.App.OnePage.load(module.name, initPage);
    });

    return void 0;

    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------
    // ---------- App Definition
    // ------------------------------------------------------------------------
    // ------------------------------------------------------------------------

    function initPage(pageEl) {
        var App = {
                views: {
                    'home': module.views.home
                },
                init: init,
                goto: goto,
                showError: showError,
                showWaiting: showWaiting
            };

        App.init();

        // ------------------------------------------------------------------------
        // ------------------------------------------------------------------------
        // ---------- App functions
        // ------------------------------------------------------------------------
        // ------------------------------------------------------------------------

        /**
         * Bootstrap the page views
         */
        function init() {
            window.App.OnePage.initViews(App, pageEl);

            App.goto('home');
        }


        /**
         * Display a SWAL alert box
         * @param {*} message The title as string or `{ title: string, text: string }`
         * @param {*} error Optional error data
         * @param {*} cb callback function on close
         */
        function showError(message, error, cb) {
            return window.App.Alert.showError(message, error, cb);
        }

        /**
         * Display a waiting SWAL alert box
         *
         * @returns {{close: Function}} Call close to close the waiting bo
         */
        function showWaiting(title) {
            return window.App.Alert.showWaiting(title);
        }

        /**
         * Change the view
         * @param {string} view The view key
         * @param {*} [data] Optional data to pass to the view onEnter event
         */
        function goto(view, data) {
            return window.App.OnePage.gotoView(this, view, data);
        }
    }
}(window, document, jQuery);