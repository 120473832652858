+function (window, document, $) {

    var module = {
        // Required
        transformIn: transformIn,
        transformOut: transformOut,
        // Internals
        inStatus: inStatus,
        inSales: inSales,
        inAddress: inAddress,
        outStatus: outStatus,
        outSales: outSales
    };

    window.App = window.App || {};
    window.App.Api = window.App.Api || {};
    window.App.Api.Transformers = window.App.Api.Transformers || {};
    window.App.Api.Transformers.ColikadoParcel = module;

    return void 0;

    // --------------------------------------------------------------------
    // REQUIRED FUNCTIONS
    // --------------------------------------------------------------------


    /**
     * Transform a COLIKADO PARCEL from API to local
     * @param {*} parcel source object
     * @returns {*} transformed object
     */
    function transformIn(parcel) {
        var transformer = this;

        transformer.inStatus(parcel);
        transformer.inSales(parcel);
        transformer.inAddress(parcel);

        return parcel;
    }


    /**
     * Transform a COLIKADO PARCEL from local to API
     * @param {*} parcel source object
     * @returns {*} transformed object
     */
    function transformOut(parcel) {
        var transformer = this;

        parcel = $.extend(true, {}, parcel);

        transformer.outStatus(parcel);
        transformer.outSales(parcel);

        return parcel;
    }

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Compute parcel status
     *
     * @private
     * @returns {*|{}} Colipays V2 API gateway
     */
    function inStatus(parcel) {
        var no = '** NON **',
            yes = 'OUI',
            status = $.extend(true, parcel.status || {}, {
                controle: {
                    etat: no,
                    date: null,
                    fax: no,
                    date_fax: null
                },
                imprime: {
                    etat: no,
                    date: null
                },
                saisie: {
                    etat: no,
                    date: null
                },
                palette: {
                    etat: no,
                    date: null
                }
            }),
            dotProp = window.dotProp,
            moment = window.moment;

        // ***

        if (dotProp.get(parcel, 'controle.arrive')) {
            status.controle.etat = yes;
            status.controle.date = parcel.controle.date ? moment(parcel.controle.date).format('DD/MM/YYYY HH:mm') : null
        }

        if (dotProp.get(parcel, 'controle.fax')) {
            status.controle.fax = yes;
            status.controle.date_fax = parcel.controle.date_fax ? moment(parcel.controle.date_fax).format('DD/MM/YYYY HH:mm') : null
        }

        // ***

        if (dotProp.get(parcel, 'impression.etiquette.imprime')) {
            status.imprime.etat = yes;
            status.imprime.date = parcel.impression.etiquette.date ? moment(parcel.impression.etiquette.date).format('DD/MM/YYYY HH:mm') : null
        }

        // ***

        var saisie = parcel.saisie;

        if (saisie) {
            if (saisie.terminee) {
                if ('INTERNET' === parcel.source) {
                    status.saisie.etat = 'SAISIE EN LIGNE';
                } else {
                    status.saisie.etat = 'COMPLETE';
                }
                status.saisie.date = saisie.date ? moment(saisie.date).format('DD/MM/YYYY HH:mm') : null;
            }
            else if (saisie.rapide) {
                status.saisie.etat = 'RAPIDE';
                status.saisie.date = saisie.date ? moment(saisie.date).format('DD/MM/YYYY HH:mm') : null;
            }
        }

        // ***

        if (dotProp.get(parcel, 'fret.embarque')) {
            status.palette.etat = yes;
            status.palette.date = parcel.fret.depart ? moment(parcel.fret.depart).format('DD/MM/YYYY HH:mm') : null
        }

        // ***

        parcel.status = status;
    }

    function inAddress(parcel) {
        var blank = {
            civilite: '',
            nom: '',
            prenom: '',
            adresse: {
                lignes: [],
                code_postal: '',
                ville: '',
                pays: {
                    nom: '',
                    code: ''
                }
            }
        };

        if (!parcel.expediteur) {
            parcel.expediteur = $.extend(true, {}, blank);
        }

        if (!parcel.destinataire) {
            parcel.destinataire = $.extend(true, {}, blank);
        }

        parcel.expediteur.numclient = parcel.numclient;
    }

    /**
     * Delete status from upstream
     *
     * @private
     * @returns {*|{}} Colipays V2 API gateway
     */
    function outStatus(parcel) {
        delete parcel.status;
    }

    /**
     * Compute parcel status
     *
     * @private
     * @returns {*|{}} Colipays V2 API gateway
     */
    function inSales(parcel) {
        var vente = $.extend(true, parcel.vente || {}, {
            // DEPRECATED - date_achat not used anymore since 2016-01-28
            date_achat: parcel.date_achat ? moment(parcel.date_achat).format('DD/MM/YYYY') : null,
            date_depot: parcel.date_depot ? moment(parcel.date_depot).format('DD/MM/YYYY') : null,
            achat: 'object' === typeof parcel.pdvAchat ? parcel.pdvAchat : {id: null},
            depot: 'object' === typeof parcel.pdvDepot ? parcel.pdvDepot : {id: null}
        });

        delete parcel.date_achat;
        delete parcel.pdvAchat;
        delete parcel.pdvDepot;

        parcel.vente = vente;
    }

    /**
     * Refactor sales from upstream
     *
     * @private
     * @returns {*|{}} Colipays V2 API gateway
     */
    function outSales(parcel) {
        if (null !== parcel.vente && 'object' === typeof parcel.vente) {
            parcel.pdvAchat = parcel.vente.achat;
            parcel.pdvDepot = parcel.vente.depot;
            parcel.date_achat = parcel.vente.date_achat && parcel.vente.date_achat.match(/\d{2}\/\d{2}\/\d{4}/) ?
                moment(parcel.vente.date_achat + ' 12:00:00.000', 'DD/MM/YYYY H:m:s.SSS').format() :
                null;
            parcel.date_depot = parcel.vente.date_depot && parcel.vente.date_depot.match(/\d{2}\/\d{2}\/\d{4}/) ?
                moment(parcel.vente.date_depot + ' 12:00:00.000', 'DD/MM/YYYY H:m:s.SSS').format() :
                null;
        }

        delete parcel.vente;
    }


}(window, document, jQuery);