!function (window, document, $) {
    $.components.register('app.menu', {
        mode: 'init',
        defaults: {
            itemSelector: '.site-menu [data-menu-id="?"]',
            targetSelector: '.site-menu [data-menu-id]',
            activeClass: 'active',
            parentActiveClass: 'active open'
        },
        init: function (context) {
            var lastSelection = this.getLastSelection,
                self = this;

            if (lastSelection) {
                var defaults = $.components.getDefaults('app.menu');
                $(defaults.itemSelector.replace(/\?/, lastSelection))
                    .addClass(defaults.activeClass)
                    .parents('.site-menu-item').each(function () {
                        $(this).addClass(defaults.parentActiveClass);
                    });
            }

            $(defaults.targetSelector).on('click', function () {
                self.saveLastSelection($(this).data('menu-id'));
            });
        },
        getLastSelection: function () {
            var item = false;

            if (typeof window.sessionStorage !== 'undefined') {
                item = window.sessionStorage.getItem('menu.last');
            }

            return item;
        },
        saveLastSelection: function (item) {
            if (typeof window.sessionStorage !== 'undefined') {
                window.sessionStorage.setItem('menu.last', item);
            }
        }
    });
}(window, document, jQuery);