+function (window, document, $) {

    var module = {
        options: {
            validClass: 'has-success',
            invalidClass: 'has-error',
            parentClass: 'form-group'
        },
        // Public functions,
        validate: validate,
        reset: reset,
        // Internal functions,
        $$validate: $$validate,
        $$target: $$target
    };

    window.FormValidation = module;

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Chaining
     *
     * @param form
     * @returns {{__successCallback: null, __failCallback: null, success: Function, fail: Function}}
     */
    function validate(form) {
        var state = {
            __successCallback: null,
            __failCallback: null,
            success: function (callback) {
                this.__successCallback = callback;
                return state;
            },
            fail: function (callback) {
                this.__failCallback = callback;
                return state;
            },
            reset: function () {
                module.reset(form);
            }
        }, module = this;

        setTimeout(function () {
            var result = module.$$validate(form);

            if (result) {
                'function' === typeof state.__successCallback && state.__successCallback(state);
            } else {
                'function' === typeof state.__failCallback && state.__failCallback(state);
            }
        }, 1);

        return state;
    }

    /**
     * The real validation
     *
     * @param {*} form HTML Form element
     * @returns {boolean}
     */
    function $$validate(form) {
        var f, field, formvalid = true, module = this;

        module.reset(form);

        // loop all fields
        for (f = 0; f < form.elements.length; f++) {

            // get field
            field = form.elements[f];

            // ignore buttons, fieldsets, etc.
            if (field.nodeName !== "INPUT" && field.nodeName !== "TEXTAREA" && field.nodeName !== "SELECT") continue;

            // is native browser validation available?
            if (typeof field.willValidate !== "undefined") {
                // native validation available
                if (field.nodeName === "INPUT" && field.type !== field.getAttribute("type")) {
                    // input type not supported! Use legacy JavaScript validation
                    field.setCustomValidity(LegacyValidation(field) ? "" : "error");
                }
                // native browser check
                field.checkValidity();
            }
            else {
                // native validation not available
                field.validity = field.validity || {};
                // set to result of validation function
                field.validity.valid = LegacyValidation(field);
                // if "invalid" events are required, trigger it here
            }

            var $field = $(field);

            if (!$field.is(':visible') || field.validity.valid) {
                module.$$target($field)
                    .removeClass(module.options.invalidClass)
                    .addClass(module.options.validClass);
            }
            else {
                module.$$target($field)
                    .removeClass(module.options.validClass)
                    .addClass(module.options.invalidClass);

                formvalid = false;
            }

            // Reset validation class on change
            +function ($field) {
                $field.on('change.FormValidation', function () {
                    setTimeout(function () {
                        module.$$target($field, true)
                            .removeClass(module.options.validClass)
                            .removeClass(module.options.invalidClass);
                    }, 250);

                    $field.off('.FormValidation');
                })
            }($field);
        }

        return formvalid;
    }

    /**
     * Reset validation state for form fields
     *
     * @param {*} form HTML Form element
     */
    function reset(form) {
        var f, field, module = this;

        // loop all fields
        for (f = 0; f < form.elements.length; f++) {

            // get field
            field = form.elements[f];

            // ignore buttons, fieldsets, etc.
            if (field.nodeName !== "INPUT" && field.nodeName !== "TEXTAREA" && field.nodeName !== "SELECT") continue;

            var $field = $(field);

            module.$$target($field, true)
                .removeClass(module.options.validClass)
                .removeClass(module.options.invalidClass);

            $field.off('change.FormValidation');
        }
    }


    /**
     * basic legacy validation checking
     */
    function LegacyValidation(field) {

        var
            valid = true,
            val = field.value,
            type = field.getAttribute("type"),
            chkbox = (type === "checkbox" || type === "radio"),
            required = field.getAttribute("required"),
            minlength = field.getAttribute("minlength"),
            maxlength = field.getAttribute("maxlength"),
            pattern = field.getAttribute("pattern");

        // disabled fields should not be validated
        if (field.disabled) return valid;

        // value required?
        valid = valid && (!required ||
                (chkbox && field.checked) ||
                (!chkbox && val !== "")
            );

        // minlength or maxlength set?
        valid = valid && (chkbox || (
                (!minlength || val.length >= minlength) &&
                (!maxlength || val.length <= maxlength)
            ));

        // test pattern
        if (valid && pattern) {
            pattern = new RegExp(pattern);
            valid = pattern.test(val);
        }

        return valid;
    }

    function $$target($field, force) {
        var module = this,
            target = $field;

        if (module.options.parentClass) {
            target = $field.closest('.' + module.options.parentClass);

            if (!force) {
                target = target.not([
                    '.' + module.options.invalidClass,
                    '.' + module.options.validClass
                ].join(','));
            }
        }

        return target;
    }

}(window, document, jQuery);