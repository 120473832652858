+function (window, document, $) {

    var module = {
        // Public functions,
        load: loadPage,
        initViews: initViews,
        gotoView: gotoView,
        // Internal function
        compile: compile
    };

    window.App = window.App || {};
    window.App.OnePage = module;

    return void 0;

    // --------------------------------------------------------------------
    // MODULE FUNCTIONS
    // --------------------------------------------------------------------

    /**
     * Initialize a page module when required modules are loaded :
     * - Colipays.Api
     *
     * @param {string} name The page name
     * @param {function} factory The factory function that creates the page model
     */
    function loadPage(name, factory) {
        var app = this;

        if ('function' !== typeof factory) {
            console.error('Page [' + name + '] requires a valid factory function');
        } else {
            var page = $('[v-page=' + name + ']');

            if (page.length) {
                $(document).on('colipays:api:loaded', function () {
                    app.compile(page);
                    factory(page);
                });
            }
        }
    }

    /**
     * Deep merge an object into form fields through `field.val()` function
     */
    function initViews(page, pageEl) {
        page.$$currentView = null;

        for (var view in page.views) {
            if (!page.views.hasOwnProperty(view)) continue;

            if ('function' !== typeof page.views[view]) {
                console.error('view [' + view + '] defined but no definition exists');
                continue;
            }

            page.views[view] = (function (config) {
                config.$$el = $(config.el, pageEl);
                config.el = function (name) {
                    if (!name) {
                        return config.$$el;
                    }

                    return $('[v-el="' + name + '"]', config.$$el);
                };
                config.component = function (id) {
                    return $('[v-component-instance="' + id + '"]', config.$$el);
                };
                config.$$el
                    .on('view:enter', function (e, data) {
                        'function' === typeof config.onEnter && config.onEnter(data);
                    })
                    .on('view:exit', function (e, data) {
                        'function' === typeof  config.onExit && config.onExit(data);
                    });
                'function' === typeof config.onInit && config.onInit();

                return config;
            })(page.views[view](page)); // Call view factory with App
        }
    }

    /**
     * Change current active view inside a page
     *
     * @param {*} page The page module
     * @param {string} view The new view name
     * @param {*} params Optional params passed to `onEnter` event
     */
    function gotoView(page, view, params) {
        if ('undefined' !== typeof page.views[view]) {
            if (null !== page.$$currentView) {
                page.$$currentView.$$el
                    .trigger('view:exit')
                    .addClass('hidden');
            }
            page.views[view].$$el.removeClass('hidden');
            page.$$currentView = page.views[view];
            page.$$currentView.$$el.trigger('view:enter', params || {});
        } else {
            console.error('View [' + view + '] does not exists');
        }
    }

    /**
     * Compile all components on a page recursively
     *
     * @param page
     */
    function compile(page) {
        if ('object' !== typeof page || null === page) {
            throw 'A page compilation requires a DOM root element';
        }

        var app = this;

        $('[v-component]', page).each(function () {
            var el = $(this),
                directive = el.attr('v-component');

            for (var component in window.App.Components) {
                if (window.App.Components[component].directive === directive) {
                    app.compile(window.App.Components[component].compile(el));
                    break;
                }
            }
        });
    }

}(window, document, jQuery);