+function (window, $) {
    "use strict";

    window.App = window.App || {};
    window.App.Pages = window.App.Pages || {};
    window.App.Pages.SaisieArrivee = window.App.Pages.SaisieArrivee || {};
    window.App.Pages.SaisieArrivee.views = window.App.Pages.SaisieArrivee.views || {};

    window.App.Pages.SaisieArrivee.views.home = viewFactory;

    function viewFactory(App) {
        var state = {
                bindings: [],
                focusInterval: null,
                canFocus: false
            },
            Api = window.App.Api,
            FormValidation = window.FormValidation;

        return {
            el: '#homeView',
            $$loaded: false,
            // Called by App lifecycle
            onInit: onInit,
            onEnter: onEnter,
            onExit: onExit,
            // Local methods
            bindEvents: bindEvents,
            reset: reset,
            validateNumber: validateNumber
        };

        // --------------------------------------------------------------------
        // VIEW FUNCTIONS
        // --------------------------------------------------------------------

        /**
         * Load the carriers from the API
         */
        function onInit() {
            var view = this,
                carrier = view.el('transporteur');

            Api.Colikado.loadCarriersOptions(carrier, {actif: true})
                .done(function () {
                    view.$$loaded = true;
                    view.$$el.trigger('loaded');
                })
                .fail(function (error) {
                    App.showError('Impossible de charger la liste des transporteurs', error);
                })
        }

        /**
         * Initialize fields states, start autofocus on number
         */
        function onEnter() {
            var view = this,
                form = view.el('form'),
                number = view.el('numerock'),
                alert = view.el('alert'),
                carrier = view.el('transporteur');

            form.find('input,select,button,a').prop('disabled', false);

            if (view.$$loaded) {
                load();
            } else {
                view.$$el.on('loaded', function () {
                    load();
                });
            }

            return void 0;

            /*
             * Internal functions
             */

            function load() {

                if (window.sessionStorage) {
                    var val = window.sessionStorage.getItem('Page.SaisieArrivee');

                    if (val) {
                        carrier.val(val);
                    }
                }

                // Ensure something is selected
                App.selectFirst(carrier);

                // Auto switch view if number is set
                if (number.val() && carrier.val()) {
                    setTimeout(function () {
                        view.validateNumber()
                            .fail(function () {
                                loadUi();
                            });
                    }, 250);
                } else {
                    loadUi();
                }
            }

            function loadUi() {
                form.find('input,select,button,a').prop('disabled', false);
                alert.addClass('hidden');

                view.bindEvents();

                // ***

                state.focusInterval = setInterval(function () {
                    if (state.canFocus) {
                        number.prop('disabled', false);
                        number.focus();
                    } else {
                        number.prop('disabled', true);
                    }
                }, 250);

                // ***

                if (carrier.val()) {
                    carrier.change();
                }
            }
        }

        /**
         * Binds all events
         */
        function bindEvents() {
            var view = this,
                form = view.el('form'),
                number = view.el('numerock');

            /*
             * Validate the number when requested
             */
            state.bindings.push((function () {
                form
                    .on('submit', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        view.validateNumber();
                    })
                    .on('reset', function (e) {
                        e.preventDefault();
                        e.stopPropagation();

                        view.reset();
                    });

                return function () {
                    form.off('submit reset');
                };
            })());

            /*
             * Reset the form
             */
            state.bindings.push((function () {
                var reset = view.el('reset')
                    .on('click', function () {
                        view.reset();
                    });

                return function () {
                    reset.off('click');
                };
            })());

            /*
             * Autofocus on change + Session save when carrier is selected
             */
            state.bindings.push((function () {
                var carrier = view.el('transporteur')
                    .on('change', function () {
                        var self = $(this);

                        self.blur();

                        if (window.sessionStorage) {
                            window.sessionStorage.setItem('Page.SaisieArrivee', self.val());
                        }
                    });

                return function () {
                    carrier.off('change');
                };
            })());

            /*
             * Cancel/Force focus on number field when others field gain/lost focus
             */
            state.bindings.push((function () {
                var carrier = view.el('transporteur'),
                    fields = form.find('input,select,textarea').not(number)
                        .on('focus', function () {
                            state.canFocus = false;
                        })
                        .on('blur', function () {
                            state.canFocus = canFocus();
                        }),
                    canFocus = function () {
                        return carrier.val() > 0;
                    };

                return function () {
                    fields.off('focus blur');
                };
            })());

            /*
             * Handle history
             */
            if ('function' === typeof window.history.replaceState) {
                window.history.replaceState(
                    {
                        page: 'home-control'
                    }, null, view.$$el.closest('[v-page]').data('url-home')
                );
            }
        }

        /**
         * Clear bindings, stop autofocus
         */
        function onExit() {
            var i;

            state.focusInterval && clearInterval(state.focusInterval);

            for (i = 0; i < state.bindings.length; ++i) {
                state.bindings[i]();
            }

            state.bindings = [];
        }

        /**
         * Clear the number field, keep the carrier
         */
        function reset() {
            var view = this,
                form = view.el('form'),
                number = view.el('numerock'),
                alert = view.el('alert'),
                buttons = form.find('input,select,button,a');

            buttons.prop('disabled', false);
            number.val('');
            alert.text('').addClass('hidden');
        }

        /**
         * Call API to check the number if it matches the provided pattern
         */
        function validateNumber() {
            var view = this,
                defer = $.Deferred(),
                form = view.el('form'),
                number = view.el('numerock'),
                carrier = view.el('transporteur'),
                fields = form.find('input,select,button,a'),
                value = number.val(),
                failureAlert = function () {
                    view.el('alert').html('!!! Numéro [<strong>' + value + '</strong>] invalide !!!').removeClass('hidden');
                };

            FormValidation.validate(view.el('form').get(0))
                .success(function (validation) {
                    validation.reset();
                    view.reset();
                    fields.prop('disabled', true);

                    var wait = App.showWaiting('Récupération des données');

                    App.getParcel(value, ['interception', 'pdvAchat', 'commandes'])
                        .done(function (parcel) {
                            /** @namespace parcel.control.arrive */

                            wait.close();

                            // Parcel must not be linked to non valid order for COLIPAYS and COLIKADO point of sales (at least one valid order must exists
                            if (
                                typeof parcel.vente == 'object' && typeof parcel.vente.achat == 'object' && parcel.vente.achat.code &&
                                (parcel.vente.achat.code === '000' || parcel.vente.achat.code === '022') && !parcel.numero.match(/^TT.+$/)
                            ) {
                                var test =
                                    typeof parcel.commandes == 'object' &&
                                    parcel.commandes.data &&
                                    parcel.commandes.data.length &&
                                    parcel.commandes.data.reduce(function (valid, order) {
                                        return valid || order.valid;
                                    }, false);

                                if (!test) {
                                    fields.prop('disabled', false);
                                    defer.reject();

                                    setTimeout(function () {
                                        App.showError({
                                            title: 'SAISIE IMPOSSIBLE',
                                            html: 'Le bordereau n\'a pas été facturé en caisse au showroom.'
                                        });
                                    }, 250);

                                    return;
                                }
                            }

                            if (parcel.interception.required) {
                                fields.prop('disabled', false);
                                defer.reject();

                                setTimeout(function () {
                                    App.showError({
                                        title: 'INTERCEPTION REQUISE',
                                        html: 'Ce colis doit être intercepté.<br><strong>Aucune action autorisée.</strong>'
                                    });
                                }, 250);
                            } else {
                                if (
                                    parcel.controle.arrive &&
                                    parcel.dimensions.poids_reel > 0 &&
                                    parcel.produit.id > 0
                                ) {
                                    App.goto('existing', {
                                        parcel: parcel
                                    });
                                    defer.resolve();
                                } else {
                                    App.goto('new', {
                                        parcel: parcel,
                                        carrier: carrier.val()
                                    });
                                    defer.resolve();
                                }
                            }
                        })
                        .fail(function () {
                            fields.prop('disabled', false);
                            wait.close();
                            failureAlert();
                            defer.reject();
                        });
                })
                .fail(function () {
                    view.reset();
                    failureAlert();
                    defer.reject();
                });

            return defer;
        }
    }
}(window, jQuery);